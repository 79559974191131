import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { isCorpTenant } from "@capone/common";
import { IStoreState } from "../../../../reducers/types";
import { actions } from "../../actions";
import {
  getReturnDate,
  getDepartureDate,
  getNightCount,
  getAdultsCount,
  getChildren,
} from "../../../search/reducer";
import {
  getPackageLodgingRoomInfoProducts,
  getHotelShopCallState,
  getPackageSelectedLodging,
  getHotelShopChosenProduct,
  getHotelShopChosenRoomInfoIndex,
  getHotelShopChosenProductIndex,
  getHotelShopChosenRoomInfo,
  getPackageRatesById,
} from "../../reducer";
import { PackageHotelShopCallState } from "../../reducer/state";
import { DesktopRoomPicker } from "./component";
import {
  getRewardsAccountWithLargestEarnForBanner,
  getSelectedAccountReferenceIdIfRedemptionEnabled,
  getUserIsPrimaryCardHolder,
} from "../../../rewards/reducer";

import { config } from "../../../../api/config";
import { getTravelersCount } from "../../../availability/reducer";
import { fetchPackageHotelShop } from "../../actions/actions";

const mapStateToProps = (state: IStoreState) => {
  const checkinDate = getDepartureDate(state);
  const checkoutDate = getReturnDate(state);
  const chosenRoomInfoIndex = getHotelShopChosenRoomInfoIndex(state);
  const chosenProductIndex = getHotelShopChosenProductIndex(state);
  const chosenRoomInfo = getHotelShopChosenRoomInfo(state);
  const chosenProduct = getHotelShopChosenProduct(state);
  const selectedLodging = getPackageSelectedLodging(state);
  const nightCount = getNightCount(state);
  const rewardsKey =
    getSelectedAccountReferenceIdIfRedemptionEnabled(state) || undefined;
  const roomInfoProducts = getPackageLodgingRoomInfoProducts(state);
  const isHotelShopLoading =
    getHotelShopCallState(state) === PackageHotelShopCallState.InProcess;
  const adultsCount = getAdultsCount(state);
  const children = getChildren(state);
  const largestValueAccount = getRewardsAccountWithLargestEarnForBanner(state);

  const canEarnRewards =
    !isCorpTenant(config.TENANT) || getUserIsPrimaryCardHolder(state);
  const packagesByRateId = getPackageRatesById(state);
  const travelersCount = getTravelersCount(state);
  return {
    checkinDate,
    checkoutDate,
    chosenRoomInfoIndex,
    chosenProductIndex,
    chosenRoomInfo,
    chosenProduct,
    selectedLodging,
    nightCount,
    rewardsKey,
    roomInfoProducts,
    isHotelShopLoading,
    adultsCount,
    children,
    largestValueAccount,
    canEarnRewards,
    packagesByRateId,
    travelersCount,
  };
};

const mapDispatchToProps = {
  selectRoomType: actions.selectRoomType,
  setSelectedRoomId: actions.setSelectedRoomId,
  fetchPackageHotelShop,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type DesktopRoomPickerConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedDesktopRoomPickerPicker = withRouter(
  connector(DesktopRoomPicker)
);
