import React from "react";

export const STOPS_HEADER_TEXT = (appliedLabel?: string) =>
  !!appliedLabel ? (
    <>
      <span className="filter-name">Stops:</span> {appliedLabel}
    </>
  ) : (
    <span className="filter-name">Stops</span>
  );

export const ANY_NUMBER_OF_STOPS_TEXT = "Any number of stops";
export const NO_STOPS_ONLY = "Nonstop only";
export const ONE_STOP_OR_FEWER = "One stop or fewer";
export const TWO_STOPS_OR_FEWER = "Two stops or fewer";

export const CHOOSE_STOPS_OPTION_TEXT = "Filter stops";
export const CHANGE_STOPS_OPTION_TEXT = "Change stops filter";
