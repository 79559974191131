import { Box, Divider, Typography } from "@material-ui/core";
import { getCancellationPolicyInfo, Icon, IconName } from "halifax";
import React from "react";
import { CancellationPoliciesCardConnectorProps } from "./container";
import {
  CALIFORNIA_BILL_644_CANCELLATION_TEXT,
  CANCELLATION_HEADING,
  FREE_CANCELLATION,
  FREE_FLIGHT_CANCEL,
} from "./textConstants";
import "./styles.scss";
import { CancellationPolicyEnum, CancellationReason } from "redmond";
import clsx from "clsx";

interface ICancellationPoliciesCardProps
  extends CancellationPoliciesCardConnectorProps {}

export const CancellationPoliciesCard = (
  props: ICancellationPoliciesCardProps
) => {
  const { chosenProduct, isVoidWindowEligible, cancellationSummary } = props;

  if (!isVoidWindowEligible && !chosenProduct) return null;

  const cancellationPolicyInfo = chosenProduct?.cancellationPolicy
    ? getCancellationPolicyInfo(chosenProduct?.cancellationPolicy)
    : null;

  return (
    <Box className="cancellation-policies-card-root">
      <Typography variant="h3" className="packages-book-section-heading">
        {CANCELLATION_HEADING}
      </Typography>

      {cancellationPolicyInfo && (
        <>
          <Divider />
          <Box className="hotel-cancellation-container">
            <Box className="icon-wrapper">
              <Icon name={IconName.HotelFunnelIcon} />
            </Box>
            <Box className="text-container">
              <Typography
                className={clsx("title", {
                  green:
                    chosenProduct?.cancellationPolicy.CancellationPolicy ===
                    CancellationPolicyEnum.Refundable,
                })}
              >
                {cancellationPolicyInfo.primaryText}
              </Typography>
              <Typography variant="body2">
                {chosenProduct?.cancellationPolicy.CancellationPolicy ===
                  CancellationPolicyEnum.NonRefundable &&
                cancellationSummary?.reasons.includes(
                  CancellationReason.CaliforniaBill644
                )
                  ? CALIFORNIA_BILL_644_CANCELLATION_TEXT
                  : cancellationPolicyInfo.secondaryText}
              </Typography>
            </Box>
          </Box>
        </>
      )}
      {isVoidWindowEligible && (
        <>
          <Divider />
          <Box className="flight-free-cancellation-container">
            <Box className="icon-wrapper">
              <Icon name={IconName.FlightFunnelIcon} />
            </Box>
            <Box className="free-cancellation-texts">
              <Typography className="title green">
                {FREE_CANCELLATION}
              </Typography>
              <Typography variant="body2">{FREE_FLIGHT_CANCEL}</Typography>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};
