import { Box, Link, Typography } from "@material-ui/core";
import "./styles.scss";

import React, { useRef, useEffect, useMemo, useState, useContext } from "react";
import {
  CorpLodging,
  CorpLodgingData,
  FlightRatingsEnum,
  getLodgingTrackingProperties,
  IIdLodgings,
  Lodging,
  PackagedLodging,
  PackageFetchDetailsEnum,
  SELECTED_PACKAGE_HOTEL_FROM_LIST,
  SELECTED_UNAVAILABLE_PACKAGE_HOTEL_FROM_LIST,
  SliceStopCountFilter,
  TripDetails,
  VIEWED_PACKAGE_LODGING_IN_LIST,
  ViewedCorpRateDescriptorEntryPoints,
} from "redmond";
import { RouteComponentProps } from "react-router";
import {
  BannerSeverity,
  CorpCompareBarBanner,
  CorpCompareBarTooltip,
  CorpPolicyBanner,
  GenericShopListFooter,
  HotelAvailabilityCard,
  Icon,
  IconName,
  NotificationBanner,
  OutOfPolicyModal,
  useDeviceTypes,
} from "halifax";
import ReactList from "react-list";
import clsx from "clsx";
import { isCorpTenant, localCache, useShowPolicyBanner } from "@capone/common";
import { useExperimentIsVariant } from "@capone/experiments";
import { InfoOutlined } from "@material-ui/icons";
import InView from "react-intersection-observer";
import { v4 as uuidv4 } from "uuid";
import { AvailabilityListConnectorProps } from "./container";
import * as textConstants from "./textConstants";
import { config } from "../../../../api/config";
import { AvailabilitySearchControl } from "../AvailabilitySearchControl";
import { PackagesAvailabilityCallState } from "../../reducer/state";
import { AvailabilitySort } from "../AvailabilitySort";
import { AvailabilityNoResults } from "../AvailabilityNoResults";
import { PATH_HOME, PATH_HOTEL_SHOP } from "../../../../utils/paths";
import { setOpenDatesModal } from "../../actions/actions";
import { ClientContext } from "../../../../App";
import {
  useExperiments,
  getExperimentVariant,
  AVAILABLE,
  CORP_DEBUGGING_PANEL,
  CACHE_HOTEL_TOKEN,
} from "../../../../context/experiments";
import { CorporateDebuggingPanel } from "../CorporateDebuggingPanel";
import { onOpenCompareBarTooltip } from "../../../../utils/events";
import { RecommendedFlightsCard } from "../RecommendedFlightsCard";
import { transformToStringifiedQuery } from "../../../hotel-shop/utils/queryStringHelpers";
import { trackEvent } from "../../../../api/v0/trackEvent";
import {
  initialFareclassOptionFilter,
  initialFilterOptions,
} from "../../../search/reducer";
import {
  getSelectedFareClass,
  getLowestAvailableFareByRating,
} from "../../../../utils/fareClass";

const APPROXIMATE_HOTEL_AVAILABILITY_CARD_HEIGHT = 284;

export interface IAvailabilityListProps
  extends AvailabilityListConnectorProps,
    RouteComponentProps {}

export const AvailabilityList = (props: IAvailabilityListProps) => {
  const {
    nightCount,
    accountReferenceId,
    largestValueAccount,
    lodgings,
    packagesAvailabilityCallState,
    fetchMorePackagesAvailability,
    history,
    packagesByLodgingId,
    searchedLocation,
    travelersCount,
    isFilteredHotelAvailabilityLodgingsEmpty,
    recommendedFlights,
    packageHotelQueryParams,
    origin,
    credit,
    packagesViewedHotelListProperties,
    setLodgingIdHovered,
    fareClassFilter,
    setFareClassFilter,
    stopsFilter,
    setStopsFilter,
  } = props;
  // const [untilProp, setUntilProp] = useState<Date | null>(null);
  // const [fromProp, setFromProp] = useState<Date | null>(null);
  // const [refetchFromCalendarModal, setRefetchFromCalendarModal] =
  //   useState(false);
  const [locationName, setLocationName] = React.useState("");
  const [isSearchTermLodging, setIsSearchTermLodging] = React.useState(false);
  // If search term is location (ex: Toronto) vs if search term is point of interest (ex: Toronto Zoo)
  const [isSearchTermPoint, setIsSearchTermPoint] = React.useState(false);
  const listRef = useRef<ReactList | null>(null);
  const divRef = useRef<HTMLDivElement | null>(null);
  const recommendedFlightsRef = useRef<HTMLDivElement | null>(null);
  const { isAutoApprovalEnabled, policies } = useContext(ClientContext);
  const { matchesMobile, matchesDesktop } = useDeviceTypes();
  const [showPolicyModal, setShowPolicyModal] = useState<string | null>(null);
  const [corpPolicyDescriptorViewCount, setCorpPolicyDescriptorViewCount] =
    useState(0);
  const [fareClassCriteriaNotMet, setFareClassCriteriaNotMet] = useState(false);
  const [nonStopFilterCriteriaNotMet, setNonStopFilterCriteriaNotMet] =
    useState(false);

  const isApprovalsV2Enabled = useExperimentIsVariant(
    "corp-approvals-v2",
    "m2"
  );

  const outOfPolicyCopy =
    isAutoApprovalEnabled || isApprovalsV2Enabled
      ? "If you wish to proceed with your selection, admins will be notified upon booking that this hotel was out of policy."
      : "This hotel rate is out of your company policy. You can continue anyway or change your selection.";

  const showPolicyBanner = useShowPolicyBanner(policies);

  const expState = useExperiments();

  const isCorporateDebuggingPanelEnabled =
    getExperimentVariant(expState.experiments, CORP_DEBUGGING_PANEL) ===
    AVAILABLE;

  const cacheHotelTokenEnabled =
    getExperimentVariant(expState.experiments, CACHE_HOTEL_TOKEN) === AVAILABLE;

  React.useEffect(() => {
    const [locationName] = searchedLocation?.label
      ? searchedLocation.label.split(",")
      : [];
    setLocationName(locationName);

    const placeTypes = searchedLocation
      ? (searchedLocation.id as IIdLodgings).lodgingSelection.placeTypes
      : [];

    setIsSearchTermLodging(placeTypes.includes("lodging"));
    setIsSearchTermPoint(
      !placeTypes.includes("locality") &&
        !placeTypes.includes("political") &&
        !placeTypes.includes("country")
    );
  }, [searchedLocation]);

  useEffect(() => {
    if (
      packagesAvailabilityCallState ===
        PackagesAvailabilityCallState.FollowUpSearchCallSuccess ||
      packagesAvailabilityCallState ===
        PackagesAvailabilityCallState.InitialSearchCallSuccess
    ) {
      setTimeout(() => fetchMorePackagesAvailability(history), 100);
    }
  }, [packagesAvailabilityCallState, fetchMorePackagesAvailability, history]);

  React.useEffect(() => {
    if (recommendedFlights?.value) {
      const tripDetails: TripDetails = recommendedFlights.value;

      const selectedFareClass = getSelectedFareClass(fareClassFilter);

      const selectedFareRating = selectedFareClass
        ? FlightRatingsEnum[selectedFareClass]
        : FlightRatingsEnum[getLowestAvailableFareByRating(tripDetails)];

      const matchingFare = tripDetails.fareDetails.find((fareDetail) =>
        fareDetail.slices.some(
          (slice) => slice.fareShelf?.rating === selectedFareRating
        )
      );

      setFareClassCriteriaNotMet(false);
      if (!matchingFare) {
        setFareClassCriteriaNotMet(true);

        const lowestFare = getLowestAvailableFareByRating(tripDetails);

        // if selected fare class isn't available, set filter to lowest available fare class
        setFareClassFilter({
          ...initialFareclassOptionFilter,
          [lowestFare]: true,
        });
      }

      setNonStopFilterCriteriaNotMet(false);
      if (stopsFilter === SliceStopCountFilter.NONE) {
        const hasNonStop = tripDetails.slices.some(
          (slice) => slice.stops === 0
        );

        if (!hasNonStop) {
          setNonStopFilterCriteriaNotMet(true);
          setStopsFilter(initialFilterOptions.stopsOption);
        }
      }
    } else {
      setFareClassCriteriaNotMet(false);
      setNonStopFilterCriteriaNotMet(false);
    }
  }, [recommendedFlights?.value]);

  const sortedLodgings = useMemo(() => {
    // SORT
    const searchedLodgingSelection = (searchedLocation?.id as IIdLodgings)
      ?.lodgingSelection;

    if (
      searchedLodgingSelection &&
      searchedLodgingSelection.placeTypes.includes("lodging")
    ) {
      const searchTermName = searchedLodgingSelection.searchTerm.split(",")[0];
      return lodgings
        .slice()
        .sort(
          (a, b) =>
            b.lodging.name.toLowerCase().indexOf(searchTermName.toLowerCase()) -
            a.lodging.name.toLowerCase().indexOf(searchTermName.toLowerCase())
        );
    }
    return lodgings;
  }, [lodgings, searchedLocation]);

  const renderEachLodging = (
    lodgingData: Lodging | CorpLodging,
    index: number,
    packagedLodging?: PackagedLodging
  ) => {
    const { isFreeCancel, lodging, bestPromotionThisLodging, isPreferred } =
      lodgingData;
    const available =
      typeof lodgingData.available === "undefined"
        ? true
        : lodgingData.available;

    const hasPolicyCompliance = "corporateTravel" in lodging;
    const isInPolicy =
      hasPolicyCompliance &&
      (lodging.corporateTravel.policyCompliance.isInPolicy ?? true);

    // const policyReasons = hasPolicyCompliance
    //   ? lodging.corporateTravel.policyCompliance.reasons
    //   : [];

    const onOpenPolicyDescriptor = () => {
      if (corpPolicyDescriptorViewCount <= 5) {
        // trackEvent({
        //   eventName: VIEWED_POLICY_DESCRIPTOR,
        //   properties: {
        //     type: POLICY_DESCRIPTOR,
        //     entry_point: PolicyDescriptorEntryPoints.HOTELS_LIST,
        //     funnel: "hotels",
        //     policy_reason: policyReasons.join(", "),
        //   },
        // });
        setCorpPolicyDescriptorViewCount((prevState) => prevState + 1);
      }
    };
    const showCorpCompareBarBanner =
      matchesDesktop && Boolean(lodgingData.hasCorporateRates);

    return (
      <Link
        id={`availability-row-${index}`}
        className={clsx("availability-row", {
          unavailable: !available,
          "nav-improvement": true,
        })}
        component="button"
        key={lodging.id}
        onClick={() => {
          if (available) {
            const packagePricingDetails =
              packagesByLodgingId?.[lodging.id]?.packageDetails.pricing;

            const packageDiscount =
              packagePricingDetails?.totalPackageSavings.fiat.value ?? 0;

            const packageDiscountPercentage =
              packageDiscount && packagePricingDetails
                ? (packageDiscount /
                    packagePricingDetails?.strikethroughTotalPrice.fiat.value) *
                  100
                : 0;

            trackEvent({
              eventName: SELECTED_PACKAGE_HOTEL_FROM_LIST,
              properties: {
                ...getLodgingTrackingProperties(lodgingData).properties,
                ...packagesViewedHotelListProperties.properties,
                ...lodgingData?.trackingPropertiesV2?.properties,
                is_preferred_cot: isPreferred,
                lodging_row_index: index,
                package_discount: packageDiscount,
                package_discount_percentage: packageDiscountPercentage,
              },
              encryptedProperties: [
                ...getLodgingTrackingProperties(lodgingData)
                  .encryptedProperties,
                ...packagesViewedHotelListProperties.encryptedProperties,
                lodgingData?.trackingPropertiesV2?.encryptedProperties ?? "",
              ],
            });
            const params = transformToStringifiedQuery({
              lodgingId: lodging.id,
              origin: origin?.id?.code?.code,
              destination: (searchedLocation?.id as IIdLodgings)
                .lodgingSelection.searchTerm,
              placeId: (searchedLocation?.id as IIdLodgings).lodgingSelection
                .placeId,
              selectedLodgingIndex: index,
              ...packageHotelQueryParams,
              shopToken: cacheHotelTokenEnabled
                ? packagedLodging?.opaqueShopRequest
                : localCache.set(
                    uuidv4(),
                    packagedLodging?.opaqueShopRequest || ""
                  ).key,
            });
            if (matchesMobile)
              history.push(`${PATH_HOTEL_SHOP}${params}`, {
                fromPage: location.pathname,
              });
            else window.open(`${PATH_HOTEL_SHOP}${params}`, "_blank");
          } else {
            trackEvent({
              eventName: SELECTED_UNAVAILABLE_PACKAGE_HOTEL_FROM_LIST,
              properties: {
                ...packagesViewedHotelListProperties.properties,
                lodging_row_index: index,
              },
              encryptedProperties:
                packagesViewedHotelListProperties.encryptedProperties,
            });
          }
        }}
        onMouseEnter={() => {
          setLodgingIdHovered(lodging.id);
        }}
        onMouseLeave={() => setLodgingIdHovered(null)}
      >
        <InView
          as="div"
          onChange={(inView) => {
            if (inView) {
              const searchedLodgingSelection = (
                searchedLocation?.id as IIdLodgings
              )?.lodgingSelection;

              const nightlyTotalWithTaxesAndFees =
                lodgingData.price?.breakdown &&
                "taxesAndFees" in lodgingData.price?.breakdown
                  ? (lodgingData.price?.nightlyPrice.fiat.value || 0) +
                    (lodgingData.price?.breakdown.taxesAndFees?.fiat.value ||
                      0) /
                      (nightCount || 1)
                  : lodgingData.price?.nightlyPrice.fiat.value || 0;

              const isSearchedLodging =
                searchedLodgingSelection &&
                searchedLodgingSelection.placeTypes.includes("lodging") &&
                lodgingData.lodging.name.startsWith(
                  searchedLodgingSelection.searchTerm.split(",")[0]
                );

              trackEvent({
                eventName: VIEWED_PACKAGE_LODGING_IN_LIST,
                properties: {
                  ...getLodgingTrackingProperties(lodgingData).properties,
                  ...packagesViewedHotelListProperties.properties,
                  lodging_row_index: index,
                  lodging_id: lodging.id,
                  lodging_name: lodging.name,
                  promotion_applicable:
                    lodgingData?.bestPromotionThisLodging?.promotionType,
                  is_preferred: lodgingData?.isPreferred,
                  is_available: lodgingData?.available,
                  google_place_id: searchedLodgingSelection?.placeId,
                  daily_owed_including_taxes_fees_usd:
                    nightlyTotalWithTaxesAndFees,
                  daily_owed_excluding_taxes_fees_usd:
                    lodgingData.price?.nightlyPrice.fiat.value,
                  is_compare_to_bar: showCorpCompareBarBanner,
                  is_searched: isSearchedLodging,
                },
                encryptedProperties: [
                  ...getLodgingTrackingProperties(lodgingData)
                    .encryptedProperties,
                  ...packagesViewedHotelListProperties.encryptedProperties,
                ],
              });
            }
          }}
          threshold={0.8}
        >
          <Box
            className={clsx("hotel-availability-card-wrapper", {
              unavailable: !available,
              // preferred: shouldMerchandisePreferred(lodgingData),
              // "on-sale": showOnSaleBanner,
              "capone-corporate": isCorpTenant(config.TENANT),
              "in-policy": showPolicyBanner && isInPolicy,
            })}
          >
            <CorpPolicyBanner
              variant={matchesDesktop ? "descriptor" : "base"}
              productType="hotel"
              corporateTravel={
                showPolicyBanner
                  ? (lodging as CorpLodgingData)?.corporateTravel
                  : undefined
              }
              limit={policies?.hotels.policies[0].maxPricePerNight}
              onOpen={() => onOpenPolicyDescriptor()}
              {...(matchesMobile && { borderRadius: 0 })}
            />
            <CorpCompareBarBanner
              show={showCorpCompareBarBanner}
              roundedTopCorners={!showPolicyBanner}
              onOpen={onOpenCompareBarTooltip(
                ViewedCorpRateDescriptorEntryPoints.HOTELS_LIST_CARD
              )}
            />
            <Box className="hotel-availability-card-content">
              <HotelAvailabilityCard
                hotelAvailabilityInfo={{
                  bestPromotionThisLodging,
                  lodging,
                  price: available
                    ? packagesByLodgingId?.[lodging.id]?.packageDetails.pricing
                    : undefined,
                  nightCount,
                  // TODO: ask about where to get city name only (e.g.: Toronto)
                  neighbourhood: searchedLocation?.label,
                  rewardsKey: accountReferenceId || undefined,
                  available: lodgingData.available,
                  bestOfferThisLodging: lodgingData.bestOfferThisLodging,
                  searchedRoomCount: 1,
                }}
                earnTagContent={
                  <>
                    <Icon name={IconName.StarIcon} />
                    <Typography
                      className="earn-tag-text"
                      dangerouslySetInnerHTML={{
                        __html: textConstants.getEarnTagText(
                          largestValueAccount.earn.hotelsMultiplier,
                          largestValueAccount.rewardsBalance
                            .currencyDescription ??
                            largestValueAccount.rewardsBalance.currency
                        ),
                      }}
                    />
                  </>
                }
                earnTagClassName="b2b"
                isGlobalMobileNavExperiment
                variant="package"
                travelersCount={travelersCount}
                isFreeCancel={isFreeCancel}
                isFreeBreakfastCancelExperiment
                isShowMealPlanExperiment
                isMobile={matchesMobile}
                showOffer={
                  lodgingData.bestOfferThisLodging?.amount.amount !==
                  credit?.amount.amount
                }
                tenant={config.TENANT}
              />
            </Box>
          </Box>
        </InView>
      </Link>
    );
  };

  const containerRef = useRef<null | HTMLElement>(null);

  useEffect(() => {
    if (recommendedFlightsRef.current && matchesMobile) {
      setTimeout(() => {
        recommendedFlightsRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }, 1000);
    }
  }, [recommendedFlightsRef]);

  return (
    <Box
      className={clsx("availability-list-root", {
        mobile: matchesMobile,
      })}
      {...{ ref: containerRef }}
    >
      {isCorporateDebuggingPanelEnabled && (
        <CorporateDebuggingPanel lodgings={lodgings} />
      )}
      {showPolicyModal && (
        <OutOfPolicyModal
          subtitle={outOfPolicyCopy}
          isOpen={!!showPolicyModal}
          isMobile={matchesMobile}
          onClose={() => {
            setShowPolicyModal(null);
            // trackEvent(
            //   getClickCancelOOPModalEvent(
            //     ModalScreens.HOTELS_AVAILABILITY,
            //     "hotels",
            //     modalType
            //   )
            // );
          }}
          onContinue={() => {
            window.open(showPolicyModal, "_blank");
            setShowPolicyModal(null);
            // trackEvent(
            //   getClickContinueOOPModalEvent(
            //     ModalScreens.HOTELS_AVAILABILITY,
            //     "hotels",
            //     modalType
            //   )
            // );
          }}
          isApprovalRequired={
            policies?.settings && policies.settings.isApprovalRequired
          }
        />
      )}
      <Box className="availability-list-container">
        {!matchesMobile && <AvailabilitySearchControl />}
        {isCorpTenant(config.TENANT) && (
          <Box
            className={clsx("availability-list-corp-bar-banner", {
              mobile: matchesMobile,
            })}
          >
            <Icon name={IconName.TagIcon} />
            <CorpCompareBarTooltip
              label={
                <Typography>
                  {textConstants.CORP_COMPARE_TO_BAR_BANNER_TEXT}
                  {matchesMobile && <InfoOutlined fontSize="small" />}
                </Typography>
              }
              additionalTooltipContent={
                <Typography style={{ fontStyle: "italic", marginTop: "10px" }}>
                  Look for the price tag icon to find hotels with these rates!
                </Typography>
              }
              onOpen={onOpenCompareBarTooltip(
                ViewedCorpRateDescriptorEntryPoints.HOTELS_LIST_HEADER
              )}
              hideIcon={matchesMobile}
            />
          </Box>
        )}
        {recommendedFlights?.type === PackageFetchDetailsEnum.Flight && (
          <div
            ref={recommendedFlightsRef}
            className="recommended-flghts-section"
          >
            <Typography className="recommended-flights-header">
              {textConstants.RECOMMENDED_FLIGHTS_HEADER}
            </Typography>
            {fareClassCriteriaNotMet && (
              <NotificationBanner
                content={
                  <Typography className="search-criteria-warning-banner-text">
                    {textConstants.SEARCHED_FARE_CLASS_NOT_FOUND_BANNER_TEXT}
                  </Typography>
                }
                severity={BannerSeverity.WARNING}
                className="pkg-search-criteria-warning-banner"
              />
            )}
            {nonStopFilterCriteriaNotMet && (
              <NotificationBanner
                content={
                  <Typography className="search-criteria-warning-banner-text">
                    {textConstants.NO_NONSTOP_FLIGHTS_FOUND}
                  </Typography>
                }
                severity={BannerSeverity.WARNING}
                className="pkg-search-criteria-warning-banner"
              />
            )}
            <RecommendedFlightsCard isMobile={matchesMobile} />
          </div>
        )}
        {!matchesMobile && (
          <Box className="availability-list-heading-sort">
            {locationName ? (
              <Typography
                variant="h3"
                className="availability-list-count-heading"
              >
                {textConstants.HOTEL_COUNT_HEADING_TEXT(
                  lodgings.filter(
                    (lodging) => lodging.available || !("available" in lodging)
                  ).length,
                  locationName,
                  isSearchTermLodging,
                  isSearchTermPoint
                )}
              </Typography>
            ) : (
              <div />
            )}
            <AvailabilitySort />
          </Box>
        )}

        {isFilteredHotelAvailabilityLodgingsEmpty ? (
          <Box className="no-hotels-found-components-section">
            <AvailabilityNoResults />
          </Box>
        ) : (
          <div ref={divRef} className="availability-list">
            <ReactList
              ref={listRef}
              itemRenderer={(index: number) => {
                if (
                  packagesAvailabilityCallState ===
                  PackagesAvailabilityCallState.InitialMapSearchCallInProcess
                ) {
                  return (
                    <HotelAvailabilitySkeleton
                      key={index}
                      isMobile={matchesMobile}
                    />
                  );
                }
                if (index < sortedLodgings.length) {
                  // [Packages TO-DO]: Update to render correct components based on callstate
                  return (
                    <React.Fragment key={index}>
                      {renderEachLodging(
                        lodgings[index],
                        index,
                        packagesByLodgingId?.[lodgings[index].lodging.id]
                      )}
                    </React.Fragment>
                  );
                }
                // render CardSkeleton when it's not Complete
                if (
                  packagesAvailabilityCallState !==
                  PackagesAvailabilityCallState.Complete
                ) {
                  return (
                    <HotelAvailabilitySkeleton
                      key={index}
                      isMobile={matchesMobile}
                    />
                  );
                }
                // render MoreResults footer when it's Complete
                if (
                  packagesAvailabilityCallState ===
                  PackagesAvailabilityCallState.Complete
                ) {
                  return (
                    <>
                      {/* {matchesMobile ? (
                        <MobilePopoverCard
                          open={openDatesModal}
                          onClose={() => setOpenDatesModal(false)}
                          fullScreen={true}
                          className="mobile-dates-selection-root"
                          contentClassName="mobile-dates-selection-content-wrapper"
                          topRightButton={
                            <ActionLink
                              className="dates-selection-close-button"
                              content={<CloseButtonIcon />}
                              label="Close"
                              onClick={() => setOpenDatesModal(false)}
                            />
                          }
                        >
                          <Box className="mobile-dates-selection-content-container">
                            <MobileCalendarPicker
                              onComplete={() => {
                                setOpenDatesModal(false);
                              }}
                            />
                          </Box>
                        </MobilePopoverCard>
                      ) : (
                        <DesktopCalendarPicker
                          open={openDatesModal}
                          closePopup={() => {
                            setOpenDatesModal(false);
                          }}
                          fromProp={fromProp}
                          untilProp={untilProp}
                          onClickDone={() => {
                            setRefetchFromCalendarModal &&
                              setRefetchFromCalendarModal(true);
                          }}
                        />
                      )} */}
                      <GenericShopListFooter
                        className={clsx(
                          "availability-list-find-more-results",
                          "b2b"
                        )}
                        title={textConstants.FIND_MORE_RESULTS_TITLE_TEXT}
                        subtitle={textConstants.FIND_MORE_RESULTS_SUBTITLE_TEXT}
                        buttons={[
                          {
                            title: textConstants.ADJUST_DATES_TEXT,
                            className: clsx(
                              "find-more-results-button",
                              "adjust-dates",
                              "b2b"
                            ),
                            isPrimary: true,
                            onClick: () => setOpenDatesModal(true),
                          },
                          {
                            title: textConstants.SEARCH_AGAIN_TEXT,
                            className: clsx(
                              "find-more-results-button",
                              "search-again",
                              "b2b"
                            ),
                            isPrimary: false,
                            onClick: () => history.push(PATH_HOME),
                          },
                        ]}
                        isMobile={matchesMobile}
                      />
                    </>
                  );
                }

                return <></>;
              }}
              length={lodgings.length + 1}
              type="variable"
              itemSizeEstimator={() =>
                APPROXIMATE_HOTEL_AVAILABILITY_CARD_HEIGHT
              }
            />
          </div>
        )}
      </Box>
    </Box>
  );
};

interface IHotelAvailabilitySkeletonProps {
  isMobile: boolean;
}

const HotelAvailabilitySkeleton = (props: IHotelAvailabilitySkeletonProps) => {
  const { isMobile } = props;

  return (
    <Box className="availability-skeleton-row">
      <Box className="hotel-availability-card-wrapper">
        <HotelAvailabilityCard isSkeleton isMobile={isMobile} />
      </Box>
    </Box>
  );
};
