import React from "react";

export const FETCHING_AVAILABILITY_TEXT = "for hotels";
export const FETCHING_PACKAGES_AVAILABILITY_TEXT = "for packages";
export const FETCHING_AVAILABILITY_SECONDARY_TEXT =
  "We match your hotel prices before and after you book so you don't overpay.";
export const CORP_FETCHING_AVAILABILITY_SECONDARY_TEXT =
  "With the right room, work travel doesn't have to feel like work.";
export const PARADISE_SUBTITLE =
  "Book with flexibility and start your next journey here.";

export const CHOOSE_HOTEL_TEXT = (
  locationName: string,
  isLodging?: boolean,
  isSearchTermPoint?: boolean,
  isMapView?: boolean
) =>
  `Choose hotel ${
    isMapView
      ? `in map area`
      : `${isLodging || isSearchTermPoint ? `near` : `in`} ${locationName}`
  }`;
export const DATES_TEXT = (fromDate: string, untilDate: string) =>
  `${fromDate} - ${untilDate}`;

export const EARN_ENHANCEMENT_SUBTITLE = (
  earnRate: string | number,
  productDisplayName: string,
  currency: string,
  flightEarnRate?: string | number
) => {
  const multiplier = currency.includes("cash") ? "%" : "X";
  return (
    <>
      <span className="font-bold">
        Earn {earnRate}
        {multiplier} {currency}
      </span>{" "}
      on hotels{" "}
      {flightEarnRate ? (
        <>
          {" "}
          and{" "}
          <span className="font-bold">
            {flightEarnRate}
            {multiplier} {currency}
          </span>{" "}
          on flights{" "}
        </>
      ) : (
        ""
      )}
      with your {productDisplayName} account.
    </>
  );
};

export const TRAVEL_SALES_EVENT_ACTIVE_SUBTITLE =
  "Save up to $200 on select hotels with our first-ever travel sale.";
export const TRAVEL_SALES_EVENT_ACTIVE_CTA = "Explore destinations on sale";
