import { StateValue } from "xstate";
import * as H from "history";
import { ParentState } from "@capone/checkout";
import { FiatPrice, RewardsAccount, RewardsPrice } from "redmond";
import { PackagesMachineContext } from "./types";
import { Event } from "./events";

export const stringifyParentStateValue = (state: StateValue) => {
  if (typeof state === "string") return state;
  if (typeof state === "object") {
    const key = Object.keys(state)[0];
    return key;
  }
  return "";
};

export const mapFlightStateToTransitionEvent: Record<string, Event> = {
  [ParentState.contactInformation]: Event.GO_TO_CONTACT_INFORMATION,
  [ParentState.passengerInformation]: Event.GO_TO_PASSENGER_SELECT,
  [ParentState.review]: Event.GO_TO_REVIEW,
  [ParentState.bookingConfirmation]: Event.GO_TO_REVIEW,
  [ParentState.seatSelection]: Event.GO_TO_SEAT_SELECTION,
  [ParentState.cardPayment]: Event.GO_TO_CARD_PAYMENT,
};

export const handleFulfillSuccess = (history: H.History) => {
  history.push("/");
};

export const validateContext = (_: PackagesMachineContext) => true;
// Boolean(
//   (ctx.flightShop.shopPricingInfo.fare?.length || -1) > 0 &&
//     ctx.flightSearch.departureDate &&
//     ctx.flightSearch.destination &&
//     ctx.flightSearch.origin &&
//     ctx.flightShop.selectedTrip.outgoingFareId &&
//     ctx.flightShop.selectedTrip.outgoingSliceId &&
//     ctx.flightShop.selectedTrip.tripId &&
//     Object.keys(ctx.flightShop.airports || {}).length > 0
// );

export function integerWithCommas(x: number) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function numberWithCommas(x: number) {
  return (
    integerWithCommas(Math.floor(x)) +
    (x % 1 ? `.${Math.round((x % 1) * 100)}` : "")
  );
}

export const formatCurrency = (price: FiatPrice, discount?: boolean) =>
  Intl.NumberFormat("en-US", {
    style: "currency",
    currency: price.currencyCode,
    currencyDisplay: "narrowSymbol",
  }).format(discount ? Math.abs(price.value) * -1 : price.value);

export const formatRewards = (price: RewardsPrice, discount?: boolean) => {
  const priceValueToUse = discount ? Math.abs(price.value) : price.value;

  const isCash = price.currencyDescription?.includes("cash");

  const currencySymbol = isCash ? "$" : "";
  return `${
    discount ? "-" : ""
  }${currencySymbol}${priceValueToUse.toLocaleString("en-US", {
    minimumFractionDigits: isCash ? 2 : 0,
    maximumFractionDigits: isCash ? 2 : 0,
  })} ${price?.currencyDescription}`;
};

export const getRewardsAmountFromFiat = (
  usdAmount: number,
  rewardsAccount: RewardsAccount
): RewardsPrice => {
  const amountToUse =
    usdAmount *
    (rewardsAccount.rewardsBalance.currencyDescription?.includes("cash")
      ? 1
      : 100);

  return {
    ...rewardsAccount.rewardsBalance,
    value: amountToUse,
  };
};
