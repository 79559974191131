import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { IStoreState } from "../../reducers/types";

import { PackageHotelShop } from "./component";
import { actions } from "./actions";
import {
  getDepartureDate,
  getDestination,
  getOrigin,
  getReturnDate,
} from "../search/reducer";
import {
  getHotelShopCallState,
  getPackageSelectedLodging,
  selectedPackageByRateId,
} from "./reducer";
import {
  listPaymentMethods,
  setPackageEntryPoint,
} from "../availability/actions/actions";
import { fetchTravelWalletDetails } from "../travel-wallet/actions/actions";
import { getPackageHotelQueryParams } from "../availability/reducer";
import { setPackagesFlightShopProgress } from "../flight-shop/actions/actions";

const mapStateToProps = (state: IStoreState) => {
  return {
    fromDate: getDepartureDate(state),
    untilDate: getReturnDate(state),
    hotelShopCallState: getHotelShopCallState(state),
    selectedLodging: getPackageSelectedLodging(state),
    destination: getDestination(state),
    origin: getOrigin(state),
    packageHotelQueryParams: getPackageHotelQueryParams(state),
    selectedPackageByRateId: selectedPackageByRateId(state),
  };
};

export const mapDispatchToProps = {
  fetchPackageHotelShop: actions.fetchPackageHotelShop,
  fetchTravelWalletDetails,
  listPaymentMethods,
  setPackagesFlightShopProgress,
  setPackageEntryPoint,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type PackageHotelShopConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedPackageHotelShop = withRouter(
  connector(PackageHotelShop)
);
