import { assign, DoneInvokeEvent } from "xstate";
import {
  cartHelpers,
  getHasPriceChanged,
  ParentState,
  PriceChangeType,
} from "@capone/checkout";
import {
  ProductOpaqueValue,
  Product,
  QuoteSuccess,
  PaymentOpaqueValue,
} from "@b2bportal/purchase-api";
import { PackageOpaqueProductValue, PackagesMachineContext } from "../types";
import { InitializeCheckoutStateEvent, SetPlatformEvent } from "../events";
import { RoomInfoProducts, Lodging } from "@b2bportal/lodging-api";
import {
  getOpaquePayments,
  getPackagesShopPricing,
  getQuotedPackageDiscount,
} from "../selectors/common";
import { ITripTerminus, WithDiscount } from "redmond";

export const setPaymentFulfillParams = assign(
  (ctx: PackagesMachineContext, _event) => {
    const opaquePayments = getOpaquePayments({ context: ctx });

    ctx[ParentState.cartFulfill].fulfillRequestPayments?.push(
      ...opaquePayments
    );

    return ctx;
  }
);

export const initializeCheckoutState = assign(
  (ctx: PackagesMachineContext, event: InitializeCheckoutStateEvent) => {
    ctx.flightShop.selectedTrip = event.payload.selectedTrip;
    ctx.flightShop.tripDetails = event.payload.tripDetails;
    ctx.lodgingShop.fromDate = event.payload.hotelFromDate;
    ctx.lodgingShop.untilDate = event.payload.hotelUntilDate;
    ctx.lodgingShop.selectedLodging = event.payload
      .selectedLodging as unknown as Lodging;
    ctx.lodgingShop.selectedRoom = event.payload
      .selectedRoom as unknown as RoomInfoProducts;
    ctx.lodgingShop.selectedRoomRateId =
      event.payload.selectedRoom.products[0]?.rateId?.value;
    ctx.finalizePackageResponse = event.payload.finalizePackageResponse;
    ctx.packagePricing = event.payload.packagePricing;
    ctx.lodgingShop.guests = {
      adults: event.payload.searchedAdultsCount,
      children: [
        ...event.payload.searchedChildren,
        ...event.payload.searchedInfants,
      ],
    };
    ctx.searchedDestination = event.payload.searchedDestination;
    ctx.flightSearch.departureDate = event.payload.searchedDepartureDate;
    ctx.flightSearch.returnDate = event.payload.searchedReturnDate;
    ctx.flightSearch.destination = event.payload
      .searchedDestination as ITripTerminus;
    ctx.flightSearch.origin = event.payload.searchedOrigin;
    ctx.flightShop.benchmarkSliceIds = event.payload.benchmarkSliceIds;
    ctx.lodgingShop.selectedLodgingIndex = event.payload.selectedLodgingIndex;
    ctx.selectedAccount = event.payload.selectedAccount;
    ctx.entryPoint = event.payload.entryPoint;
    ctx.discountInfo = event.payload.discountInfo;
    return ctx;
  }
);

export const addPackageProduct = assign(
  (ctx: PackagesMachineContext, _event: unknown) => {
    const packageProduct: ProductOpaqueValue = {
      type: "Package",
      value: {
        destinationName:
          ctx.searchedDestination?.label ||
          `${ctx[ParentState.lodgingShop].selectedLodging?.lodging.city}, ${
            ctx[ParentState.lodgingShop].selectedLodging?.lodging.country
          }`,
        savings: ctx.packagePricing?.totalPackageSavings?.fiat,
        ...(window.__mclean_env__.POPULATE_PACKAGES_SAVINGS_V2 &&
        (ctx.packagePricing?.totalPackageSavings?.fiat.value ?? 0) > 0
          ? {
              savingsV2: {
                amount: ctx.packagePricing?.totalPackageSavings?.fiat,
                percentage: (ctx.discountInfo as WithDiscount)
                  .savingsPercentage,
                sortExperimentVariantName: (ctx.discountInfo as WithDiscount)
                  .sortingVariant,
                discountExperimentVariantName: (
                  ctx.discountInfo as WithDiscount
                ).savingsVariant,
              },
            }
          : {}),
      },
    };
    const ctxWithPackage = cartHelpers.addQuoteProduct(packageProduct, ctx);

    return ctxWithPackage;
  }
);

export const addPackageDiscount = assign(
  (ctx: PackagesMachineContext, _event: unknown) => {
    let packageSavings = ctx.packagePricing?.totalPackageSavings?.fiat?.value;
    if (packageSavings && packageSavings > 0) {
      const packageDiscount: PaymentOpaqueValue = {
        type: "PackageSyntheticDiscount" as any, // TODO: remove 'any' cast once Payment model is updated with PackageSyntheticDiscount
        value: {},
      };

      return cartHelpers.addUpdatePayment(packageDiscount, ctx);
    }

    return ctx;
  }
);

export const setPlatform = assign(
  (ctx: PackagesMachineContext, event: SetPlatformEvent) => {
    ctx.platform = event.platform;

    return ctx;
  }
);

export const checkForPriceChange = assign(
  (context: PackagesMachineContext, event: DoneInvokeEvent<QuoteSuccess>) => {
    const packagesShopPricing = getPackagesShopPricing({ context });
    const quoteBreakdown = event.data?.quoteBreakdown;

    if (!quoteBreakdown) return context;

    const quotedPackageProduct = quoteBreakdown?.products.find(
      (product) => product.product.type === Product.Package
    );

    const quotedPackageProductValue = quotedPackageProduct?.product.value as
      | PackageOpaqueProductValue
      | undefined;

    const numTravelers =
      context[ParentState.passengerInformation].selectedPassengerIds.length +
      context[ParentState.passengerInformation].selectedLapInfantIds.length;

    const searchTravelerCount =
      (context[ParentState.lodgingShop].guests?.adults || 0) +
      (context[ParentState.lodgingShop].guests?.children.length || 0);

    const selectedTravelerCountDiffersFromSearched =
      numTravelers !== searchTravelerCount;

    const quotedTaxesAndFeesPerTraveler =
      ((quotedPackageProductValue?.totalPackagePricing?.taxesAndFeesTotal?.fiat
        .value ?? 0) +
        (quotedPackageProductValue?.totalPackagePricing?.payLaterTotal?.value ||
          0)) /
      numTravelers;

    const shopPackageSavings = Math.abs(
      packagesShopPricing?.totalPackageSavings?.fiat.value ?? 0
    );
    const shopPerTravelerDiscount = shopPackageSavings / numTravelers;

    const predictedTotal =
      (packagesShopPricing?.pricePerTraveler?.fiat.value || 0) +
      shopPerTravelerDiscount;
    const priceQuoteTotal =
      (quotedPackageProductValue?.perTravelerPricing?.subtotal?.fiat.value ||
        0) + quotedTaxesAndFeesPerTraveler; // taxes and fees are included in shop per-traveler pricing, so include in quoted value for comparison

    const difference = Math.abs(priceQuoteTotal - predictedTotal);

    const allowedValue = 1;
    const allowedCurrency = "USD";
    const allowedDifference = {
      value: allowedValue,
      currencyCode: allowedCurrency,
    };

    const actualDifference = {
      value: difference,
      currencyCode:
        quotedPackageProductValue?.perTravelerPricing?.subtotal.fiat
          .currencyCode || "USD",
    };

    const hasDifference =
      !selectedTravelerCountDiffersFromSearched && // changing pax updates pricing so don't show price change modal
      getHasPriceChanged(allowedDifference, actualDifference);

    const differenceString = difference.toFixed();
    const priceChange: PriceChangeType = {
      hasDifference,
      predictedTotal,
      priceQuoteTotal,
      ...(hasDifference
        ? {
            difference,
            differenceString,
            isIncrease: priceQuoteTotal > predictedTotal,
          }
        : {}),
    };

    context.cartQuote.priceChange = priceChange;

    return context;
  }
);

export const setRedeemedPackageDiscountAmount = assign(
  (context: PackagesMachineContext) => {
    const quotedPackageDiscount = getQuotedPackageDiscount({ context });
    const packagesShopPricing = getPackagesShopPricing({ context });

    const discountAmount =
      quotedPackageDiscount?.amount.amount ||
      packagesShopPricing?.totalPackageSavings.fiat.value;

    if (discountAmount) {
      context.redeemedPackageDiscountAmount = Math.abs(discountAmount);
    }

    return context;
  }
);
