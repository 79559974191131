import dayjs from "dayjs";
import {
  FareclassOptionFilter,
  PackagesEntryTypeEnum,
  SliceStopCountFilter,
} from "redmond";
import { getSelectedFareClasses } from "../../../utils/fareClass";

export const transformToStringifiedAvailabilityQuery = (
  origin: string,
  destination: string,
  placeId: string,
  fromDate: Date | string,
  untilDate: Date | string,
  adultsCount: number | null,
  children: number[] | null,
  infants: {
    age: number;
    inSeat: boolean;
  }[],
  stopsOptions: SliceStopCountFilter,
  fareClassOptionFilter: FareclassOptionFilter,
  entryPoint?: PackagesEntryTypeEnum
): string => {
  const encodedDestination = encodeURIComponent(destination);
  const formatFrom = dayjs(fromDate).format("YYYY-MM-DD");
  const formatUntil = dayjs(untilDate).format("YYYY-MM-DD");

  const fareClass = getSelectedFareClasses(fareClassOptionFilter).map(
    (fareClassName) => `&fareClass=${fareClassName}`
  );

  let query = `?origin=${origin}&destination=${encodedDestination}&placeId=${placeId}&fromDate=${formatFrom}&untilDate=${formatUntil}&adultsCount=${adultsCount}${children?.reduce(
    (ageString, age) => `${ageString}&children=${age}`,
    ""
  )}${infants?.reduce(
    (infantString, infant) =>
      `${infantString}&infants=${encodeURIComponent(JSON.stringify(infant))}`,
    ""
  )}&stopsOption=${stopsOptions}${fareClass}`;

  if (entryPoint) {
    query += `&entryPoint=${entryPoint}`;
  }

  return query;
};
