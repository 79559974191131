import { Person } from "@b2bportal/air-booking-api";
import { CipherText } from "@b2bportal/purchase-api";
import {
  PersonId,
  Payment,
  TripDetails,
  ISelectedTrip,
  Lodging,
  RoomInfoProducts,
  FinalizePackageResponse,
  PackagePricing,
  IResult,
  ITripTerminus,
  PackagesSliceId,
  RewardsAccount,
  PackagesEntryTypeEnum,
  DiscountInfo,
} from "redmond";
import {
  ContactEvents,
  FlightPassengerEvents,
  CartQuoteEvents,
  SeatEvents,
  CardPaymentEvents,
  CartFulfillEvents,
  RewardsPaymentEvents,
  WalletEvents,
} from "@capone/checkout";
import { IContactInfo } from "halifax";

export enum Event {
  // CommonEvents
  NEXT = "NEXT",
  PREVIOUS = "PREVIOUS",
  CHANGE = "CHANGE",
  GO_IDLE = "GO_IDLE",
  RESET_CONTEXT = "RESET_CONTEXT",

  //  PassengerInformationEvents
  SELECT_PASSENGER = "SELECT_PASSENGER",
  ADD_PASSENGER = "ADD_PASSENGER",
  UPDATE_PASSENGER = "UPDATE_PASSENGER",
  DELETE_PASSENGER = "DELETE_PASSENGER",
  OPEN_PASSENGER_FORM = "OPEN_PASSENGER_FORM",
  OPEN_PASSENGER_PICKER = "OPEN_PASSENGER_PICKER",
  OPEN_PASSPORT_FORM = "OPEN_PASSPORT_FORM",
  SET_CURRENT_PASSENGER = "SET_CURRENT_PASSENGER",
  CLEAR_CURRENT_INFANT_ID = "CLEAR_CURRENT_INFANT_ID",
  ON_INFANT_MODAL_CONTINUE = "ON_INFANT_MODAL_CONTINUE",
  CLEAR_PASSENGER_INFORMATION_ERROR = "CLEAR_PASSENGER_INFORMATION_ERROR",

  // ContactInformationEvents
  SET_CONTACT_INFO = "SET_CONTACT_INFO",
  CLEAR_CONTACT_ERROR = "CLEAR_CONTACT_ERROR",

  // PassportEvents
  UPDATE_PASSPORT_AND_CONTINUE = "UPDATE_PASSPORT_AND_CONTINUE",
  VALIDATE_PASSENGERS = "VALIDATE_PASSENGERS",
  CLEAR_PASSPORT_ERROR = "CLEAR_PASSPORT_ERROR",

  // ScheduleQuote
  CLEAR_SCHEDULE_QUOTE_ERROR = "CLEAR_SCHEDULE_QUOTE_ERROR",

  // PaymentInformation
  OPEN_PAYMENT_FORM = "OPEN_PAYMENT_FORM",
  CLOSE_PAYMENT_FORM = "CLOSE_PAYMENT_FORM",
  SET_APPLIED_BALANCE = "SET_APPLIED_BALANCE",
  VERIFY_PAYMENT_METHOD = "VERIFY_PAYMENT_METHOD",
  DELETE_PAYMENT_METHOD = "DELETE_PAYMENT_METHOD",
  SET_PAYMENT_METHOD_ID = "SET_PAYMENT_METHOD_ID",
  SET_SELECTED_PAYMENT_METHOD_ID = "SET_SELECTED_PAYMENT_METHOD_ID",
  CLEAR_PAYMENT_ERROR = "CLEAR_PAYMENT_ERROR",

  // UBER Payment
  SET_PAYMENT_DATA_AND_GO_NEXT = "SET_PAYMENT_DATA_AND_GO_NEXT",

  // Review
  GO_TO_PASSENGER_SELECT = "GO_TO_PASSENGER_SELECT",
  GO_TO_CONTACT_INFORMATION = "GO_TO_CONTACT_INFORMATION",

  // ScheduleFulfill
  CLEAR_SCHEDULE_FULFILL_ERROR = "CLEAR_SCHEDULE_FULFILL_ERROR",

  // Confirmation
  GO_HOME = "GO_HOME",

  // Transition to state from query params
  GO_TO_CART_QUOTE = "GO_TO_CART_QUOTE",
  GO_TO_SEAT_SELECTION = "GO_TO_SEAT_SELECTION",
  GO_TO_CARD_PAYMENT = "GO_TO_CARD_PAYMENT",
  GO_TO_REVIEW = "GO_TO_REVIEW",
  GO_TO_BOOKING_CONFIRMATION = "GO_TO_BOOKING_CONFIRMATION",
  CBA_SELECT_SEAT = "CBA_SELECT_SEAT",
  CBA_SKIP_SEAT = "CBA_SKIP_SEAT",
  GO_TO_HOTEL_PASSENGER_SELECT = "GO_TO_HOTEL_PASSENGER_SELECT",

  // Initializing from redux
  INITIALIZE_CHECKOUT_STATE = "INITIALIZE_CHECKOUT_STATE",

  SET_PLATFORM = "SET_PLATFORM",
}

export interface NextEvent {
  type: Event.NEXT;
}
export interface PreviousEvent {
  type: Event.PREVIOUS;
}
export interface SelectPassengerEvent {
  type: Event.SELECT_PASSENGER;
  singleTravelerWorkflow?: boolean;
  passengerId?: PersonId;
  data?: Person;
}
export interface AddPassengerEvent {
  type: Event.ADD_PASSENGER;
}
export interface UpdatePassengerEvent {
  type: Event.UPDATE_PASSENGER;
  person: Person;
  onUpdate?: boolean;
}
export interface DeletePassengerEvent {
  type: Event.DELETE_PASSENGER;
  personId: PersonId;
}

export interface GoIdleEvent {
  type: Event.GO_IDLE;
}

export interface SetCurrentPassengerEvent {
  type: Event.SET_CURRENT_PASSENGER;
  passenger?: Person;
}

export interface OpenPassengerFormEvent {
  type: Event.OPEN_PASSENGER_FORM;
}

export interface OpenPassengerPickerEvent {
  type: Event.OPEN_PASSENGER_PICKER;
}

export interface OpenPassportFormEvent {
  type: Event.OPEN_PASSPORT_FORM;
}

export interface GoIdleEvent {
  type: Event.GO_IDLE;
}

export interface ClearCurrentInfantId {
  type: Event.CLEAR_CURRENT_INFANT_ID;
}

export interface OnInfantModalContinue {
  type: Event.ON_INFANT_MODAL_CONTINUE;
  seatType: "OwnSeat" | "OnLap";
}

export interface ClearPassengerInformationError {
  type: Event.CLEAR_PASSENGER_INFORMATION_ERROR;
}

export interface OpenPaymentFormEvent {
  type: Event.OPEN_PAYMENT_FORM;
}

export interface ClosePaymentFormEvent {
  type: Event.CLOSE_PAYMENT_FORM;
}

export interface SetAppliedBalanceEvent {
  type: Event.SET_APPLIED_BALANCE;
  appliedBalance: number;
}

export interface VerifyPaymentMethodEvent {
  type: Event.VERIFY_PAYMENT_METHOD;
  token: string;
}

export interface DeletePaymentMethodEvent {
  type: Event.DELETE_PAYMENT_METHOD;
  paymentMethod: {
    paymentId: string;
  };
}

export interface SetPaymentMethodIdEvent {
  type: Event.SET_PAYMENT_METHOD_ID;
  data: {
    selectedPaymentMethodId: number | string;
    paymentMethods: Payment[];
  };
}

export interface SetSelectedPaymentMethodIdEvent {
  type: Event.SET_SELECTED_PAYMENT_METHOD_ID;
  paymentMethod: {
    paymentId: string | undefined;
    rewardsAccountId: string | undefined;
  };
}

export interface SetPaymentDataAndGoNext {
  type: Event.SET_PAYMENT_DATA_AND_GO_NEXT;
  paymentQuoteToken: CipherText;
  paymentSessionToken: string;
}

export interface ClearScheduleQuoteError {
  type: Event.CLEAR_SCHEDULE_QUOTE_ERROR;
}

export interface ClearScheduleFulfillError {
  type: Event.CLEAR_SCHEDULE_FULFILL_ERROR;
}

export interface GoToPassengerSelect {
  type: Event.GO_TO_PASSENGER_SELECT;
}

export interface GoToContactInformation {
  type: Event.GO_TO_CONTACT_INFORMATION;
}

export interface GoHomeEvent {
  type: Event.GO_HOME;
}

export interface UpdatePassportAndContinue {
  type: Event.UPDATE_PASSPORT_AND_CONTINUE;
  person: Person;
}

export interface ValidatePassengers {
  type: Event.VALIDATE_PASSENGERS;
}

export interface ClearPassportError {
  type: Event.CLEAR_PASSPORT_ERROR;
}

export interface ResetContextEvent {
  type: Event.RESET_CONTEXT;
}

export interface GoToCardPayment {
  type: Event.GO_TO_CARD_PAYMENT;
}
export interface GoToReview {
  type: Event.GO_TO_REVIEW;
}

export interface GoToSeatSelection {
  type: Event.GO_TO_SEAT_SELECTION;
}

export interface GoToBookingConfirmation {
  type: Event.GO_TO_BOOKING_CONFIRMATION;
}

export interface GoToCartQuote {
  type: Event.GO_TO_CART_QUOTE;
}

export interface GoToHotelPassengerInformation {
  type: Event.GO_TO_HOTEL_PASSENGER_SELECT;
}

export interface ClearContactError {
  type: Event.CLEAR_CONTACT_ERROR;
}

export interface SetContactInfoEvent {
  type: Event.CHANGE;
  contactInfo: IContactInfo;
}

export interface ClearPaymentError {
  type: Event.CLEAR_PAYMENT_ERROR;
}

export interface InitializeCheckoutStateEvent {
  type: Event.INITIALIZE_CHECKOUT_STATE;
  payload: {
    tripDetails: TripDetails;
    selectedTrip: ISelectedTrip;
    selectedLodging: Lodging;
    selectedRoom: RoomInfoProducts;
    hotelFromDate: string;
    hotelUntilDate: string;
    finalizePackageResponse: FinalizePackageResponse;
    packagePricing: PackagePricing;
    searchedAdultsCount: number;
    searchedChildren: number[];
    searchedInfants: number[];
    searchedDestination: IResult;
    searchedDepartureDate: Date;
    searchedReturnDate: Date | null;
    searchedOrigin: ITripTerminus;
    benchmarkSliceIds: PackagesSliceId[];
    selectedLodgingIndex?: number;
    selectedAccount?: RewardsAccount;
    entryPoint?: PackagesEntryTypeEnum;
    discountInfo?: DiscountInfo;
  };
}

export interface SetPlatformEvent {
  type: Event.SET_PLATFORM;
  platform: "desktop" | "mobile";
}

export type TEvent =
  | AddPassengerEvent
  | ClearCurrentInfantId
  | ClearPassengerInformationError
  | ClearScheduleFulfillError
  | ClearScheduleQuoteError
  | DeletePassengerEvent
  | GoHomeEvent
  | GoIdleEvent
  | GoToContactInformation
  | GoToPassengerSelect
  | NextEvent
  | OnInfantModalContinue
  | OpenPassengerFormEvent
  | OpenPassengerPickerEvent
  | OpenPassportFormEvent
  | PreviousEvent
  | SelectPassengerEvent
  | SetCurrentPassengerEvent
  | UpdatePassengerEvent
  | OpenPaymentFormEvent
  | ClosePaymentFormEvent
  | SetAppliedBalanceEvent
  | VerifyPaymentMethodEvent
  | DeletePaymentMethodEvent
  | SetPaymentMethodIdEvent
  | SetSelectedPaymentMethodIdEvent
  | UpdatePassportAndContinue
  | ValidatePassengers
  | ClearPassportError
  | ResetContextEvent
  | ClearContactError
  | SetPaymentDataAndGoNext
  | GoToCardPayment
  | GoToReview
  | GoToSeatSelection
  | GoToBookingConfirmation
  | GoToCartQuote
  | GoToHotelPassengerInformation
  | ClearPaymentError
  | InitializeCheckoutStateEvent
  // Contact Information Events
  | ContactEvents
  | SetContactInfoEvent
  // Passenger Information Events
  | FlightPassengerEvents
  // Cart Quote Events
  | CartQuoteEvents
  // Cart Fulfill Events
  | CartFulfillEvents
  // Seat Selection Events
  | SeatEvents
  // Card Payment Events
  | CardPaymentEvents
  // Rewards Payment Events
  | RewardsPaymentEvents
  | WalletEvents
  | SetPlatformEvent;
