import React, { useState, useEffect } from "react";
import { Box, TextField, Typography } from "@material-ui/core";
import {
  MobileAutocompleteOptions,
  MobilePopoverCard,
  IconName,
  Icon,
  InfoIcon,
  ActionLink,
  CloseButtonIcon,
  RecentlySearchedList,
} from "halifax";
import clsx from "clsx";
import * as H from "history";
import "./styles.scss";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ITripTerminus, IResult, RecentPackageSearch } from "redmond";
import { MobileLocationSearchModalConnectorProps } from "./container";
import * as textConstants from "./textConstants";
import { PATH_HOME } from "../../../../../../utils/paths";

export interface IMobileLocationSearchModalProps
  extends MobileLocationSearchModalConnectorProps {
  history: H.History;
  openLocationModal: boolean;
  setOpenLocationModal: (val: boolean) => void;
  searchType: "origin" | "destination";
  handleClose: () => void;
  headerText?: string;
  onDestinationSelection?: () => void;
  onSelectLocation?: (value: any) => void;
  localOrigin?: ITripTerminus | null;
  setLocalOrigin?: (origin: ITripTerminus | null) => void;
  localDestination?: IResult | null;
  setLocalDestination?: (origin: IResult | null) => void;
  recentSearches?: RecentPackageSearch[];
  onRecentSearchClick?: (search: RecentPackageSearch) => void;
}
export const MobileLocationSearchModal = (
  props: IMobileLocationSearchModalProps
) => {
  const {
    origin,
    destination,
    loading,
    originCategories,
    destinationCategories,
    fetchOriginCategories,
    fetchDestinationCategories,
    setOrigin,
    setDestination,
    hasOriginAutocompleteError,
    hasDestinationAutocompleteError,
    history,
    setOriginAutocompleteError,
    setDestinationAutocompleteError,
    openLocationModal,
    setOpenLocationModal,
    searchType,
    handleClose,
    headerText,
    onDestinationSelection,
    onSelectLocation,
    localOrigin,
    setLocalOrigin,
    localDestination,
    setLocalDestination,
    recentSearches,
    onRecentSearchClick,
  } = props;
  const [originFocused, setOriginFocused] = useState<boolean>(false);
  const [destinationFocused, setDestinationFocused] = useState<boolean>(false);
  const [originSearchString, setOriginSearchString] = useState<string>(
    origin?.label || ""
  );
  const [destinationSearchString, setDestinationSearchString] =
    useState<string>(destination?.label || "");

  useEffect(() => {
    if (localOrigin?.label) {
      setOriginSearchString(localOrigin?.label);
    }
  }, [localOrigin]);

  useEffect(() => {
    if (localDestination?.label) {
      setDestinationSearchString(localDestination.label);
    }
  }, [localDestination]);

  const onOriginSearch = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    hasOriginAutocompleteError && setOriginAutocompleteError(false);
    setLocalOrigin ? setLocalOrigin(null) : setOrigin(null);
    setOriginSearchString(event.target.value);
    fetchOriginCategories(event.target.value);
  };

  const onDestinationSearch = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    hasDestinationAutocompleteError && setDestinationAutocompleteError(false);
    setLocalDestination ? setLocalDestination(null) : setDestination(null);
    setDestinationSearchString(event.target.value);
    fetchDestinationCategories(event.target.value);
  };

  const onOriginSelected = (value: any) => {
    setOriginFocused(false);
    setOriginSearchString(value.label);
    setLocalOrigin ? setLocalOrigin(value) : setOrigin(value);
    onSelectLocation?.(value);
    handleClose();
  };

  const onDestinationSelected = (value: any) => {
    setDestinationFocused(false);
    setDestinationSearchString(value.label);
    setLocalDestination ? setLocalDestination(value) : setDestination(value);
    onSelectLocation?.(value);
    handleClose();
    onDestinationSelection && onDestinationSelection();
  };
  const onClearOriginField = () => {
    setOrigin(null);
    setOriginSearchString("");
    fetchOriginCategories("");
  };

  const onClearDestinationField = () => {
    setDestination(null);
    setDestinationSearchString("");
    fetchDestinationCategories("");
  };
  const renderInputType = () => {
    switch (searchType) {
      case "origin":
        return (
          <Box className="mobile-packages-search-location-input-field">
            <Icon name={IconName.B2BMapPin} />
            <TextField
              className={clsx("location-auto-complete", "mobile")}
              label={textConstants.WHERE_FROM}
              InputProps={{
                disableUnderline: true,
              }}
              autoFocus
              onFocus={() => {
                setOriginFocused(true);
                setDestinationFocused(false);
              }}
              onChange={(value) => onOriginSearch(value)}
              value={originSearchString}
            />
            {/* Note: Instead of conditionally rendering this icon component, a className of 'hidden' is conditionally used so it can take the style "visibility:hidden"
            This style will allocate space for the icon even when it's not shown - this prevents the sibling elements from changing when it's shown or hidden */}
            <FontAwesomeIcon
              className={clsx("icon", { hidden: !originSearchString })}
              icon={faTimesCircle}
              onClick={onClearOriginField}
            />
          </Box>
        );
      case "destination":
        return (
          <Box className="mobile-packages-search-location-input-field">
            <Icon name={IconName.B2BMapPin} />
            <TextField
              className={clsx("location-auto-complete", "mobile")}
              label={textConstants.WHERE_TO}
              InputProps={{
                disableUnderline: true,
              }}
              autoFocus
              onFocus={() => {
                setDestinationFocused(true);
                setOriginFocused(false);
              }}
              onChange={(value) => onDestinationSearch(value)}
              value={destinationSearchString}
            />
            <FontAwesomeIcon
              className={clsx("icon", { hidden: !destinationSearchString })}
              icon={faTimesCircle}
              onClick={onClearDestinationField}
            />
          </Box>
        );
      default:
        return null;
    }
  };

  const MobileSearchPopoverHeader = () => {
    return (
      <Box className="modal-header-container">
        <Typography className="header-title">
          {headerText ? headerText : textConstants.FLIGHTS}
        </Typography>
        <ActionLink
          className="modal-close-button"
          onClick={() => {
            if (setLocalOrigin && !localOrigin) {
              setLocalOrigin(origin);
            }
            if (setLocalDestination && !localDestination) {
              setLocalDestination(destination);
            }
            handleClose();
          }}
          content={<CloseButtonIcon className="close-button-icon" />}
          label="Close"
          showTappableArea
        />
      </Box>
    );
  };

  return (
    <MobilePopoverCard
      open={openLocationModal}
      className={"mobile-packages-search-location-popup"}
      onClose={() => {
        if (setLocalOrigin && !localOrigin) {
          setLocalOrigin(origin);
        }
        if (setLocalDestination && !localDestination) {
          setLocalDestination(destination);
        }
        setOpenLocationModal(false);
      }}
      headerElement={<MobileSearchPopoverHeader />}
    >
      <Box className="mobile-packages-search-location-modal-content">
        {renderInputType()}

        {(hasOriginAutocompleteError || hasDestinationAutocompleteError) && (
          <Box className="autocomplete-error-container">
            <InfoIcon />
            <span className="autocomplete-error-text">
              Error fetching results.{" "}
            </span>
            <span
              onClick={() => {
                history.push(PATH_HOME);
                hasOriginAutocompleteError && setOriginAutocompleteError(false);
                hasDestinationAutocompleteError &&
                  setDestinationAutocompleteError(false);
              }}
              className="try-again-text"
            >
              Try again.
            </span>
          </Box>
        )}

        {originFocused && (
          <MobileAutocompleteOptions
            value={null}
            valueCategories={originCategories}
            setValue={(value: any) => onOriginSelected(value)}
            loading={loading}
            loadingText={"Loading"}
            hideSidePadding
          />
        )}
        {destinationFocused && (
          <MobileAutocompleteOptions
            value={null}
            valueCategories={destinationCategories}
            setValue={(value: any) => onDestinationSelected(value)}
            loading={loading}
            loadingText={"Loading"}
            hideSidePadding
          />
        )}

        {!loading &&
          originFocused &&
          originSearchString.length === 0 &&
          originCategories &&
          (originCategories?.length === 0 ||
            originCategories[0]?.results.length === 0) &&
          !!recentSearches &&
          recentSearches.length > 0 &&
          !!onRecentSearchClick && (
            <div
              className={clsx("mobile-recently-searched-packages-container", {
                "has-results": originCategories?.length > 0,
              })}
            >
              <RecentlySearchedList
                recentSearches={recentSearches}
                onRecentSearchClick={(recentSearch) =>
                  onRecentSearchClick(recentSearch as RecentPackageSearch)
                }
              />
            </div>
          )}
      </Box>
    </MobilePopoverCard>
  );
};
