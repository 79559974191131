import { connect, ConnectedProps } from "react-redux";
import { MobilePackagesSearchControl } from "./component";
import { IStoreState } from "../../../../reducers/types";
import { withRouter } from "react-router";
import {
  getDepartureDate,
  getDestination,
  getFareclassOptionFilter,
  getOrigin,
  getReturnDate,
  getStopsOption,
  getTravelers,
} from "../../reducer";
import {
  setDepartureDate,
  setDestination,
  setFareclassOptionFilter,
  setOrigin,
  setReturnDate,
  setStopsOption,
  setTravelers,
} from "../../actions/actions";

const mapStateToProps = (state: IStoreState) => ({
  departureDate: getDepartureDate(state),
  returnDate: getReturnDate(state),
  origin: getOrigin(state),
  destination: getDestination(state),
  travelers: getTravelers(state),
  stopsOptions: getStopsOption(state),
  fareClassOptionFilter: getFareclassOptionFilter(state),
  setOrigin,
  setDestination,
  setDepartureDate,
  setReturnDate,
  setStopsOption,
  setFareclassOptionFilter,
  setTravelers,
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type MobilePackagesSearchControlConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedMobilePackagesSearchControl = withRouter(
  connector(MobilePackagesSearchControl)
);
