import React from "react";

export const EARN_ENHANCEMENT_SUBTITLE = (
  earnRate: string | number,
  productDisplayName: string,
  currency: string
) => {
  const multiplier = currency.includes("cash") ? "%" : "X";
  return (
    <>
      <span className="font-bold">
        Earn {earnRate}
        {multiplier} {currency}
      </span>{" "}
      on flights with your {productDisplayName} account.
    </>
  );
};

export const SEARCHING_FOR_FLIGHTS_SECONDARY_MESSAGE = (
  <>
    Book your hotel and flight together to <strong>unlock special rates</strong>
    .
  </>
);
