import {
  getClickCancelOOPModalEvent,
  getClickContinueOOPModalEvent,
  getShowOOPModalEvent,
  isCorpTenant,
  useShowPolicyBanner,
} from "@capone/common";
import { Box, Typography } from "@material-ui/core";
import {
  BackToTopButton,
  HotelShopRoomTypePickerEnum,
  HotelShopRoomTypePickerVariant,
  Icon,
  IconName,
  PackageHotelShopRoomTypePicker,
  TrackingEventControlType,
} from "halifax";
import React, { useContext, useEffect, useRef } from "react";
import { RouteComponentProps } from "react-router";
import {
  LodgingPriceFreezeOffer,
  ModalScreens,
  SELECT_PACKAGE_HOTEL_ROOM,
  ViewedCorpRateDescriptorEntryPoints,
} from "redmond";
import { ClientContext } from "../../../../App";
import { config } from "../../../../api/config";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";

// import { TravelWalletDetailsBanner } from "../../../travel-wallet/components/TravelWalletDetailsBanner";
import { getEarnTagText } from "../../textConstants";
// import { MobileShopHeader } from "../MobileShopHeader";
import { MobileRoomPickerConnectorProps } from "./container";
import { onOpenCompareBarTooltip } from "../../../../utils/events";
import "./styles.scss";
import { MobileShopHeader } from "../MobileShopHeader";

export interface IMobileRoomPickerProps
  extends MobileRoomPickerConnectorProps,
    RouteComponentProps {
  roomInfoProductsType?: HotelShopRoomTypePickerVariant;
  handleReadyToRedirect?: (
    trackingEventControl?: TrackingEventControlType
  ) => void;
  baseHotelPriceFreezeOffer?: LodgingPriceFreezeOffer | null | undefined;
  nonRefundablePolicyOverrideText?: string;
}

export const MobileRoomPicker = (props: IMobileRoomPickerProps) => {
  const {
    checkinDate,
    checkoutDate,

    chosenProduct,
    selectRoomType,
    // selectedLodging,
    chosenRoomInfoIndex,
    largestValueAccount,
    handleReadyToRedirect,
    roomInfoProductsType,

    canEarnRewards,
    nightCount,
  } = props;
  const isFirstUpdate = useRef<boolean>(true);

  const showEarnEnhancement =
    !!largestValueAccount &&
    !!largestValueAccount.earn.hotelsMultiplier &&
    canEarnRewards;

  const { isAutoApprovalEnabled, policies } = useContext(ClientContext);

  const modalType = isAutoApprovalEnabled
    ? "out_of_policy_auto"
    : "out_of_policy";

  const showPolicyBanner = useShowPolicyBanner(policies);

  useEffect(() => {
    if (checkinDate && checkoutDate) {
      // skip the first update
      if (isFirstUpdate.current) {
        isFirstUpdate.current = false;
      }
    }
  }, [checkinDate, checkoutDate]);

  useEffect(() => {
    !chosenProduct && selectRoomType(0, 0);
    setTimeout(() => {
      const id = "chosen-room";
      const yOffset = -122.5;
      const element = document.getElementById(id);
      const y =
        (element?.getBoundingClientRect().top ?? 0) +
        window.pageYOffset +
        yOffset;
      if (chosenRoomInfoIndex || chosenRoomInfoIndex === 0) {
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    }, 50);
  }, []);
  // const [showOfferBanner, setShowOfferBanner] = useState(false);

  return (
    <Box>
      <MobileShopHeader />
      {/* {showOfferBanner && (
        <TravelWalletDetailsBanner
          onDismiss={() => setShowOfferBanner(false)}
          offer={selectedLodging?.bestOfferThisLodging!}
          className="mobile-hotel-shop-offer"
          screen={SelectedTravelOfferScreen.HOTEL_DETAILS}
          bannerTextType="shop"
        />
      )} */}
      <PackageHotelShopRoomTypePicker
        {...props}
        roomInfoProductsType={
          roomInfoProductsType ?? {
            roomInfoProducts: props.roomInfoProducts,
            variant: HotelShopRoomTypePickerEnum.Default,
          }
        }
        isMobile={true}
        className="b2b"
        onClickContinue={(
          room_type?: string,
          in_policy?: boolean,
          hotel_loyalty_eligible?: boolean,
          is_corporate?: boolean
        ) => {
          if (handleReadyToRedirect) {
            const room =
              props.chosenRoomInfo && props.chosenRoomInfo.roomInfo.name;
            handleReadyToRedirect({
              [SELECT_PACKAGE_HOTEL_ROOM]: {
                properties: {
                  room_type: room ?? room_type,
                  provider_name: props.chosenProduct?.providerName,
                  rate_provider: props.chosenProduct?.providerName,
                  ...(isCorpTenant(config.TENANT) && {
                    in_policy,
                    hotel_loyalty_eligible,
                    is_corporate,
                  }),
                },
              },
            });
          }
        }}
        earnTagContent={
          showEarnEnhancement ? (
            <>
              <Icon name={IconName.StarIcon} />
              <Typography
                className="earn-tag-text"
                dangerouslySetInnerHTML={{
                  __html: getEarnTagText(
                    largestValueAccount.earn.hotelsMultiplier,
                    largestValueAccount.rewardsBalance.currencyDescription ??
                      largestValueAccount.rewardsBalance.currency
                  ),
                }}
              />
            </>
          ) : undefined
        }
        earnTagClassName={showEarnEnhancement ? "b2b" : undefined}
        variant={"refundable-room-ux"}
        isApprovalRequired={!isAutoApprovalEnabled}
        onShowOutOfPolicyModal={() =>
          trackEvent(
            getShowOOPModalEvent(ModalScreens.HOTELS_SHOP, "hotels", modalType)
          )
        }
        onClickOutOfPolicyContinue={() =>
          trackEvent(
            getClickContinueOOPModalEvent(
              ModalScreens.HOTELS_SHOP,
              "packages",
              modalType
            )
          )
        }
        onClickOutOfPolicyCancel={() =>
          trackEvent(
            getClickCancelOOPModalEvent(
              ModalScreens.HOTELS_SHOP,
              "packages",
              modalType
            )
          )
        }
        isGlobalMobileNavExperiment
        onViewCorpCompareBar={onOpenCompareBarTooltip(
          ViewedCorpRateDescriptorEntryPoints.HOTELS_ROOM_DETAILS
        )}
        showPolicyBanner={showPolicyBanner}
      />

      <BackToTopButton bottom={(nightCount || 0) >= 3 ? undefined : 100} />
    </Box>
  );
};
