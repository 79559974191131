import React, { useContext } from "react";
import { Box, Typography } from "@material-ui/core";
import {
  FlightGridRow,
  FlightShopRow,
  formatInterval,
  getTotalPriceText,
  B2BFlightCardV2,
  TriangleIcon,
  useDeviceTypes,
  IconName,
  Icon,
  ButtonWrap,
  removeTimezone,
} from "halifax";
import clsx from "clsx";
import * as textConstants from "./textConstants";
import {
  FlightRatingsEnum,
  Flights,
  IFlightGridFares,
  Slice,
  TravelWalletOffer,
  TravelWalletCredit,
  RewardsAccount,
  PolicyViolation,
  TripCategory,
  IFlightListData,
  FareclassOptionFilter,
  SafePackageDetails,
  DateRange,
} from "redmond";
// import { getStopoverDurationForSegments } from "@capone/common";
// import { isInDisruptionProtectionRebookSelector } from "../../../../../reducer";
// import { getAddedLegacyPrices } from "../../../../../../book/reducer/utils/pricingHelpers";
// import { IFlightListData } from "../../component";
// import { isCfarEligible } from "../../../../../../ancillary/utils/refundableFareHelpers";
// import {
//   AVAILABLE,
//   getExperimentVariant,
//   getExperimentVariantCustomVariants,
//   INTERNATIONAL_NGS_EXPERIMENT,
//   TRAVEL_WALLET_OFFER_EXPERIMENT,
//   useExperiments,
//   VI_SHOP_BANNERS_EXPERIMENT,
//   VI_SHOP_BANNERS_GRAY_BANNER,
//   VI_SHOP_BANNERS_NO_BANNER,
//   VI_SHOP_BANNERS_VARIANTS,
// } from "../../../../../../../context/experiments";
// import { trackEvent } from "../../../../../../../api/v0/analytics/trackEvent";
import "./styles.scss";
import { ClientContext } from "../../../../../../App";
import { useShowPolicyBanner } from "@capone/common";
import dayjs from "dayjs";
// import { config } from "../../../../../../api/config";
// import { isFlightRecommendedBasedOnPreferences } from "../../../../utils";

export enum FlightCardType {
  content = "content",
  skeleton = "skeleton",
}

interface IFlightListInfoBaseProps {
  className?: string;
  type: FlightCardType;
}

export const isFlightMultiTicketType = ({
  flight,
  slices,
}: {
  flight?: IFlightListData;
  slices?: Record<string, Slice>;
}) => {
  if (flight && slices) {
    const { hasSelfTransferLayover } = slices[flight.slice];
    return hasSelfTransferLayover;
  }
  return false;
};

export interface IFlightListInfoContentProps extends IFlightListInfoBaseProps {
  selectedFare: any;
  slice: Slice;
  onClick?: (selectedFareClass: string) => void;
  onFareClick?: (fareId: string) => void;
  isExpanded?: boolean;
  type: FlightCardType.content;
  flights: Flights;
  flight: IFlightListData;
  rewardsKey: string;
  fareClassFilter: FareclassOptionFilter;
  onAlgomerchClick: (val: string) => void;
  maxFlightPriceFilter: number;
  isRoundTrip: boolean;
  offersByTripId?: { [key: string]: TravelWalletOffer };
  showPriceAndTags?: boolean;
  credit?: TravelWalletCredit;
  largestValueAccount?: RewardsAccount;
  onClickSelfCheckBanner?: () => void;
  isChatbotEnabled?: boolean;
  onOpenPolicyDescriptor?: (
    entryPoint: string,
    policyReasons: PolicyViolation[]
  ) => void;
  isOutgoing: boolean;
  isInPolicyFilter?: boolean;
  isAgentPortal?: boolean;
  packagesByFareSlice: { [key: string]: SafePackageDetails };
  recommendedFlight?: boolean;
  departureDate: Date | null;
  returnDate: Date | null;
  hotelShopDateRange: DateRange | null;
}

export interface IFlightListInfoSkeletonProps extends IFlightListInfoBaseProps {
  type: FlightCardType.skeleton;
}

interface GridFaresPerShelf {
  basic: number;
  standard: number;
  premium: number;
  enhanced: number;
  luxury: number;
}

const FARE_GRID_TAG_LIMIT = 2;

export const FlightListInfoContent = (props: IFlightListInfoContentProps) => {
  const {
    selectedFare,
    slice,
    isExpanded,
    flight,
    onClick,
    flights,
    rewardsKey,
    fareClassFilter,
    onAlgomerchClick,
    onFareClick,
    maxFlightPriceFilter,
    isRoundTrip,
    offersByTripId,
    showPriceAndTags = true,
    credit,
    largestValueAccount,
    isChatbotEnabled,
    isAgentPortal,
    packagesByFareSlice,
    recommendedFlight,
    departureDate,
    returnDate,
    isOutgoing,
    hotelShopDateRange,
  } = props;

  const getFareSliceId = (fare: any) => {
    return fare?.fareSlice || fare.return;
  };

  const getFareId = (fare: any) => {
    return fare.example?.fare || fare.id;
  };

  const { matchesMobile, matchesDesktop, matchesLargeDesktop } =
    useDeviceTypes();

  const showEarnEnhancement =
    !!largestValueAccount && !!largestValueAccount.earn.flightsMultiplier;

  const matchesMediumDesktopOnly = matchesDesktop && !matchesLargeDesktop;

  const airline = flights.airlines[slice.marketingAirline];
  const airlineCode = airline ? airline.code : slice.marketingAirline;
  const airlineName = airline ? airline.displayName : slice.marketingAirline;

  function getFlightGridFares(flight: any, _?: string) {
    const fares: IFlightGridFares = {
      basic: null,
      standard: null,
      premium: null,
      enhanced: null,
      luxury: null,
    };

    const faresPerShelf = flight.fares.reduce(
      (result: GridFaresPerShelf, fare: any) => {
        const fareSlice = flights.fareSlices[getFareSliceId(fare)];
        result[FlightRatingsEnum[fareSlice.fareShelf.value]] += 1;
        return result;
      },
      {
        basic: 0,
        standard: 0,
        premium: 0,
        enhanced: 0,
        luxury: 0,
      }
    );

    flight.fares.forEach((fare: any) => {
      const fareSliceId = getFareSliceId(fare);
      const fareSlice = flights.fareSlices[fareSliceId];
      const packageByFareSlice = packagesByFareSlice[fareSliceId];

      if (packageByFareSlice) {
        // let reward: RewardsPrice | undefined;

        const gridFare = {
          fareId: getFareId(fare),
          tripId: fare.tripId,
          fareName: fareSlice.fareBrandName ?? "",
          // note: rawPrice is used in the price-range filtering logic, so it shouldn't include the CFAR price
          rawPrice: packageByFareSlice.pricing.pricePerTraveler.fiat.value,
          tags: [],
        };

        // reward = rewardsKey
        //   ? packageByFareSlice.cheapestPackage.incrementalPricePerTraveler
        //       .rewards[rewardsKey]
        //   : undefined;

        gridFare["price"] = getTotalPriceText({
          price: packageByFareSlice.incrementalPricePerTraveler?.fiat,
        });
        gridFare["prices"] = packageByFareSlice.pricing;

        // //  When there are multiple fares per shelf, set additional fares count to display
        if (faresPerShelf[FlightRatingsEnum[fareSlice.fareShelf.value]] > 1) {
          gridFare["additionalFares"] =
            faresPerShelf[FlightRatingsEnum[fareSlice.fareShelf.value]] - 1;
        }

        // if (reward) {
        //   gridFare["reward"] = getRewardsString(reward, true);
        // }

        // When there are multiple fares per shelf, display the lowest on the grid level
        fares[FlightRatingsEnum[fareSlice.fareShelf.value]] = getLowerGridFare(
          fares[FlightRatingsEnum[fareSlice.fareShelf.value]],
          gridFare
        );
      }
    });
    return fares;
  }

  function getLowerGridFare(existingFare: any, newFare: any) {
    const existingPrice = existingFare?.rawPrice;
    return existingPrice && existingPrice < newFare.rawPrice
      ? existingFare
      : newFare;
  }

  const gridFares = React.useMemo(
    () => getFlightGridFares(flight, rewardsKey),
    [flight, rewardsKey]
  );

  const offerToShow = React.useMemo(() => {
    const bestOffer = offersByTripId?.[flight.fares[0]?.tripId];

    return bestOffer?.amount.amount === credit?.amount.amount
      ? undefined
      : bestOffer;
  }, [offersByTripId, credit]);

  const fareSlice = flights.fareSlices[getFareSliceId(selectedFare)];

  const currentPriceText: string = (() => {
    const fareSlice = flights.fareSlices[getFareSliceId(selectedFare)];
    const priceText =
      gridFares[FlightRatingsEnum[fareSlice.fareShelf.value]]?.price;
    if (!priceText?.startsWith("-")) {
      return `+${priceText}`;
    } else {
      return priceText;
    }
  })();

  const { policies } = useContext(ClientContext);
  const showPolicyBanner = useShowPolicyBanner(policies);

  // const totalDurationWithoutStopover =
  //   (slice.totalDurationMinutes ?? 0) -
  //   getStopoverDurationForSegments(slice.segments);

  // const policyLimit = getFlightPolicyLimit(
  //   policies?.flights,
  //   totalDurationWithoutStopover,
  //   isRoundTrip
  // );

  // Small / Medium desktop view
  const renderRowDesktopFlightListInfo = () => {
    return (
      <div>
        <ButtonWrap
          className="flight-row-wrapper"
          aria-label="More flight details"
          onClick={handleOnClick}
          aria-expanded={isExpanded}
        >
          <FlightShopRow
            isMultiTicketType={slice.hasSelfTransferLayover}
            segments={slice.segments}
            isAgentPortal={isAgentPortal}
            airlineCode={airlineCode}
            airlineName={airlineName}
            arrivalTime={slice.arrival}
            className={clsx("small-flight-shop-row", "b2b")}
            currentPriceText={showPriceAndTags ? currentPriceText : undefined}
            departureTime={slice.departure}
            destinationCode={slice.destination}
            duration={formatInterval(slice.totalDurationMinutes)}
            key={slice.id}
            layoverString={textConstants.getStopsString(slice.stops)}
            onClickTag={(label: string) => onAlgomerchClick(label)}
            originCode={slice.origin}
            tags={[]}
            tagsLimit={FARE_GRID_TAG_LIMIT}
            isChatbotEnabled={isChatbotEnabled}
            isAirCXV3Experiment={true}
          />
          <TriangleIcon
            className={clsx("expand-flight-row-icon", {
              "is-expanded": isExpanded,
            })}
          />
        </ButtonWrap>
      </div>
    );
  };

  // Large desktop view
  const renderGridDesktopFlightListInfo = () => {
    return (
      <FlightGridRow
        viNotificationBannerBox={() => <Box />}
        key={slice.id}
        isMultiTicketType={slice.hasSelfTransferLayover}
        segments={slice.segments}
        isAgentPortal={isAgentPortal}
        tripType={isRoundTrip ? TripCategory.ROUND_TRIP : TripCategory.ONE_WAY}
        className={clsx("flight-grid-row", `row-${slice.id}`)}
        airlineCode={airlineCode}
        airlineName={airlineName}
        fareClassFilter={fareClassFilter}
        selectedMaxPrice={maxFlightPriceFilter}
        fares={gridFares}
        departureTime={slice.departure}
        arrivalTime={slice.arrival}
        originCode={slice.origin}
        destinationCode={slice.destination}
        duration={formatInterval(slice.totalDurationMinutes)}
        layoverString={textConstants.getStopsString(slice.stops)}
        onFareClick={(fareId) => {
          onFareClick && onFareClick(fareId);
        }}
        algomerchModalOpen={false}
        onAlgomerchInfoClick={(label: string) => onAlgomerchClick(label)}
        tagsLimit={FARE_GRID_TAG_LIMIT}
        isExpanded={isExpanded}
        fareCardClassName="b2b"
        type="content"
        bestOffer={offerToShow}
        earnString={
          showEarnEnhancement ? (
            <>
              <Icon name={IconName.StarIcon} />
              <Typography
                className="earn-tag-text"
                dangerouslySetInnerHTML={{
                  __html: textConstants.getEarnTagText(
                    largestValueAccount!.earn.flightsMultiplier
                  ),
                }}
              />
              {}
            </>
          ) : undefined
        }
        isChatbotEnabled={isChatbotEnabled}
        isAirCXV3Experiment={true}
        showIncrementalPrices={true}
        hideIsInPolicy={!showPolicyBanner}
        // limit={policyLimit}
        // isInPolicyFilter={isInPolicyFilter}
      />
    );
  };

  const renderMobileFlightListInfo = () => {
    const airports = slice.segments
      .slice(0, slice.segments.length - 1)
      .map((s) => s.destination);

    return (
      <Box className="flight-card-wrapper">
        <B2BFlightCardV2
          isMultiTicketType={slice.hasSelfTransferLayover}
          viNotificationBannerBox={() => <Box />}
          segments={slice.segments}
          duration={formatInterval(slice.totalDurationMinutes)}
          currentPriceText={currentPriceText}
          rewardText=""
          originCode={slice.origin}
          destinationCode={slice.destination}
          departureTime={slice.departure}
          arrivalTime={slice.arrival}
          airports={airports}
          brandName={fareSlice?.fareBrandName ?? ""}
          primaryCarrier={airlineCode}
          isAgentPortal={isAgentPortal}
          airlineName={airlineName}
          tags={[]}
          bestOffer={offerToShow}
          showHeader={showPriceAndTags}
          earnString={
            showEarnEnhancement ? (
              <>
                <Icon name={IconName.StarIcon} />
                <Typography
                  className="earn-tag-text"
                  dangerouslySetInnerHTML={{
                    __html: textConstants.getEarnTagText(
                      largestValueAccount!.earn.flightsMultiplier
                    ),
                  }}
                />
                {}
              </>
            ) : undefined
          }
          earnTagClassName="b2b"
          viewPricingPrompt={isChatbotEnabled}
          isAirCXV3Experiment={true}
        />
      </Box>
    );
  };

  const handleOnClick = () => {
    onClick && onClick(fareSlice.fareBrandName);
    if (!matchesLargeDesktop) {
      onFareClick && onFareClick(getFareId(selectedFare));
    }
  };

  // const showPolicyBanner =
  //   matchesMediumDesktopOnly || (matchesLargeDesktop && showListView);

  const fareSlicePackage = packagesByFareSlice[fareSlice.id];

  const originalStayLength = isOutgoing
    ? dayjs(returnDate).diff(departureDate, "days") // compare outgoing stay length with searched stay length
    : dayjs(hotelShopDateRange?.until).diff(hotelShopDateRange?.from, "days"); // compare return stay length with shop dates stay length (updated when selecting outgoing)
  const adjustedStayLength = dayjs(
    fareSlicePackage?.lodgingStayDates?.until
  ).diff(fareSlicePackage?.lodgingStayDates?.from, "days");

  const showAdjustedStayLengthBanner =
    adjustedStayLength !== originalStayLength;

  return (
    <>
      <Box
        className={clsx("flight-list-info-root", {
          recommended: recommendedFlight,
        })}
        onClick={
          matchesLargeDesktop || matchesMobile
            ? () => handleOnClick()
            : undefined
        }
      >
        {recommendedFlight && (
          <Box className="recommended-flight-banner">
            <Typography
              className="recommended-flight-banner-text"
              variant="body1"
            >
              {textConstants.RECOMMENDED_FLIGHT_BANNER_TEXT}
            </Typography>
          </Box>
        )}
        {matchesLargeDesktop && renderGridDesktopFlightListInfo()}
        {matchesMediumDesktopOnly && renderRowDesktopFlightListInfo()}
        {matchesMobile && renderMobileFlightListInfo()}
        {showAdjustedStayLengthBanner ? (
          <Box className="adjusted-stay-length-banner">
            <Icon name={IconName.HotelFunnelIcon} />
            <Typography className="adjusted-stay-length-text">
              {textConstants.ADJUSTED_STAY_LENGTH_TEXT(
                removeTimezone(slice.arrival),
                removeTimezone(slice.departure),
                originalStayLength,
                adjustedStayLength,
                isOutgoing
              )}
            </Typography>
          </Box>
        ) : undefined}
      </Box>
    </>
  );
};

export type IFlightListInfoProps =
  | IFlightListInfoContentProps
  | IFlightListInfoSkeletonProps;

export const FlightListInfo = (props: IFlightListInfoProps) => {
  switch (props.type) {
    case "content":
      return <FlightListInfoContent {...props} />;
    case "skeleton":
      return (
        <Box className={"flight-list-info-root"}>
          <FlightGridRow {...props} />
        </Box>
      );
    default:
      return <></>;
  }
};
