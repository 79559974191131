import React, { useState, useContext, useEffect, useCallback } from "react";
// import { useSelector } from "react-redux";
import {
  FlightDetailsCard,
  MixedCabinToolTip,
  FareDetailsCardCtaTitles,
  IFareCustomization,
  removeTimezone,
  useDeviceTypes,
} from "halifax";
import {
  Airport,
  FareDetails,
  FlightRatingsEnum,
  TripDetails,
  TripSegment,
  AirEntryProperties,
  MultiTicketTypeEnum,
  PolicyViolation,
  FiatPrice,
  FareclassOptionFilter,
  TripSlice,
  FareSliceDetails,
} from "redmond";
import * as textConstants from "./textConstants";
import { Typography } from "@material-ui/core";

import clsx from "clsx";
import "./styles.scss";

import dayjs from "dayjs";
import { ClientContext } from "../../../../../../App";
import { MobileFlightDetailsModal } from "../../../MobileFlightDetailsModal";
import {
  COMBINATION_FLIGHT_TOOLTIP,
  COMBINATION_FLIGHT_WARNING,
} from "./textConstants";
import { useShowPolicyBanner } from "@capone/common";

interface IFlightDetailsProps {
  isOutgoing: boolean;
  selectedFareId: string;
  onFareClick: (fareId: string, limit?: FiatPrice | null) => void;
  onAlgomerchClick?: (label: string) => void;
  tripDetails: TripDetails;
  rewardsKey: string | undefined;
  departureDate: Date | undefined | null;
  returnDate: Date | undefined | null;
  airports: { [key: string]: Airport };
  outgoingFareRating?: number;
  openMobileFlightDetailsModal?: boolean;
  setOpenMobileFlightDetailsModal?: (val: boolean) => void;
  noticeBannerContent?: JSX.Element;
  ctaTitles?: FareDetailsCardCtaTitles;
  fareCustomizations?: { [key in string]: IFareCustomization };
  showFareDetailsTitle?: boolean;
  isSeatsUXOptimizationExperiment?: boolean;
  isChatbotEnabled?: boolean;
  airEntryProperties?: AirEntryProperties;
  selectedOutboundFare?: any;
  isThebesHackerFaresV2Cap1ExperimentAvailable?: boolean;
  onOpenPolicyDescriptor?: (
    entryPoint: string,
    policyReasons: PolicyViolation[]
  ) => void;
  isSpiritOrFrontierAirlinesSelected: boolean;
  fareClassFilter?: FareclassOptionFilter;
  hasAppliedFareClassFilter?: boolean;
  isMobile?: boolean;
}

export const getEmptyRestrictionsText = (fareRating: number | undefined) => {
  switch (fareRating) {
    case FlightRatingsEnum.basic:
      return textConstants.BASIC_DETAILS_TEXT;
    case FlightRatingsEnum.standard:
      return textConstants.STANDARD_DETAILS_TEXT;
    case FlightRatingsEnum.enhanced:
      return textConstants.ENHANCED_DETAILS_TEXT;
    case FlightRatingsEnum.premium:
      return textConstants.PREMIUM_DETAILS_TEXT;
    case FlightRatingsEnum.luxury:
      return textConstants.LUXURY_DETAILS_TEXT;
    default:
      return "";
  }
};

export const airlinesCountTripSegment = (segmentDetails: TripSegment[]) => {
  const set = new Set(
    (segmentDetails ?? []).map((segment) => segment.marketingAirline.code)
  );
  return set.size - 1;
};

export const layoverDetails = ({
  tripDetails,
  airports,
  isOutgoing,
}: {
  airports: { [key: string]: Airport };
  tripDetails: TripDetails;
  isOutgoing: boolean;
}) => {
  return tripDetails.slices
    .filter((slice) => isOutgoing === slice.outgoing)
    .map((slice) =>
      slice.segmentDetails.map(
        ({ airlineCode, originCode, destinationCode, airlineName }) => ({
          airlineCode,
          originCode,
          destinationCode,
          airlineName,
          originCity: airports[originCode].cityName,
          destinationCity: airports[destinationCode].cityName,
        })
      )
    )
    .flat();
};

export const getSliceIndex = (
  departure: boolean,
  details: TripDetails | FareDetails
): number => {
  return details.slices.findIndex((slice: TripSlice | FareSliceDetails) =>
    departure ? slice.outgoing : !slice.outgoing
  );
};

export const getIsMixedClassByProps = (props: {
  fareShelfRating: number | undefined;
  fareShelfBrandName: string | undefined;
  fareShelfShortBrandName: string | undefined;
  cabinClassNames: (string | undefined)[];
}): boolean => {
  const { cabinClassNames } = props;
  return !cabinClassNames.every((c) => c === cabinClassNames[0]);
};

export const getIsMixedClass = (slice: FareSliceDetails): boolean => {
  return getIsMixedClassByProps({
    fareShelfRating: slice.fareShelf?.rating,
    fareShelfBrandName: slice.fareShelf?.brandName,
    fareShelfShortBrandName: slice.fareShelf?.shortBrandName,
    cabinClassNames: slice.fareDetails.segments.map(
      (segment) => segment.cabinClassName
    ),
  });
};

const OUTBOUND = "Outbound";
const RETURN = "Return";

export const getReviewCardHeader = (
  isDeparture: boolean,
  location: string,
  date: Date,
  parsable = false
) =>
  //Added semicolon to parse into two phrases
  `${isDeparture ? OUTBOUND : RETURN} to ${location} ${
    parsable ? ":" : ""
  } on ${dayjs(date).format("ddd, MMM D")}`;

export const FlightDetails = ({
  tripDetails,
  isOutgoing,
  selectedFareId,
  onFareClick,
  onAlgomerchClick,
  departureDate,
  returnDate,
  airports,
  openMobileFlightDetailsModal,
  setOpenMobileFlightDetailsModal,
  noticeBannerContent,
  ctaTitles,
  // airEntryProperties,
  outgoingFareRating,
  selectedOutboundFare,
  isThebesHackerFaresV2Cap1ExperimentAvailable,
  isMobile,
}: IFlightDetailsProps) => {
  const clientContext = useContext(ClientContext);
  const { isAgentPortal } = clientContext;

  const [isMixedCabinClass, setIsMixedCabinClass] = useState(false);
  const [fareDetails, setFareDetails] = useState<FareDetails | undefined>(
    undefined
  );
  const { matchesMobile } = useDeviceTypes();
  const [clickedFareId, setClickedFareId] = useState("");

  useEffect(() => {
    if (selectedFareId || clickedFareId) {
      setFareDetails(
        tripDetails.fareDetails.find(
          (f) => f.id === selectedFareId || f.id === clickedFareId
        )
      );
    }
  }, [selectedFareId, clickedFareId]);

  useEffect(() => {
    if (fareDetails) {
      isOutgoing
        ? setIsMixedCabinClass(getIsMixedClass(fareDetails.slices[0]))
        : setIsMixedCabinClass(getIsMixedClass(fareDetails.slices[1]));
    }
  }, [fareDetails]);

  if (!tripDetails) return null;

  const renderHeader = (header: string) => {
    const [fromHeader, dateHeader] = header.split(":");
    return (
      <Typography variant="body1" className="flight-details-header">
        <span className="from">{fromHeader}</span>
        <span className="date">{dateHeader}</span>
        {isMixedCabinClass && <MixedCabinToolTip />}
      </Typography>
    );
  };

  const firstSliceIndex = getSliceIndex(true, tripDetails);
  const secondSliceIndex = getSliceIndex(false, tripDetails);
  const outboundHeader =
    departureDate &&
    getReviewCardHeader(
      true,
      airports[tripDetails.slices[firstSliceIndex].destinationCode]
        ? airports[tripDetails.slices[firstSliceIndex].destinationCode].cityName
        : tripDetails.slices[firstSliceIndex].destinationName,
      dayjs(
        removeTimezone(tripDetails.slices[firstSliceIndex].departureTime)
      ).toDate(),
      true
    );

  const returnHeader =
    returnDate &&
    getReviewCardHeader(
      false,
      airports[tripDetails.slices[secondSliceIndex].destinationCode]
        ? airports[tripDetails.slices[secondSliceIndex].destinationCode]
            .cityName
        : tripDetails.slices[secondSliceIndex].destinationName,
      dayjs(
        removeTimezone(tripDetails.slices[secondSliceIndex].departureTime)
      ).toDate(),
      true
    );

  if (
    !isOutgoing &&
    selectedOutboundFare?.multiTicketType === MultiTicketTypeEnum.HackerFare &&
    outgoingFareRating !== undefined &&
    isThebesHackerFaresV2Cap1ExperimentAvailable
  ) {
    // we filter the fares from refundable fare, to see if how many do we get
    const withoutRefundableFares = tripDetails.fareDetails.filter(
      (fare) => !fare.id.includes("refundable-fare")
    );
    // if we have only one fare we do not filter because this could filter the only fare
    if (withoutRefundableFares.length > 1) {
      const filteredFare = tripDetails.fareDetails.filter((fare) => {
        const outboundSlice = fare.slices[0];
        // check if the fare is hackerfare if its single added to the array of fares
        return (
          outboundSlice.fareShelf?.rating === outgoingFareRating ||
          fare.multiTicketType === "single"
        );
      });
      tripDetails.fareDetails = filteredFare;
    }
  }

  const handleMobileFareClick = (fareId: string) => {
    setClickedFareId(fareId);
    setOpenMobileFlightDetailsModal && setOpenMobileFlightDetailsModal(true);
  };

  const getFareClickHandler =
    (onClick: (fareId: string) => void) => (fareId: string) => {
      if (matchesMobile) {
        handleMobileFareClick(fareId);
      } else {
        onClick(fareId);
      }
    };

  const handleMobileModalContinue = useCallback(() => {
    onFareClick(clickedFareId);
  }, [clickedFareId, onFareClick]);

  const isFlightMultiTicketType = tripDetails?.fareDetails?.[0]?.slices[
    isOutgoing ? 0 : 1
  ]?.fareDetails?.segments.some((s) => s.isSelfTransferLayover);

  const hackerFareNotice = tripDetails.fareDetails.map((fare) => {
    if (fare.multiTicket && !isFlightMultiTicketType) {
      return {
        id: fare.id,
        message: COMBINATION_FLIGHT_WARNING,
        tooltipCopy: COMBINATION_FLIGHT_TOOLTIP,
      };
    } else {
      return null;
    }
  });

  const { policies } = useContext(ClientContext);
  const showPolicyBanner = useShowPolicyBanner(policies);

  return (
    <>
      <FlightDetailsCard
        className={clsx("b2b", {
          "with-banner": !!noticeBannerContent,
        })}
        isAgentPortal={isAgentPortal}
        selectedFareId={selectedFareId}
        getEmptyRestrictionsText={getEmptyRestrictionsText}
        onFareClick={getFareClickHandler(onFareClick)}
        onAlgomerchClick={onAlgomerchClick}
        header={
          isOutgoing
            ? outboundHeader
              ? renderHeader(outboundHeader)
              : undefined
            : returnHeader
            ? renderHeader(returnHeader)
            : undefined
        }
        isMixedCabinClass={isMixedCabinClass}
        ctaTitles={ctaTitles}
        isFlightListOptimizationExperiment={true}
        showAirCXV4UI={true}
        tripDetails={tripDetails}
        isOutgoing={isOutgoing}
        isMobile={isMobile}
        showFaresAsDiffs
        fareNotice={hackerFareNotice}
        sortFares="packagePricing"
        hideIsInPolicy={!showPolicyBanner}
      />
      {matchesMobile && (
        <MobileFlightDetailsModal
          openModal={openMobileFlightDetailsModal ?? false}
          isDeparture={isOutgoing}
          tripDetails={tripDetails}
          fareDetails={tripDetails.fareDetails.find(
            (f) => f.id === clickedFareId
          )}
          onClose={() => {
            setOpenMobileFlightDetailsModal &&
              setOpenMobileFlightDetailsModal(false);
          }}
          onClick={handleMobileModalContinue}
        />
      )}
    </>
  );
};
