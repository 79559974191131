import { createSelector } from "@reduxjs/toolkit";
import { IStoreState } from "../../../../reducers/types";
import {
  RoomInfoProducts,
  CorpRoomInfoProducts,
  RoomProduct,
  CorpRoomProduct,
  ITrackingProperties,
  SearchDistanceFromShopProperties,
  getLodgingTrackingProperties,
  Place,
  ViewedHotelDetailsProperties,
} from "redmond";
import { PackageHotelShopCallState } from "../state";
import { getSelectedAccount } from "../../../rewards/reducer";
import queryStringParser from "query-string";
import { getPackagesViewedHotelListProperties } from "../../../availability/reducer";
import { getTravelWalletCredit } from "../../../travel-wallet/reducer";

export const getPackageSelectedLodging = (state: IStoreState) =>
  state.packageHotelShop.selectedLodging;

export const getHotelShopDateRange = (state: IStoreState) =>
  state.packageHotelShop.dateRange;

export const getSelectedPackageByLodgingId = (state: IStoreState) =>
  state.packageHotelShop.selectedPackageByLodgingId;

export const getHotelShopCallState = (state: IStoreState) =>
  state.packageHotelShop.hotelShopCallState;

export const getPackageLodgingRoomInfoProducts = (state: IStoreState) =>
  state.packageHotelShop.roomInfoProducts;

export const getCheapestLodgingRoomProduct = createSelector(
  getPackageLodgingRoomInfoProducts,
  (roomInfoProducts) => roomInfoProducts[0]?.products[0]
);

export const getPackageRatesById = (state: IStoreState) =>
  state.packageHotelShop.packagesByRateId;

export const getHotelShopChosenRoomInfoIndex = (state: IStoreState) =>
  state.packageHotelShop.chosenRoomInfoIndex;

export const getHotelShopChosenProductIndex = (state: IStoreState) =>
  state.packageHotelShop.chosenProductIndex;

export const getHotelShopChosenRoomInfo = createSelector(
  [getPackageLodgingRoomInfoProducts, getHotelShopChosenRoomInfoIndex],
  (
    roomInfoProducts,
    chosenRoomInfoIndex
  ): RoomInfoProducts | CorpRoomInfoProducts | null => {
    if (chosenRoomInfoIndex !== null) {
      return roomInfoProducts[chosenRoomInfoIndex] ?? null;
    }

    return null;
  }
);

export const getHotelShopChosenProduct = createSelector(
  [
    getPackageLodgingRoomInfoProducts,
    getHotelShopChosenRoomInfoIndex,
    getHotelShopChosenProductIndex,
  ],
  (
    roomInfoProducts,
    chosenRoomInfoIndex,
    chosenProductIndex
  ): RoomProduct | CorpRoomProduct | null => {
    if (chosenRoomInfoIndex !== null && chosenProductIndex !== null) {
      return (
        roomInfoProducts[chosenRoomInfoIndex]?.products[chosenProductIndex] ??
        null
      );
    }

    return null;
  }
);

export const getHotelShopSelectedLodging = (state: IStoreState) =>
  state.packageHotelShop.selectedLodging;

export const getHotelShopRoomInfoProducts = (state: IStoreState) =>
  state.packageHotelShop.roomInfoProducts;

export const getViewedHotelDetailsTrackingProps = createSelector(
  getHotelShopChosenProduct,
  getHotelShopChosenRoomInfo,
  (product, roomInfo): ITrackingProperties | null => {
    return {
      properties: {
        ...product?.trackingPropertiesV2?.properties,
        ...roomInfo?.trackingPropertiesV2?.properties,
      },
      encryptedProperties: [
        product?.trackingPropertiesV2?.encryptedProperties ?? "",
        roomInfo?.trackingPropertiesV2?.encryptedProperties ?? "",
      ],
    };
  }
);

export const getViewedHotelDetailsProperties = createSelector(
  getPackagesViewedHotelListProperties,
  getSelectedAccount,
  getHotelShopSelectedLodging,
  getViewedHotelDetailsTrackingProps,
  getHotelShopRoomInfoProducts,
  (
    viewedHotelListProperties,
    account,
    availability,
    trackingProps,
    roomInfoProducts
  ): ITrackingProperties<ViewedHotelDetailsProperties> => {
    const {
      lodgingSelection,
      selectedLodgingIndex,
    }: { lodgingSelection?: string; selectedLodgingIndex?: number } =
      queryStringParser.parse(location.search);

    let parsedLodgingSelection: Place | undefined;

    try {
      if (lodgingSelection) {
        parsedLodgingSelection = JSON.parse(
          decodeURIComponent(lodgingSelection)
        );
      }
    } catch (error) {
      console.error(error);
    }

    return {
      properties: {
        ...getLodgingTrackingProperties(availability).properties,
        ...viewedHotelListProperties.properties,
        account_type_selected: account?.productDisplayName || "",
        rooms_shown: roomInfoProducts.length || 0,
        account_use_type: account?.accountUseType,
        account_allow_rewards_redemption: account?.allowRewardsRedemption,
        room_media_count: availability?.lodging.media.length || 0,
        google_place_id: parsedLodgingSelection?.placeId,
        lodging_row_index: selectedLodgingIndex
          ? Number(selectedLodgingIndex)
          : undefined,
        ...trackingProps?.properties,
      },
      encryptedProperties: [
        ...getLodgingTrackingProperties(availability).encryptedProperties,
        ...viewedHotelListProperties.encryptedProperties,
        availability?.trackingPropertiesV2?.encryptedProperties ?? "",
        availability?.bestOfferThisLodging?.trackingPropertiesV2
          ?.encryptedProperties ?? "",
        ...(trackingProps?.encryptedProperties ?? []),
      ],
    };
  }
);

export const getDistanceFromLocation = (state: IStoreState) =>
  state.packageHotelShop.distanceFromLocation;

export const getDistanceFromLocationCategories = (state: IStoreState) =>
  state.packageHotelShop.distanceFromLocationCategories;

export const getDistanceFromLocationCategoriesLoading = (state: IStoreState) =>
  state.packageHotelShop.distanceFromLocationCategoriesLoading;

export const getSearchDistanceFromShopProperties = createSelector(
  getViewedHotelDetailsProperties,
  getDistanceFromLocation,
  (
    viewedHotelDetailsProperties,
    distanceFromLocation
  ): ITrackingProperties<SearchDistanceFromShopProperties> => {
    return {
      properties: {
        point_of_interest: distanceFromLocation?.label || "",
        ...viewedHotelDetailsProperties.properties,
      },
      encryptedProperties: [
        ...viewedHotelDetailsProperties.encryptedProperties,
      ],
    };
  }
);

export const isMissingProductInfo = createSelector(
  getHotelShopCallState,
  getPackageLodgingRoomInfoProducts,
  (hotelShopCallState, roomInfoProducts) => {
    return (
      hotelShopCallState === PackageHotelShopCallState.Success &&
      roomInfoProducts.length === 0
    );
  }
);

export const hasHotelShopFailed = createSelector(
  getHotelShopCallState,
  isMissingProductInfo,
  (hotelShopCallState, missingProductInfo) => {
    return (
      hotelShopCallState === PackageHotelShopCallState.Failed ||
      missingProductInfo
    );
  }
);

export const hotelShopProgressSelector = (state: IStoreState) =>
  state.packageHotelShop.progress;

export const getHotelShopCancellationSummary = (state: IStoreState) =>
  state.packageHotelShop.cancellationSummary;

export const getHotelShopCallError = (state: IStoreState) =>
  state.packageHotelShop.hotelShopCallError;

export const selectedPackageByRateId = createSelector(
  getPackageRatesById,
  getHotelShopChosenProduct,
  (packagesByRateId, chosenProduct) => {
    const rateId = chosenProduct?.rateId?.value;

    if (rateId && packagesByRateId[rateId]) {
      return packagesByRateId[rateId];
    }
    return undefined;
  }
);

export const showOfferBasedOnSelectedLodgingSelector = createSelector(
  getHotelShopSelectedLodging,
  getTravelWalletCredit,
  (selectedLodging, credit): boolean => {
    return (
      !!selectedLodging?.bestOfferThisLodging &&
      ((!!credit &&
        Math.abs(credit.amount.amount) ===
          Math.abs(selectedLodging.bestOfferThisLodging.amount.amount)) ||
        !!selectedLodging.bestOfferThisLodging.availabilityPageBanner)
    );
  }
);
