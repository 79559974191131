import React from "react";
import { Box, Typography } from "@material-ui/core";
import { RouteComponentProps } from "react-router-dom";
import { PackagesConfirmationConnectorProps } from "./container";
import "./styles.scss";
import {
  FlightSummaryPanel,
  HotelSummaryPanel,
  Icon,
  IconName,
  InfoCard,
  useDeviceTypes,
} from "halifax";
import {
  PACKAGE_CONFIRMATION_CODE_TEXT,
  PACKAGE_CONFIRMATION_EARN,
  PACKAGE_CONFIRMATION_HEADING,
  WHATS_NEXT_CARS_DESCRIPTION,
  WHATS_NEXT_CARS_TITLE,
  WHATS_NEXT_HEADING,
} from "./textConstants";
import {
  useCheckoutStateSelector,
  ContactSelectors,
  cartFulfillSelectors,
  FlightSelectors,
} from "@capone/checkout";
import { Product } from "@b2bportal/purchase-api";
import { LodgingSelectors } from "../../state/selectors";
import { Lodging, RoomInfo } from "redmond";
import { getSliceDetails, postBookingSurveyPageId } from "./utils";
import {
  AVAILABLE,
  getExperimentVariant,
  TREES_MODAL_EXPERIMENT,
  useExperiments,
} from "../../../../context/experiments";
import { PackageTreesCard } from "../";
import clsx from "clsx";
import { PATH_HOME_SEARCH } from "../../../../utils/paths";

type FulfilledPackageProductValue =
  | {
      customerReservationId: string;
    }
  | undefined;

export interface IPackagesConfirmationProps
  extends RouteComponentProps,
    PackagesConfirmationConnectorProps {}

export const PackageConfirmation = ({
  history,
}: IPackagesConfirmationProps) => {
  const { matchesMobile } = useDeviceTypes();

  const expState = useExperiments();
  const treesModalExperiment = getExperimentVariant(
    expState.experiments,
    TREES_MODAL_EXPERIMENT
  );
  const isTreesExperiment = treesModalExperiment === AVAILABLE;

  const earnedRewards = useCheckoutStateSelector(
    cartFulfillSelectors.getFulfillEarnedRewards
  );
  const emailAddress = useCheckoutStateSelector(
    ContactSelectors.getEmailAddress
  );
  const fulfilledProducts = useCheckoutStateSelector(
    cartFulfillSelectors.getFulfilledProducts
  );

  const selectedLodging = useCheckoutStateSelector(
    LodgingSelectors.getSelectedLodging
  );
  const fromDate = useCheckoutStateSelector(LodgingSelectors.getHotelFromDate);
  const untilDate = useCheckoutStateSelector(
    LodgingSelectors.getHotelUntilDate
  );
  const roomInfo = useCheckoutStateSelector(
    LodgingSelectors.getSelectedRoom
  )?.roomInfo;

  const tripDetails = useCheckoutStateSelector(FlightSelectors.getTripDetails);
  const airports = useCheckoutStateSelector(FlightSelectors.getAirportMap);

  if (!tripDetails.slices) {
    history.replace(PATH_HOME_SEARCH);
  }

  const fulfilledPackageProduct = fulfilledProducts?.find(
    (product) => product.type === Product.Package
  );
  const confirmationCode = (
    fulfilledPackageProduct?.value as FulfilledPackageProductValue
  )?.customerReservationId;

  const outgoingSliceDetails = React.useMemo(
    () => getSliceDetails(true, matchesMobile, airports || {}, tripDetails),
    [matchesMobile, airports, tripDetails]
  );

  const returnSliceDetails = React.useMemo(
    () => getSliceDetails(false, matchesMobile, airports || {}, tripDetails),
    [matchesMobile, airports, tripDetails]
  );

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box
      className={clsx("package-confirmation-root", { mobile: matchesMobile })}
    >
      <Box className="package-confirmation-header">
        <Box className="check-mark-icon">
          <Icon name={IconName.ConfirmationCheckMark} aria-hidden />
        </Box>
        <Typography variant="h4" className="package-confirmation-heading-text">
          {PACKAGE_CONFIRMATION_HEADING}
        </Typography>
      </Box>
      <Box className="package-confirmation-earn-info-container">
        <Typography className="earn-string">
          {PACKAGE_CONFIRMATION_EARN(earnedRewards, emailAddress)}
        </Typography>
        <Typography className="confirmation-code">
          {PACKAGE_CONFIRMATION_CODE_TEXT(confirmationCode || "")}
        </Typography>
      </Box>

      {selectedLodging && fromDate && untilDate ? (
        <Box className="hotel-details">
          <HotelSummaryPanel
            selectedLodging={selectedLodging as Lodging}
            checkIn={fromDate}
            checkOut={untilDate}
            isMobile={matchesMobile}
            roomInfo={roomInfo as RoomInfo}
            showRoomCapacityInfo
          />
        </Box>
      ) : null}

      <Box className="package-flight-details">
        {outgoingSliceDetails ? (
          <FlightSummaryPanel
            {...outgoingSliceDetails}
            isMobile={matchesMobile}
            className="first-slice"
          />
        ) : null}

        {returnSliceDetails ? (
          <FlightSummaryPanel
            {...returnSliceDetails}
            isMobile={matchesMobile}
            className="last-slice"
          />
        ) : null}
      </Box>

      <div id={postBookingSurveyPageId()} />

      {isTreesExperiment ? <PackageTreesCard isConfirmationScreen /> : null}

      <Box className="what-is-next-section">
        <Typography variant="h6" className="next-header">
          {WHATS_NEXT_HEADING}
        </Typography>
        <Box className="info-cards">
          <InfoCard
            title={WHATS_NEXT_CARS_TITLE}
            subtitle={WHATS_NEXT_CARS_DESCRIPTION}
            onClick={() => {
              history.push("/cars");
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};
