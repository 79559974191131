import React from "react";
import { Airport, ITripTerminus } from "redmond";
import { getCityNameAndAirportCodeFromTerminus } from "../../../../utils/terminus";

export const getChooseFlightHeaderText = (
  isDeparture: boolean,
  location: string
) => {
  // TODO: we should get the location without state/airport code from BE since we cannot guarantee split method to work 100%
  const getShortLabel = (label: string) => {
    return label.split(",")[0];
  };
  return `<strong>Choose ${
    isDeparture ? "outbound" : "return"
  } </strong>flight to ${getShortLabel(location)}`;
};

export const FLIGHT_LIST_TITLE = (
  originAirport?: Airport,
  destinationAirport?: Airport,
  origin?: ITripTerminus,
  destination?: ITripTerminus,
  isMultiAirport?: boolean
) => {
  let originCity = originAirport?.cityName || origin?.label;
  // This might go to an actual airport but that is not enforced
  let destinationCity = destinationAirport?.cityName || destination?.label;
  let originAirportCode = originAirport?.code;
  let destinationAirportCode = destinationAirport?.code;

  if (origin && destination) {
    let orgCityName: string | undefined;
    let destCityName: string | undefined;
    let orgAirportCode: string | undefined;
    let destAirportCode: string | undefined;

    if (isMultiAirport) {
      [orgCityName, orgAirportCode] =
        getCityNameAndAirportCodeFromTerminus(origin);
      [destCityName, destAirportCode] =
        getCityNameAndAirportCodeFromTerminus(destination);
      originAirportCode = orgAirportCode?.slice(1, -1);
      destinationAirportCode = destAirportCode?.slice(1, -1);
    } else {
      [orgCityName] = getCityNameAndAirportCodeFromTerminus(origin);
      [destCityName] = getCityNameAndAirportCodeFromTerminus(destination);
    }
    originCity = orgCityName;
    destinationCity = destCityName;
  }

  return (
    <>
      <strong>{`${originCity} `}</strong>
      {originAirportCode && ` (${originAirportCode}) `}
      <strong>to {`${destinationCity} `}</strong>
      {destinationAirportCode && ` (${destinationAirportCode}) `}
    </>
  );
};

export const FLIGHT_LIST_SUBTITLE = `Prices are round-trip, per traveler`;
