import React, { ReactElement, useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import {
  DesktopPopupModal,
  Icon,
  IconName,
  B2BButton,
  NotificationBanner,
  BannerSeverity,
  noop,
  PassengerCountPickerType,
  PassengerCountPicker,
} from "halifax";
import {
  TripCategory,
  SliceStopCountFilter,
  IIdLodgings,
  RecentPackageSearch,
  PackagesEntryTypeEnum,
} from "redmond";
import "./styles.scss";
import { RouteComponentProps } from "react-router";
import clsx from "clsx";
import {
  CalendarPickerButton,
  FareclassOptionSelection,
  PackagesSearchButton,
} from "./components";
import * as textConstants from "./textConstants";
import { PackagesSearchControlConnectorProps } from "./container";
import * as constants from "./constants";
import { OriginDestinationSearch } from "./components/OriginDestinationSearch";

import { NonStopToggle } from "./components/NonstopToggle";
import { config } from "../../../../api/config";
import { PATH_HOTELS_AVAILABILITY } from "../../../../utils/paths";
import { transformToStringifiedAvailabilityQuery } from "../../../availability/utils/queryStringHelpers";
import {
  AVAILABLE,
  getExperimentVariant,
  PACKAGES_FORCED_PAX,
  useExperiments,
} from "../../../../context/experiments";
import { parseQueryString } from "../../utils/parseQueryString";
import { initialFareclassOptionFilter } from "../../../availability/reducer";

export interface IPackagesSearchControlProps
  extends PackagesSearchControlConnectorProps,
    RouteComponentProps {
  showSearchButton?: boolean;
}

export const PackagesSearchControl = ({
  origin,
  setOrigin,
  destination,
  setDestination,
  departureDate,
  setDepartureDate,
  returnDate,
  setReturnDate,
  fareclassOptionFilter,
  setFareclassOptionFilter,
  hasSetFareclassFilter,
  stopsOption,
  setStopsOption,
  travelers,
  setTravelers,
  history,
  showSearchButton = true,
}: IPackagesSearchControlProps): ReactElement => {
  const [openPassengerCountPicker, setOpenPassengerCountPicker] =
    useState(false);
  const [hasMissingSearchInfoError, setHasMissingSearchInfoError] =
    useState(false);
  const [recentSearches, setRecentSearches] = useState<RecentPackageSearch[]>(
    []
  );

  const onRecentSearchClick = (recentSearch: RecentPackageSearch) => {
    const recentSearchInfants = [
      ...recentSearch.infantsInSeatAges.map((age) => ({ age, inSeat: true })),
      ...recentSearch.infantsInLapAges.map((age) => ({ age, inSeat: false })),
    ];

    const recentSearchFareClassOptionFilter =
      recentSearch.fareClass?.reduce(
        (acc, fareClass) => ({ ...acc, [fareClass]: true }),
        initialFareclassOptionFilter
      ) || initialFareclassOptionFilter;

    history.push(
      `${PATH_HOTELS_AVAILABILITY}${transformToStringifiedAvailabilityQuery(
        recentSearch.originLocation,
        recentSearch.destinationLocationLabel,
        recentSearch.destinationLocation,
        recentSearch.departureDate,
        recentSearch.returnDate,
        recentSearch.numAdults,
        recentSearch.childrenAges,
        recentSearchInfants,
        SliceStopCountFilter.ANY_NUMBER,
        recentSearchFareClassOptionFilter,
        PackagesEntryTypeEnum.RECENTLY_SEARCHED_PACKAGE
      )}`
    );
  };
  const expState = useExperiments();

  const isForcedPAXExperiment =
    getExperimentVariant(expState.experiments, PACKAGES_FORCED_PAX) ===
    AVAILABLE;

  useEffect(() => {
    if (
      !!destination &&
      !!origin &&
      !!departureDate &&
      !!returnDate &&
      !!travelers.adultsCount
    ) {
      setHasMissingSearchInfoError(false);
    }
  }, [destination, origin, departureDate, returnDate, travelers.adultsCount]);

  const parsedQueryString = parseQueryString(history);

  useEffect(() => {
    if (!isForcedPAXExperiment && !parsedQueryString?.entryPoint) {
      setTravelers({ adultsCount: 2, children: [], infants: [] });
    }
  }, [isForcedPAXExperiment]);

  useEffect(() => {
    const getRecentSearches = () => {
      try {
        const localStorageItem = localStorage.getItem(
          "recently_searched_packages"
        );

        if (localStorageItem) {
          const parsedSearches: RecentPackageSearch[] =
            JSON.parse(localStorageItem);
          const sortedSearches = parsedSearches
            .filter(
              (search) =>
                !!search.originLocation && !!search.destinationLocation
            )
            .sort(
              (a, b) =>
                new Date(b.searchDate).getTime() -
                new Date(a.searchDate).getTime()
            );
          setRecentSearches(sortedSearches);
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(`Failed to get recent searches from local storage: ${e}`);
      }
    };
    getRecentSearches();
    // eslint-disable-next-line no-restricted-globals
    addEventListener("update_recently_searched_packages", getRecentSearches);
    return () =>
      // eslint-disable-next-line no-restricted-globals
      removeEventListener(
        "update_recently_searched_packages",
        getRecentSearches
      );
  }, []);

  const handleTravelersChanged = (counts: PassengerCountPickerType) => {
    setOpenPassengerCountPicker(false);
    if ("adultsCount" in counts) {
      setTravelers({
        adultsCount: counts.adultsCount,
        children: counts.childrenAges || [],
        infants: counts.infants || [],
      });
    }
  };

  const incrementPackagesFunnelSearchesCounter = () => {
    const PACKAGES_FUNNEL_SEARCHES = "packages-funnel-searches";
    const searches = parseInt(
      sessionStorage.getItem(PACKAGES_FUNNEL_SEARCHES) || "0",
      10
    );
    sessionStorage.setItem(PACKAGES_FUNNEL_SEARCHES, String(searches + 1));
  };

  const handleSearchClick = () => {
    if (
      !!destination &&
      !!origin &&
      !!departureDate &&
      !!returnDate &&
      !!travelers.adultsCount
    ) {
      incrementPackagesFunnelSearchesCounter();

      history.push(
        `${PATH_HOTELS_AVAILABILITY}${transformToStringifiedAvailabilityQuery(
          origin?.id?.code?.code,
          (destination?.id as IIdLodgings).lodgingSelection.searchTerm,
          (destination?.id as IIdLodgings).lodgingSelection.placeId,
          departureDate,
          returnDate,
          travelers.adultsCount,
          travelers.children,
          travelers.infants,
          stopsOption,
          fareclassOptionFilter
        )}`
      );
    } else {
      setHasMissingSearchInfoError(true);
    }
  };

  const renderTripTypeAndTravelerPickers = () => {
    const numTravelers =
      travelers.adultsCount +
      travelers.children.length +
      travelers.infants.length;
    const numTravelerString = numTravelers
      ? `${numTravelers} traveler${numTravelers === 1 ? "" : "s"}`
      : "Travelers";

    return (
      <Box className={clsx("traveler-pickers-container")}>
        <Box className="traveler-pickers">
          <B2BButton
            aria-label={numTravelerString}
            className={clsx("num-travelers-input b2b", {
              "has-error": !numTravelers && hasMissingSearchInfoError,
            })}
            variant="traveler-selector"
            onClick={() => setOpenPassengerCountPicker(true)}
          >
            <Box className="num-traveler-content">
              <Icon
                aria-hidden
                className="icon-start"
                name={IconName.B2BUser}
                ariaLabel=""
              />
              <Box className="text">{numTravelerString}</Box>
              <Icon
                aria-hidden
                className="icon-end"
                name={IconName.Dropdown}
                ariaLabel=""
              />
            </Box>
          </B2BButton>
        </Box>
        <DesktopPopupModal
          open={openPassengerCountPicker}
          className="packages-desktop-passenger-count-picker-popup"
          contentClassName="desktop-passenger-count-picker-popup-container"
          onClose={() => setOpenPassengerCountPicker(false)}
          invisibleBackdrop={false}
          headerElement={textConstants.EDIT_TRAVELERS_TITLE}
        >
          <PassengerCountPicker
            counts={{
              adultsCount: travelers.adultsCount,
              children: travelers.children,
              childrenCount: travelers.children.length,
              infants: travelers.infants,
              infantsInSeatCount: travelers.infants.filter(
                ({ inSeat }) => inSeat
              ).length,
              infantsOnLapCount: travelers.infants.filter(
                ({ inSeat }) => !inSeat
              ).length,
            }}
            minimumAdultsCount={1}
            onClickApply={handleTravelersChanged}
            className="b2b"
            includeChildrenInMaxCount
            setPassengerCounts={noop}
            showChildAgeInputs
            showInfantAgeInputs
            titles={textConstants.TRAVELERS_SELECT_TITLES}
          />
        </DesktopPopupModal>

        <FareclassOptionSelection
          fareclassOptionFilter={fareclassOptionFilter}
          setFareclassOptionFilter={setFareclassOptionFilter}
          hasSetFareclassFilter={hasSetFareclassFilter}
          appliedLabel={Object.keys(fareclassOptionFilter).find(
            (key) => fareclassOptionFilter[key]
          )}
          labelIcon={IconName.FareIconFilled}
          includeClearFilter={false}
          popoverClassName="packages-search-fare-class-filter-popover"
        />

        <NonStopToggle
          checked={stopsOption === SliceStopCountFilter.NONE}
          onClick={(checked) => {
            setStopsOption(
              checked
                ? SliceStopCountFilter.NONE
                : SliceStopCountFilter.ANY_NUMBER
            );
          }}
        />
      </Box>
    );
  };

  const renderPackagesSearchRow = () => (
    <Box className={clsx("packages-search-row")}>
      <OriginDestinationSearch
        origin={origin}
        setOrigin={setOrigin}
        destination={destination}
        setDestination={setDestination}
        hasMissingSearchInfoError={hasMissingSearchInfoError}
        recentSearches={recentSearches}
        onRecentSearchClick={onRecentSearchClick}
      />
      <CalendarPickerButton
        classes={["date-pickers"]}
        saveDatesOnClose
        tripCategory={TripCategory.ROUND_TRIP}
        departureDate={departureDate}
        returnDate={returnDate}
        setDepartureDate={setDepartureDate}
        setReturnDate={setReturnDate}
        hasMissingSearchInfoError={
          hasMissingSearchInfoError && !(departureDate && returnDate)
        }
      />
      {showSearchButton && (
        <PackagesSearchButton
          className="packages-search-button b2b"
          message={constants.SEARCH}
          onClick={handleSearchClick}
          enabled
        />
      )}
    </Box>
  );

  const renderErrorBanner = () => {
    if (!hasMissingSearchInfoError) return undefined;
    return (
      <Box className="missing-info-search-error-container">
        <NotificationBanner
          className="missing-info-search-error-banner"
          label={constants.MISSING_INFO_SEARCH_ERROR}
          severity={BannerSeverity.ERROR}
          icon={<Icon name={IconName.WarningAlert} />}
        />
      </Box>
    );
  };

  return (
    <Box className={clsx("packages-search", config.TENANT)}>
      {renderTripTypeAndTravelerPickers()}
      {renderPackagesSearchRow()}
      {renderErrorBanner()}
    </Box>
  );
};
