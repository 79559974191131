import { put, putResolve, select, call } from "redux-saga/effects";
import { actions } from "../actions";
import Logger from "../../../utils/logger";
import {
  PackageHotelShopCallError,
  PackageHotelShopCallState,
} from "../reducer/state";
import {
  AirportRegion,
  DestinationEnum,
  ShopFilter,
  ILocationQueryLabel,
  IResponse,
  ITripTerminus,
  LocationQueryEnum,
  Lodging,
  PackagedLodging,
  PackageLodging,
  Platform,
  SearchPackagesRequest,
  SearchPackagesRequestEnum,
  SearchPackagesResponse,
  SearchPackagesResponseEnum,
  SearchPackagesResponseInitial,
  ShopRoomsRequest,
  ShopRoomsResponse,
  SliceStopCountFilter,
  IResult,
  IIdLodgings,
  PackageShopResponseEnum,
  LodgingTokenResponse,
  LodgingCollectionEnum,
  LodgingTokenRequest,
  PlatformEnum,
} from "redmond";
import { fetchPackageHotelShop } from "../../../api/v0/hotel-shop/fetchPackageHotelShop";
import { IStoreState } from "../../../reducers/types";
import {
  getDepartureDate,
  getDestination,
  getOrigin,
  getReturnDate,
  getStopsOption,
  getTravelers,
} from "../../search/reducer";
import {
  getPackageSelectedLodging,
  getSelectedPackageByLodgingId,
} from "../reducer";
import dayjs from "dayjs";
import { isMobile } from "../../../utils/userAgent";
import { fetchPackagesAvailability } from "../../../api/v0/availability/fetchPackagesAvailability";
import queryStringParser from "query-string";
import { actions as searchActions } from "../../search/actions";
import { actions as availabilityActions } from "../../availability/actions";
import { IPackageHotelShopParsedQuery } from "../utils/queryStringHelpers";
import { fetchFlightAutocomplete } from "../../../api/v0/search/fetchFlightAutocomplete";
import { IPackagesAvailabilityParsedQuery } from "../../availability/sagas/fetchPackagesAvailabilitySaga";
import { ITripTerminusCategory } from "../../search/types";
import { fetchHotelAutocomplete } from "../../../api/v0/search/fetchHotelAutocomplete";
import {
  getSelectedFareClass,
  getSelectedFareClasses,
} from "../../../utils/fareClass";
import { fetchCacheLodgingToken } from "../../../api/v0/hotel-shop/fetchCacheLodgingToken";
import { localCache } from "@capone/common";

const DEFAULT_CHECKIN_DATE_OFFSET_IN_DAYS_FROM_TODAY = 1;
const DEFAULT_CHECKOUT_DATE_OFFSET_IN_DAYS_FROM_CHECKIN = 2;

export default function* fetchPackageHotelShopSaga(
  fetchPackageHotelShopAction: actions.IFetchPackageHotelShop
) {
  try {
    const {
      selectedPackageByLodgingId,
      selectedAvailability,
      shopToken,
    }: {
      selectedAvailability?: Lodging;
      selectedPackageByLodgingId?: PackagedLodging;
      shopToken?: string;
    } = yield call(
      setUpPackageHotelShopParameters,
      fetchPackageHotelShopAction
    );
    const shopRequestId = selectedPackageByLodgingId?.opaqueShopRequest;
    const shopPackageId =
      selectedPackageByLodgingId?.packageDetails.opaquePackageToken;

    if (!selectedAvailability?.available) {
      yield put(
        actions.setPackageHotelShopCallStateFailed({
          hotelShopCallError: PackageHotelShopCallError.NoAvailability,
        })
      );
      return;
    }

    if ((!shopRequestId && !shopToken) || !shopPackageId) {
      throw new Error("Shop Request Id must be present.");
    }

    const requestBody: ShopRoomsRequest = {
      opaquePackageToken: shopPackageId,
      opaqueShopRequest: shopToken ? shopToken : shopRequestId!,
    };
    const response: ShopRoomsResponse = yield fetchPackageHotelShop(
      requestBody
    );
    yield putResolve(
      actions.setPackageHotelShopResults({
        hotelShopCallState: PackageHotelShopCallState.Success,
        payload: response,
      })
    );
  } catch (e) {
    Logger.debug(e);
    yield put(
      actions.setPackageHotelShopCallStateFailed({
        hotelShopCallError: PackageHotelShopCallError.Unknown,
      })
    );
  }
}
function parseQueryString(
  fetchPackageHotelShopAction: actions.IFetchPackageHotelShop,
  now: dayjs.Dayjs
) {
  const queryString = fetchPackageHotelShopAction.history.location.search;
  const {
    lodgingId,
    origin,
    destination,
    fromDate,
    untilDate,
    adultsCount,
    children,
    infants,
    stopsOption,
    fareClass,
    searchToken,
    placeId,
    shopToken,
  } = queryStringParser.parse(queryString);

  const childrenArray = Array.isArray(children)
    ? children.map((age) => parseInt(age, 10))
    : children
    ? [parseInt(children, 10)]
    : [];
  const infantsArray = Array.isArray(infants)
    ? infants.map((infant) => JSON.parse(decodeURIComponent(infant)))
    : infants
    ? [JSON.parse(decodeURIComponent(infants))]
    : [];

  const parsedQueryString: IPackageHotelShopParsedQuery = {
    lodgingId: lodgingId as string,
    origin: origin as string,
    destination: destination as string,
    fromDate: dayjs(fromDate as string).toDate(),
    untilDate: dayjs(untilDate as string).toDate(),
    adultsCount: parseInt(adultsCount as string),
    children: childrenArray,
    infants: infantsArray as {
      age: number;
      inSeat: boolean;
    }[],
    stopsOption: stopsOption as SliceStopCountFilter,
    fareClassOptionFilter: {
      basic: false,
      premium: false,
      standard: false,
      enhanced: false,
      luxury: false,
      ...(fareClass && { [fareClass as string]: true }),
    },
    searchToken: searchToken as string,
    placeId: placeId as string,
    shopToken: shopToken as string,
  };

  const currentDate = dayjs().toDate();
  currentDate.setHours(0);
  currentDate.setMinutes(0);
  currentDate.setSeconds(0);
  currentDate.setMilliseconds(0);

  const parsedQueryStringFromDate: Date =
    parsedQueryString.fromDate &&
    dayjs(parsedQueryString.fromDate).toDate() >= currentDate
      ? dayjs(parsedQueryString.fromDate).toDate()
      : now
          .add(DEFAULT_CHECKIN_DATE_OFFSET_IN_DAYS_FROM_TODAY, "days")
          .toDate();
  const parsedQueryStringUntilDate: Date =
    parsedQueryString.untilDate &&
    dayjs(parsedQueryString.untilDate).toDate() > parsedQueryStringFromDate
      ? dayjs(parsedQueryString.untilDate).toDate()
      : dayjs(parsedQueryStringFromDate)
          .add(DEFAULT_CHECKOUT_DATE_OFFSET_IN_DAYS_FROM_CHECKIN, "days")
          .toDate();

  return {
    parsedQueryString,
    parsedQueryStringOrigin: parsedQueryString.origin,
    parsedQueryStringDestination: parsedQueryString.destination,
    parsedQueryStringFromDate,
    parsedQueryStringUntilDate,
    parsedQueryStringAdultsCount: parsedQueryString.adultsCount,
    parsedQueryStringChildren: parsedQueryString.children,
    parsedQueryStringInfants: parsedQueryString.infants,
    parsedQueryStopsOptions: parsedQueryString.stopsOption,
    parsedQueryFareClass: parsedQueryString.fareClassOptionFilter,
    parsedQueryStringSearchToken: parsedQueryString.searchToken,
    parsedQueryStringShopToken: parsedQueryString.shopToken,
  };
}

function* setUpPackageHotelShopParameters(
  fetchPackageHotelShopAction: actions.IFetchPackageHotelShop
) {
  const state: IStoreState = yield select();

  let selectedAvailability: Lodging | undefined =
    yield getPackageSelectedLodging(state);
  let selectedPackageByLodgingId: PackagedLodging | undefined =
    yield getSelectedPackageByLodgingId(state);
  let origin: ITripTerminus | null = yield getOrigin(state);
  let destination: IResult | null = yield getDestination(state);
  let fromDate: Date = yield getDepartureDate(state);
  let untilDate: Date = yield getReturnDate(state);
  let lodgingToken: string | undefined;
  const travelers: {
    adultsCount: number;
    children: number[];
    infants: {
      age: number;
      inSeat: boolean;
    }[];
  } = yield getTravelers(state);
  let stopOptions: SliceStopCountFilter = yield getStopsOption(state);
  const { history } = fetchPackageHotelShopAction;
  const now = dayjs();

  let {
    parsedQueryString,
    parsedQueryStringFromDate,
    parsedQueryStringUntilDate,
    parsedQueryStringAdultsCount,
    parsedQueryStringChildren,
    parsedQueryStringInfants,
    parsedQueryStopsOptions,
    parsedQueryFareClass,
    parsedQueryStringSearchToken,
    parsedQueryStringShopToken,
  } = parseQueryString(fetchPackageHotelShopAction, now);

  let originToSearch: ITripTerminus | null = origin;
  let destinationToSearch: IResult | null = destination;

  if (
    !origin ||
    (origin &&
      parsedQueryString.origin &&
      origin.id.code.code !== parsedQueryString.origin)
  ) {
    const { correspondingOrigin } = yield fetchOriginLocation(
      parsedQueryString
    );
    originToSearch = correspondingOrigin;
  }

  if (
    !destination ||
    (destination?.id as IIdLodgings).lodgingSelection.searchTerm !==
      parsedQueryString.destination
  ) {
    const { correspondingLocation } = yield fetchDestinationLocation(
      parsedQueryString
    );
    destinationToSearch = correspondingLocation;
  }

  if (
    !fetchPackageHotelShopAction.options?.overrideStateByQueryParams &&
    // if (
    selectedAvailability &&
    fromDate &&
    untilDate &&
    (parsedQueryString.lodgingId !== selectedAvailability.lodging.id ||
      parsedQueryStringFromDate !== fromDate ||
      parsedQueryStringUntilDate !== untilDate)
  ) {
    const fareClass = getSelectedFareClass(parsedQueryFareClass);

    history.replace({
      ...history.location,
      search: queryStringParser.stringify({
        origin: originToSearch?.id.code.code,
        destination: (destination?.id as IIdLodgings).lodgingSelection
          .searchTerm,
        lodgingId: selectedAvailability.lodging.id,
        fromDate: dayjs(fromDate).format("YYYY-MM-DD"),
        untilDate: dayjs(untilDate).format("YYYY-MM-DD"),
        adultsCount: travelers.adultsCount,
        children: travelers.children,
        infants: travelers.infants,
        stopsOption: stopOptions,
        ...(!!fareClass && { fareClass }),
        searchToken: parsedQueryStringSearchToken,
        ...(parsedQueryStringShopToken
          ? { shopToken: parsedQueryStringShopToken }
          : {}),
      }),
    });

    ({
      parsedQueryString,
      parsedQueryStringFromDate,
      parsedQueryStringUntilDate,
      parsedQueryStringAdultsCount,
      parsedQueryStringChildren,
      parsedQueryStringInfants,
      parsedQueryStopsOptions,
      parsedQueryFareClass,
      parsedQueryStringSearchToken,
      parsedQueryStringShopToken,
    } = parseQueryString(fetchPackageHotelShopAction, now));
  }
  if (
    !selectedAvailability ||
    !fromDate ||
    !untilDate ||
    fetchPackageHotelShopAction.options?.overrideStateByQueryParams ||
    fetchPackageHotelShopAction.options?.forceCallPackageHotelAvailability
  ) {
    const lodgingSelection: PackageLodging = {
      id: { value: parsedQueryString.lodgingId },
      destinationType: DestinationEnum.Lodging,
    };

    const selectedFareClasses = getSelectedFareClasses(parsedQueryFareClass);

    const availabilityRequestBody: SearchPackagesRequest = {
      searchType: SearchPackagesRequestEnum.InitialRequest,
      origin: originToSearch?.id.code as AirportRegion,
      destination: lodgingSelection,
      dates: {
        from: dayjs(parsedQueryStringFromDate).format("YYYY-MM-DD"),
        until: dayjs(parsedQueryStringUntilDate).format("YYYY-MM-DD"),
      },
      platform: isMobile() ? Platform.Mobile : Platform.Desktop,
      roomCount: 1,
      travelers: {
        adultCount: parsedQueryStringAdultsCount,
        childAges: parsedQueryStringChildren,
        infantOnLapAges: parsedQueryStringInfants
          ?.filter((infant) => !infant.inSeat)
          .map((infant) => infant.age),
        infantInSeatAges: parsedQueryStringInfants
          ?.filter((infant) => infant.inSeat)
          .map((infant) => infant.age),
      },
      searchFilters: {
        flightFilter:
          parsedQueryStopsOptions === SliceStopCountFilter.NONE
            ? ShopFilter.NonStop
            : ShopFilter.NoFilter,
        ...(selectedFareClasses.length > 0 && {
          fareClass: selectedFareClasses,
        }),
      },
      searchToken: { value: parsedQueryStringSearchToken },
    };

    fromDate = parsedQueryStringFromDate;
    untilDate = parsedQueryStringUntilDate;
    yield putResolve(searchActions.setOrigin(originToSearch));
    yield putResolve(searchActions.setDestination(destinationToSearch));
    yield putResolve(searchActions.setDepartureDate(fromDate));
    yield putResolve(searchActions.setReturnDate(untilDate));
    yield putResolve(
      searchActions.setTravelers({
        adultsCount: parsedQueryStringAdultsCount,
        children: parsedQueryStringChildren,
        infants: parsedQueryStringInfants?.map((infant) => infant),
      })
    );
    yield putResolve(
      searchActions.setFareclassOptionFilter(parsedQueryFareClass)
    );

    yield putResolve(
      availabilityActions.setFareclassOptionFilter(parsedQueryFareClass)
    );

    yield putResolve(availabilityActions.setSearchedDates(fromDate, untilDate));
    yield putResolve(
      availabilityActions.setSearchedTravelers({
        adultsCount: parsedQueryStringAdultsCount,
        children: parsedQueryStringChildren,
        infants: parsedQueryStringInfants?.map((infant) => infant),
      })
    );
    yield put(availabilityActions.setSearchedOriginResult(origin));
    yield put(availabilityActions.setSearchedDestinationResult(destination));
    const availabilityResponse: SearchPackagesResponse = yield call(
      fetchPackagesAvailability,
      availabilityRequestBody
    );

    if (availabilityResponse.Response === PackageShopResponseEnum.Success) {
      selectedAvailability = (
        availabilityResponse.value.lodgingAvailability.value
          .lodgings as Lodging[]
      )?.find((lodging) => lodging.lodging.id === parsedQueryString.lodgingId);
      selectedPackageByLodgingId =
        availabilityResponse.value.packagesByLodgingId[
          parsedQueryString.lodgingId
        ];
      if (selectedAvailability) {
        yield putResolve(actions.selectLodging(selectedAvailability));
        yield putResolve(
          actions.selectPackageByLodgingId(selectedPackageByLodgingId)
        );
        yield put(
          availabilityActions.setPackagesAvailabilityResults({
            payload: availabilityResponse.value,
          })
        );
      }

      if (
        availabilityResponse.value.searchType ===
        SearchPackagesResponseEnum.InitialResponse
      ) {
        yield putResolve(
          actions.setDateRange(
            (availabilityResponse.value as SearchPackagesResponseInitial)
              .stayDates
          )
        );
      }
    } else {
      yield put(
        availabilityActions.setPackagesAvailabilityErrors(
          availabilityResponse.errors
        )
      );
      yield put(availabilityActions.setPackagesAvailabilityCallStateFailed());
    }

    if (
      parsedQueryStringShopToken &&
      fetchPackageHotelShopAction.options?.forceCallPackageHotelAvailability &&
      selectedPackageByLodgingId?.opaqueShopRequest
    ) {
      const fareClass = getSelectedFareClass(parsedQueryFareClass);
      history.replace({
        ...history.location,
        search: queryStringParser.stringify({
          origin: originToSearch?.id.code.code,
          destination: (destination?.id as IIdLodgings).lodgingSelection
            .searchTerm,
          lodgingId: selectedAvailability?.lodging.id,
          fromDate: dayjs(fromDate).format("YYYY-MM-DD"),
          untilDate: dayjs(untilDate).format("YYYY-MM-DD"),
          adultsCount: travelers.adultsCount,
          children: travelers.children,
          infants: travelers.infants,
          stopsOption: stopOptions,
          ...(!!fareClass && { fareClass }),
          searchToken: parsedQueryStringSearchToken,
          ...(parsedQueryStringShopToken
            ? { shopToken: selectedPackageByLodgingId?.opaqueShopRequest }
            : {}),
        }),
      });
      parsedQueryStringShopToken =
        selectedPackageByLodgingId?.opaqueShopRequest;
    }

    if (fetchPackageHotelShopAction.options?.fetchLodgingToken) {
      const tokenRequestBody: LodgingTokenRequest = {
        progressiveConfig: {
          pageSize: 2,
        },
        stayDates: {
          from: dayjs(parsedQueryStringFromDate).format("YYYY-MM-DD"),
          until: dayjs(parsedQueryStringUntilDate).format("YYYY-MM-DD"),
        },
        guests: {
          adults: parsedQueryStringAdultsCount,
          children: parsedQueryStringChildren,
        },

        platform: isMobile() ? PlatformEnum.Mobile : PlatformEnum.Desktop,

        lodgingCollection: LodgingCollectionEnum.NoCollection,
        rooms: { numberOfRooms: 1 },
        lodgingId: parsedQueryString.lodgingId,
        opaqueShopRequest:
          parsedQueryStringShopToken ??
          selectedPackageByLodgingId?.opaqueShopRequest ??
          "",
      };
      const tokenResponse: LodgingTokenResponse = yield call(
        fetchCacheLodgingToken,
        tokenRequestBody
      );
      lodgingToken = tokenResponse.opaqueShopRequest;
    } else if (parsedQueryStringShopToken) {
      lodgingToken = localCache.get(parsedQueryStringShopToken);
    }
  }

  return {
    selectedAvailability,
    selectedPackageByLodgingId,
    shopToken: lodgingToken,
  };
}

function* fetchOriginLocation(
  parsedQueryString: IPackagesAvailabilityParsedQuery
) {
  const originRequestBody: ILocationQueryLabel = {
    LocationQuery: LocationQueryEnum.Label,
    l: parsedQueryString.origin,
  };

  const { categories: originCategories }: IResponse =
    yield fetchFlightAutocomplete(originRequestBody);
  const correspondingOrigin = originCategories
    .flatMap((category) => (category as ITripTerminusCategory).results)
    .find(
      (result) => result.id.code.code === parsedQueryString.origin
    ) as ITripTerminus;

  return { correspondingOrigin };
}

export function* fetchDestinationLocation(
  parsedQueryString: IPackagesAvailabilityParsedQuery
) {
  if (!parsedQueryString.destination)
    return { correspondingLocation: undefined };

  const location = decodeURIComponent(parsedQueryString.destination);

  const locationRequestBody: ILocationQueryLabel = {
    LocationQuery: LocationQueryEnum.Label,
    l: location,
  };

  const { categories: locationCategories }: IResponse =
    yield fetchHotelAutocomplete(locationRequestBody);
  const correspondingLocations = locationCategories.flatMap((category) =>
    category.results.find(
      (result) =>
        (result.id as IIdLodgings).lodgingSelection.placeId ===
          parsedQueryString.placeId ||
        result.label.toLowerCase().includes(location.toLowerCase())
    )
  );
  const allLocations = locationCategories.flatMap(
    (category) => category.results
  );

  return {
    correspondingLocation:
      correspondingLocations.length > 0
        ? correspondingLocations[0]
        : allLocations[0],
  };
}
