import { Box, Typography } from "@material-ui/core";
import React, { useContext, useEffect, useMemo } from "react";
import { RouterProps } from "react-router";
import { PackagesHotelsAvailabilityConnectorProps } from "./container";
import clsx from "clsx";
import {
  Header,
  HotelSplitMapView,
  useDeviceTypes,
  HotelSearchLoadingImage,
  B2BLoadingPopup,
} from "halifax";
import { config } from "../../api/config";
import { PATH_HOME } from "../../utils/paths";
import { ClientContext } from "../../App";
import { RewardsAccountSelection } from "../rewards/components";
// import { TravelWalletDrawer } from "../travel-wallet/components";
import { PORTAL_TITLE, SELECT_HOTEL_TITLE } from "../../lang/textConstants";
import "./styles.scss";
import { AvailabilityList } from "./components/AvailabilityList";
import { AvailabilityMap } from "./components/AvailabilityMap";
import { PackagesAvailabilityCallState } from "./reducer/state";
import * as constants from "./textConstants";
import { MobilePackagesHotelAvailability } from "./components/MobilePackagesHotelAvailability";
import { TravelWalletDrawer } from "../travel-wallet/components";
import dayjs from "dayjs";
import { IIdLodgings, PackagesEntryTypeEnum } from "redmond";
import { AvailabilityErrorModal } from "./components/AvailabilityErrorModal";
import { parseQueryString } from "../search/utils/parseQueryString";
import queryStringParser from "query-string";

export interface IPackagesHotelsAvailability
  extends PackagesHotelsAvailabilityConnectorProps,
    RouterProps {}

export const PackagesHotelsAvailability = (
  props: IPackagesHotelsAvailability
) => {
  const {
    history,
    fetchTravelWalletDetails,
    fetchInitialPackagesAvailability,
    stopFetchMorePackagesAvailability,
    packagesAvailabilityCallState,
    largestValueAccount,
    untilDate,
    fromDate,
    location,
    setPackageEntryPoint,
  } = props;
  const [mobileMapOpen, setMobileMapOpen] = React.useState(false);
  const [currentUrl, setCurrentUrl] = React.useState(history.location.search);

  const { logo } = useContext(ClientContext);
  const { matchesMobile } = useDeviceTypes();
  const [locationName, setLocationName] = React.useState("");
  const [isSearchTermLodging, setIsSearchTermLodging] = React.useState(false);
  // If search term is location (ex: Toronto) vs if search term is point of interest (ex: Toronto Zoo)
  const [isSearchTermPoint, setIsSearchTermPoint] = React.useState(false);

  const parsedQueryString = parseQueryString(history);

  React.useEffect(() => {
    if (location) {
      const [locationName] = location?.label ? location.label.split(",") : [];
      setLocationName(locationName);
      const placeTypes = location
        ? (location.id as IIdLodgings).lodgingSelection.placeTypes
        : [];

      setIsSearchTermLodging(placeTypes.includes("lodging"));
      setIsSearchTermPoint(
        !placeTypes.includes("locality") &&
          !placeTypes.includes("political") &&
          !placeTypes.includes("country")
      );
    }
  }, [location]);

  useEffect(() => {
    fetchInitialPackagesAvailability(history);

    return () => {
      stopFetchMorePackagesAvailability();
    };
  }, []);

  useEffect(() => {
    if (history.location.search !== currentUrl) {
      fetchInitialPackagesAvailability(history);
      setCurrentUrl(history.location.search);
    }
  }, [history.location.search]);

  const queryString = useMemo(
    () => queryStringParser.parse(history.location.search),
    [history.location.search]
  );
  const { entryPoint } = queryString;

  useEffect(() => {
    if (entryPoint) {
      setPackageEntryPoint(entryPoint as PackagesEntryTypeEnum);
    }
  }, [entryPoint]);

  useEffect(() => {
    fetchTravelWalletDetails();
    // TODO: Find out why window.scrollTo requires a setTimeout; it's needed so that the browser nav button works with scrollTo
    document.title = SELECT_HOTEL_TITLE;
    setTimeout(() => window.scrollTo(0, 0), 0);
    return () => {
      document.title = PORTAL_TITLE;
    };
  }, []);

  const renderDesktopView = () => (
    <HotelSplitMapView
      className={clsx("packages-hotels-availability-container", config.TENANT)}
      header={
        // TODO: Make this common component to be used throughout Packages Funnel?
        <Header
          className="rewards-components-section"
          left={
            <Box className={"rewards-account-section-left-content"}>
              <Box className={"logo"} onClick={() => history.push(PATH_HOME)}>
                {logo}
              </Box>
              <Box className={"rewards-account-section-travel-details"}>
                <Typography variant={"body1"} tabIndex={0}>
                  {constants.CHOOSE_HOTEL_TEXT(
                    locationName,
                    isSearchTermLodging,
                    isSearchTermPoint
                  )}
                </Typography>
                {fromDate && untilDate ? (
                  <Typography variant={"body2"}>
                    {constants.DATES_TEXT(
                      dayjs(fromDate).format("ddd, MMM DD"),
                      dayjs(untilDate).format("ddd, MMM DD")
                    )}
                  </Typography>
                ) : null}
              </Box>
            </Box>
          }
          right={
            <Box className="desktop-hotel-availability-rewards-account-contents">
              <RewardsAccountSelection
                className={clsx("b2b hide-balance-border")}
                popoverClassName="b2b"
              />
              <TravelWalletDrawer />
            </Box>
          }
        />
      }
      leftClassName={clsx("list-section")}
      left={<AvailabilityList />} // [Packages TO-DO]: Remove, lodgings will be passed through container redux state when we set using packages API
      rightClassName={clsx("map-section")}
      right={<AvailabilityMap />} // [Packages TO-DO]: Remove, lodgings will be passed through container redux state when we set using packages API
    />
  );

  const renderMobileView = () => (
    <Box
      className={clsx("packages-hotels-availability-container", config.TENANT, {
        "mobile-map": mobileMapOpen,
      })}
    >
      <MobilePackagesHotelAvailability onShowMap={setMobileMapOpen} />
    </Box>
  );

  const showEarnEnhancement =
    !!largestValueAccount && !!largestValueAccount.earn.hotelsMultiplier;

  const getCapOneLoadingSecondaryText = () => {
    if (showEarnEnhancement) {
      return constants.EARN_ENHANCEMENT_SUBTITLE(
        largestValueAccount.earn.hotelsMultiplier,
        largestValueAccount.productDisplayName,
        largestValueAccount.rewardsBalance.currencyDescription ??
          largestValueAccount.rewardsBalance.currency,
        parsedQueryString?.entryPoint === "flight_list"
          ? largestValueAccount.earn.flightsMultiplier
          : undefined
      );
    }
    if (
      largestValueAccount?.productDisplayName.toLowerCase().includes("paradise")
    ) {
      return constants.PARADISE_SUBTITLE;
    }

    return constants.FETCHING_AVAILABILITY_SECONDARY_TEXT;
  };
  return (
    <>
      {packagesAvailabilityCallState ===
      PackagesAvailabilityCallState.InitialSearchCallInProcess ? (
        <B2BLoadingPopup
          open={
            packagesAvailabilityCallState ===
            PackagesAvailabilityCallState.InitialSearchCallInProcess
          }
          message={
            parsedQueryString?.entryPoint === "flight_list"
              ? constants.FETCHING_PACKAGES_AVAILABILITY_TEXT
              : constants.FETCHING_AVAILABILITY_TEXT
          }
          secondaryMessage={getCapOneLoadingSecondaryText()}
          image={HotelSearchLoadingImage}
          className={clsx("hotel-search-loading-popup earn-enhancement")}
          popupSize={matchesMobile ? "mobile" : "desktop"}
        />
      ) : null}

      <AvailabilityErrorModal />
      <Box
        className={clsx("package-hotels-availability-root", {
          "full-width": !matchesMobile,
        })}
      >
        {matchesMobile ? renderMobileView() : renderDesktopView()}
      </Box>
    </>
  );
};
