import React, { useState } from "react";
import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import { ActionButton, Icon, IconName } from "halifax";

import "./styles.scss";
import dayjs from "dayjs";
import { useInView } from "react-intersection-observer";
import { IIdFlight, ITripTerminus } from "redmond";
import { FlightShopHeaderConnectorProps } from "./container";
import * as constants from "./constants";

import { PackagesShopProgressBar } from "../../../book/components";
import { FlightShopAllFiltersModal } from "../FlightShopAllFiltersModal";
import { FlightShopFilters } from "../FlightShopFilters";
import { SortOptionSelection } from "../SortOptionSelection";
import { FILTERS_MODAL_CTA_TEXT } from "../FlightShopAllFiltersModal/textConstants";
import { airCXV3SortOptionsToDisplay } from "../../../../utils/sortAndFilter";
import { FareClassesInfo } from "./FareClassesInfo";
import { SelectedPackagePricingCard } from "./SelectedPackagePricingCard";
import { FlightSummaryPanel } from "./FlightSummaryPanel";
import {
  AVAILABLE,
  getExperimentVariant,
  PACKAGES_ORIGIN_MULTIPLE_AIRPORT,
  useExperiments,
} from "../../../../context/experiments";

export interface IFlightShopHeaderProps extends FlightShopHeaderConnectorProps {
  isMobile: boolean;
  isMediumDesktop?: boolean;
}

export const FlightShopHeader = (props: IFlightShopHeaderProps) => {
  const {
    destination,
    origin,
    isMobile,
    isMediumDesktop,
    isOutgoing,
    departureDate,
    returnDate,
    activeFiltersCount,
    sortOption,
    setSortOption,
    airportMap,
    flights,
  } = props;

  const [allFiltersModalOpen, setAllFiltersModalOpen] = useState(false);

  const expState = useExperiments();

  const isMultiAirportExperiment =
    getExperimentVariant(
      expState.experiments,
      PACKAGES_ORIGIN_MULTIPLE_AIRPORT
    ) === AVAILABLE;

  const fixedShelfOffset = React.useMemo(
    () =>
      (document
        .querySelector(".b2b-portal-banner-root")
        ?.getBoundingClientRect().height || 0) +
      (document.querySelector(".app-header")?.getBoundingClientRect().height ||
        0),
    []
  );

  const getScrollbarWidth = function () {
    const outer = document.createElement("div");
    outer.style.visibility = "hidden";
    outer.style.width = "100px";
    document.body.appendChild(outer);

    const widthNoScroll = outer.offsetWidth;
    outer.style.overflow = "scroll";

    const inner = document.createElement("div");
    inner.style.width = "100%";
    outer.appendChild(inner);

    const widthWithScroll = inner.offsetWidth;
    outer.parentNode?.removeChild(outer);

    return widthNoScroll - widthWithScroll;
  };

  const [
    fixedFareClassShelfPlaceholderRef,
    fixedFareClassShelfPlaceholderInView,
  ] = useInView({
    threshold: 1,
    rootMargin: `-${fixedShelfOffset}px 0px 0px 0px`,
  });

  const [fixedFareClassShelfRef, fixedFareClassShelfInView] = useInView();

  const showFixedShelf =
    fixedFareClassShelfInView && !fixedFareClassShelfPlaceholderInView;

  const airportCodes:
    | { origin: string | undefined; destination: string | undefined }
    | undefined = React.useMemo(() => {
    if (flights?.trips) {
      const trip: any = Object.values(flights.trips)[0];

      const outboundSlice = flights?.slices?.[trip?.outbound];

      return {
        origin: outboundSlice?.origin,
        destination: outboundSlice?.destination,
      };
    }
    return undefined;
  }, [flights]);

  const getCurrentStepFlightListTitle = () => {
    if (!airportMap) return "";
    let originAirport;
    let destinationAirport;

    if (isMultiAirportExperiment) {
      originAirport = origin ? airportMap[origin?.id.code.code] : undefined;
      destinationAirport = destination
        ? airportMap[(destination?.id as IIdFlight).code?.code]
        : undefined;
    } else {
      originAirport = airportCodes?.origin
        ? airportMap[airportCodes.origin]
        : undefined;

      destinationAirport = airportCodes?.destination
        ? airportMap[airportCodes.destination]
        : undefined;
    }

    return constants.FLIGHT_LIST_TITLE(
      isOutgoing ? originAirport : destinationAirport,
      isOutgoing ? destinationAirport : originAirport,
      (isOutgoing ? origin : (destination as ITripTerminus)) || undefined,
      (isOutgoing ? (destination as ITripTerminus) : origin) || undefined,
      isMultiAirportExperiment
    );
  };

  const getCurrentStepFlightDate = () =>
    isOutgoing
      ? dayjs(departureDate).format("ddd, MMM D")
      : dayjs(returnDate).format("ddd, MMM D");

  const renderDesktopFlightShopHeader = () => (
    <>
      <Box className={clsx("progress-bar-container")}>
        <PackagesShopProgressBar />
      </Box>
      <Box className="filter-entry-points air-cx-v3">
        <FlightShopAllFiltersModal
          openAllFiltersModal={allFiltersModalOpen}
          setOpenAllFiltersModal={setAllFiltersModalOpen}
        />
        <ActionButton
          className={clsx("filters-modal-action-button")}
          defaultStyle="h4r-secondary"
          message={FILTERS_MODAL_CTA_TEXT(activeFiltersCount)}
          icon={<Icon name={IconName.Settings} />}
          onClick={() => setAllFiltersModalOpen(true)}
        />
        <FlightShopFilters />
      </Box>
      <Box className="title-and-selected-package">
        <Box className="left">
          {!!airportMap && (
            <>
              <Typography variant="h3" className="title">
                {getCurrentStepFlightListTitle()}
              </Typography>
              <Typography className="date">
                {getCurrentStepFlightDate()}
              </Typography>
            </>
          )}
        </Box>
        <Box className="right">
          <FlightSummaryPanel />
          <SelectedPackagePricingCard />
        </Box>
      </Box>
      <div
        ref={fixedFareClassShelfPlaceholderRef}
        className={clsx({
          "fixed-shelf-placeholder":
            fixedFareClassShelfInView && !fixedFareClassShelfPlaceholderInView,
        })}
      />{" "}
      <div
        className={clsx("filters-sorting", {
          fixed: showFixedShelf,
        })}
        style={{
          top: fixedShelfOffset,
          marginLeft:
            window.innerWidth > 1460 && showFixedShelf
              ? (getScrollbarWidth() / 2) * -1
              : undefined,
        }}
        ref={fixedFareClassShelfRef}
      >
        <Box className={clsx("sorting-fares", "filter-experiment")}>
          <SortOptionSelection
            sortOption={sortOption}
            setSortOption={setSortOption}
            sortOptions={airCXV3SortOptionsToDisplay}
          />
          {showFixedShelf && (
            <>
              <FlightShopAllFiltersModal
                openAllFiltersModal={allFiltersModalOpen}
                setOpenAllFiltersModal={setAllFiltersModalOpen}
              />
              <ActionButton
                className={clsx("filters-modal-action-button")}
                defaultStyle="h4r-secondary"
                message={FILTERS_MODAL_CTA_TEXT(activeFiltersCount)}
                icon={<Icon name={IconName.Settings} />}
                onClick={() => setAllFiltersModalOpen(true)}
              />
            </>
          )}
          <FareClassesInfo />
        </Box>{" "}
      </div>
    </>
  );

  const renderMobileFlightShopHeader = () => (
    <Box className="mobile-pkg-flights-header">
      <Typography variant="h3" className="title">
        {getCurrentStepFlightListTitle()}
      </Typography>
      <Typography className="subtitle">
        {constants.FLIGHT_LIST_SUBTITLE}
      </Typography>
    </Box>
  );

  return (
    <Box
      className={clsx("pkg-flight-shop-header-root", {
        mobile: isMobile,
        "medium-desktop": isMediumDesktop,
      })}
    >
      <Box className="flight-shop-header-container">
        {!isMobile && renderDesktopFlightShopHeader()}
        {!!isMobile && renderMobileFlightShopHeader()}
      </Box>
    </Box>
  );
};
