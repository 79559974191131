import { ParentState } from "@capone/checkout";
import { CheckoutStepperProps } from "halifax/build/CheckoutStepper/component";
import { trackEvent } from "../../../../api/v0/trackEvent";
import {
  FinalizePackageResponse,
  OpaqueToken,
  REDIRECT_TO_PACKAGE_HOTEL_SHOP,
} from "redmond";

export const getCheckoutSteps = (
  parentState: ParentState
): CheckoutStepperProps["steps"] => {
  return [
    {
      name: "Who's flying?",
      status: (() => {
        switch (parentState) {
          case ParentState.passengerInformation:
          case ParentState.passport:
          case ParentState.contactInformation:
          case ParentState.loading:
            return 1;
          default:
            return 2;
        }
      })(),
    },
    {
      name: "Who's checking in?",
      status: (() => {
        switch (parentState) {
          case ParentState.passengerInformation:
          case ParentState.passport:
          case ParentState.contactInformation:
          case ParentState.loading:
            return 0;
          case ParentState.lodgingPassengerInformation:
            return 1;
          default:
            return 2;
        }
      })(),
    },
    {
      name: "Seat selection",
      status: (() => {
        switch (parentState) {
          case ParentState.passengerInformation:
          case ParentState.passport:
          case ParentState.contactInformation:
          case ParentState.loading:
          case ParentState.lodgingPassengerInformation:
          case ParentState.cartQuote:
            return 0;
          case ParentState.seatSelection:
            return 1;
          default:
            return 2;
        }
      })(),
    },
    {
      name: "Rewards & payment",
      status: (() => {
        switch (parentState) {
          case ParentState.passengerInformation:
          case ParentState.passport:
          case ParentState.contactInformation:
          case ParentState.loading:
          case ParentState.lodgingPassengerInformation:
          case ParentState.cartQuote:
          case ParentState.seatSelection:
            return 0;
          case ParentState.cardPayment:
            return 1;
          default:
            return 2;
        }
      })(),
    },
  ];
};

export const getMissingStateValuesForTracking = (
  values: Record<string, any | undefined>
): string => {
  const keysWithUndefinedValues = Object.keys(values).filter(
    (key) => !values[key]
  );

  return keysWithUndefinedValues.reduce(
    (missingKeyValuesString, currentKey, i) => {
      if (i > 0) {
        return `${missingKeyValuesString},${currentKey}`;
      }

      return currentKey;
    },
    ""
  );
};

export const trackHotelShopRedirect = (
  opaqueQuoteRequest: string | undefined,
  opaquePackageToken: OpaqueToken | undefined,
  finalizePackageResponse: FinalizePackageResponse | undefined,
  stateValues: Record<string, any | undefined>
) => {
  trackEvent({
    eventName: REDIRECT_TO_PACKAGE_HOTEL_SHOP,
    properties: {
      screen: "package_checkout",
      reason: (() => {
        switch (true) {
          case !opaqueQuoteRequest && !opaquePackageToken:
            return "missing_hotel_and_flight_tokens";
          case !opaqueQuoteRequest:
            return "missing_hotel_token";
          case !opaquePackageToken:
            return "missing_flight_token";
          case !finalizePackageResponse:
            return "missing_package_finalize_response";
          default:
            return `missing_state_values:${getMissingStateValuesForTracking(
              stateValues
            )}`;
        }
      })(),
    },
    encryptedProperties: [],
  });
};
