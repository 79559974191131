import * as actionTypes from "./constants";
import * as H from "history";
import {
  DateRange,
  ICategorizedResponse,
  IResult,
  Lodging,
  PackagedLodging,
  ShopRoomsResponse,
} from "redmond";
import {
  PackageHotelShopCallState,
  PackageHotelShopCallError,
  PackageHotelShopStep,
} from "../reducer/state";

export interface IFetchPackageHotelShop {
  type: actionTypes.FETCH_PACKAGE_HOTEL_SHOP;
  history: H.History;
  options?: {
    overrideStateByQueryParams?: boolean;
    forceCallPackageHotelAvailability?: boolean;
    fetchLodgingToken?: boolean;
  };
}

export const fetchPackageHotelShop = (
  history: H.History,
  options?: {
    overrideStateByQueryParams?: boolean;
    forceCallPackageHotelAvailability?: boolean;
    fetchLodgingToken?: boolean;
  }
): IFetchPackageHotelShop => ({
  type: actionTypes.FETCH_PACKAGE_HOTEL_SHOP,
  history,
  options,
});

export interface ISetPackageHotelShopResults {
  type: actionTypes.SET_PACKAGE_HOTEL_SHOP_RESULTS;
  hotelShopCallState: PackageHotelShopCallState;
  hotelShopCallError: PackageHotelShopCallError | null;
  payload?: ShopRoomsResponse;
}

export const setPackageHotelShopResults = (args: {
  hotelShopCallState: PackageHotelShopCallState;
  hotelShopCallError?: PackageHotelShopCallError;
  payload?: ShopRoomsResponse;
}): ISetPackageHotelShopResults => ({
  type: actionTypes.SET_PACKAGE_HOTEL_SHOP_RESULTS,
  ...{ hotelShopCallError: null, ...args },
});

interface IISetPackageHotelShopCallStateFailedPayload {
  hotelShopCallError: PackageHotelShopCallError;
}
export interface ISetPackageHotelShopCallStateFailed
  extends IISetPackageHotelShopCallStateFailedPayload {
  type: actionTypes.SET_PACKAGE_HOTEL_SHOP_CALL_STATE_FAILED;
}

export const setPackageHotelShopCallStateFailed = ({
  hotelShopCallError,
}: IISetPackageHotelShopCallStateFailedPayload): ISetPackageHotelShopCallStateFailed => ({
  type: actionTypes.SET_PACKAGE_HOTEL_SHOP_CALL_STATE_FAILED,
  hotelShopCallError,
});

export interface ISelectLodging {
  type: actionTypes.SELECT_LODGING;
  lodging: Lodging;
}

export const selectLodging = (lodging: Lodging): ISelectLodging => ({
  type: actionTypes.SELECT_LODGING,
  lodging,
});

export interface ISelectPackageByLodgingId {
  type: actionTypes.SELECT_PACKAGE_BY_LODGING_ID;
  packageByLodgingId: PackagedLodging;
}

export const selectPackageByLodgingId = (
  packageByLodgingId: PackagedLodging
): ISelectPackageByLodgingId => ({
  type: actionTypes.SELECT_PACKAGE_BY_LODGING_ID,
  packageByLodgingId,
});

export interface ISetDateRange {
  type: actionTypes.SET_DATE_RANGE;
  dateRange: DateRange;
}

export const setDateRange = (dateRange: DateRange): ISetDateRange => ({
  type: actionTypes.SET_DATE_RANGE,
  dateRange,
});

export interface IAcknowledgePackageHotelShopFailure {
  type: actionTypes.ACKNOWLEDGE_PACKAGE_HOTEL_SHOP_FAILURE;
}

export const acknowledgePackageHotelShopFailure =
  (): IAcknowledgePackageHotelShopFailure => ({
    type: actionTypes.ACKNOWLEDGE_PACKAGE_HOTEL_SHOP_FAILURE,
  });

export interface ISetPackageHotelShopProgress {
  type: actionTypes.SET_PACKAGE_HOTEL_SHOP_PROGRESS;
  progress: PackageHotelShopStep;
}

export const setPackageHotelShopProgress = (
  progress: PackageHotelShopStep
): ISetPackageHotelShopProgress => ({
  type: actionTypes.SET_PACKAGE_HOTEL_SHOP_PROGRESS,
  progress,
});

export interface ISetSelectedRoomId {
  type: actionTypes.SET_SELECTED_ROOM_ID;
  selectedRoomId: string;
}

export const setSelectedRoomId = (
  selectedRoomId: string
): ISetSelectedRoomId => ({
  type: actionTypes.SET_SELECTED_ROOM_ID,
  selectedRoomId,
});

export interface ISelectRoomType {
  type: actionTypes.SELECT_ROOM_TYPE;
  roomInfoIndex: number | null;
  productIndex: number | null;
  rateId?: string;
}

export const selectRoomType = (
  roomInfoIndex: number | null,
  productIndex: number | null,
  rateId?: string
): ISelectRoomType => {
  return {
    type: actionTypes.SELECT_ROOM_TYPE,
    roomInfoIndex,
    productIndex,
    rateId,
  };
};

export interface IFetchDistanceFromLocationCategories {
  type: actionTypes.FETCH_DISTANCE_FROM_LOCATION_CATEGORIES;
  queryString: string;
}

export const fetchDistanceFromLocationCategories = (
  queryString: string
): IFetchDistanceFromLocationCategories => ({
  type: actionTypes.FETCH_DISTANCE_FROM_LOCATION_CATEGORIES,
  queryString,
});

export interface ISetDistanceFromLocationCategories {
  type: actionTypes.SET_DISTANCE_FROM_LOCATION_CATEGORIES;
  categories: ICategorizedResponse[];
}

export const setDistanceFromLocationCategories = (
  categories: ICategorizedResponse[]
): ISetDistanceFromLocationCategories => ({
  type: actionTypes.SET_DISTANCE_FROM_LOCATION_CATEGORIES,
  categories,
});

export interface ISetDistanceFromLocation {
  type: actionTypes.SET_DISTANCE_FROM_LOCATION;
  location: IResult | null;
}

export const setDistanceFromLocation = (
  location: IResult | null
): ISetDistanceFromLocation => ({
  type: actionTypes.SET_DISTANCE_FROM_LOCATION,
  location,
});

export type PackageHotelShopActions =
  | IFetchPackageHotelShop
  | ISetPackageHotelShopResults
  | ISetPackageHotelShopCallStateFailed
  | ISelectLodging
  | ISetDateRange
  | IAcknowledgePackageHotelShopFailure
  | ISetPackageHotelShopProgress
  | ISelectPackageByLodgingId
  | ISetSelectedRoomId
  | ISelectRoomType
  | IFetchDistanceFromLocationCategories
  | ISetDistanceFromLocationCategories
  | ISetDistanceFromLocation;
