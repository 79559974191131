import {
  AirlineCode,
  CallState,
  FareclassOptionFilter,
  FlightShopStep,
  FlightSortOption,
  IFlightNumberFilter,
  ITimeRange,
  PackageFareDetails,
  ShopFlightsResponse,
  SliceStopCountFilter,
  TripDetails,
} from "redmond";
import { actions, actionTypes } from "../actions";

export interface IFlightShopFilterState {
  stopsOption: SliceStopCountFilter;
  maxPriceFilter: number;
  fareclassOptionFilter: FareclassOptionFilter;
  outboundDepartureTimeRange: ITimeRange;
  outboundArrivalTimeRange: ITimeRange;
  returnDepartureTimeRange: ITimeRange;
  returnArrivalTimeRange: ITimeRange;
  airlineFilter: AirlineCode[];
  airportFilter: string[];
  flightNumberFilter: IFlightNumberFilter[];
  baggageTransfersFilter: boolean;
  durationFilter: number;
  policyFilter: boolean;
}

export const initialFareclassOptionFilter: FareclassOptionFilter = {
  basic: false,
  standard: false,
  enhanced: false,
  premium: false,
  luxury: false,
};

export const noLCCFareclassOptionFilter: FareclassOptionFilter = {
  basic: false,
  standard: true,
  enhanced: true,
  premium: true,
  luxury: true,
};

export const INITIAL_MAX_PRICE = 0;
// number of minutes in a day = 60 min/hour * 24 hour = 1440 min
export const TIME_RANGE_MAX = 1439;

export const MAXIMUM_COUNT = 6;
export const MINIMUM_ADULT_COUNT = 1;

export const initialTimeRange: ITimeRange = {
  min: 0,
  // note: time measured in minutes
  max: TIME_RANGE_MAX,
};

export const initialFilterOptions: IFlightShopFilterState = {
  stopsOption: SliceStopCountFilter.ANY_NUMBER,
  maxPriceFilter: 0,
  fareclassOptionFilter: initialFareclassOptionFilter,
  outboundDepartureTimeRange: initialTimeRange,
  outboundArrivalTimeRange: initialTimeRange,
  returnDepartureTimeRange: initialTimeRange,
  returnArrivalTimeRange: initialTimeRange,
  airlineFilter: [],
  airportFilter: [],
  flightNumberFilter: [],
  baggageTransfersFilter: true,
  durationFilter: 0,
  policyFilter: false,
};

export interface ITripDetailsByTripId {
  [key: string]: TripDetails;
}

export interface IPackagesFlightShopState extends IFlightShopFilterState {
  packagesFlightShopCallState: CallState;
  // TODO: Change this to use the correct model from airShopSummary
  packagesFlightShopResults: ShopFlightsResponse | null;
  tripDetailsLoading: boolean | null;
  tripDetailsById: ITripDetailsByTripId;
  selectedTrip: ISelectedTrip | null;
  packagesFlightShopProgress: FlightShopStep;
  sortOption: FlightSortOption;
}
export interface ISelectedTrip {
  tripId: string | null;
  outgoingSliceId: string | null;
  outgoingFareId: string | null;
  outgoingFareSliceId: string | null;
  outgoingFareRating?: number;
  returnSliceId?: string | null;
  returnFareId?: string | null;
  returnFareSliceId?: string | null;
  returnFareRating?: number;
}

const initialState: IPackagesFlightShopState = {
  packagesFlightShopCallState: CallState.NotCalled,
  packagesFlightShopResults: null,
  selectedTrip: null,
  packagesFlightShopProgress: FlightShopStep.ChooseDeparture,
  tripDetailsLoading: null,
  tripDetailsById: {},
  sortOption: FlightSortOption.FareScore,
  ...initialFilterOptions,
};

export const reducer = (
  state: IPackagesFlightShopState = initialState,
  action: actions.PackagesFlightShopActions
): IPackagesFlightShopState => {
  switch (action.type) {
    case actionTypes.FETCH_PACKAGE_FLIGHT_SHOP:
      return {
        ...state,
        packagesFlightShopCallState: CallState.InProcess,
      };
    case actionTypes.SET_PACKAGE_FLIGHT_SHOP_RESULTS: {
      return {
        ...state,
        packagesFlightShopCallState: action.flightShopCallState,
        packagesFlightShopResults: action.flightShopResults,
      };
    }
    case actionTypes.SET_PACKAGE_FLIGHT_SHOP_CALL_STATE_FAILED:
      return {
        ...state,
        packagesFlightShopCallState: CallState.Failed,
      };

    case actionTypes.FETCH_PACKAGES_TRIP_DETAILS:
      return {
        ...state,
        tripDetailsLoading: true,
      };
    case actionTypes.SET_PACKAGES_TRIP_DETAILS: {
      const { packagesByFareId, tripDetailsResponse } =
        action.tripDetailsResponse;
      let tripDetails = tripDetailsResponse.value as TripDetails;
      let amendedFareDetails: PackageFareDetails[] = [];
      tripDetails.fareDetails.map((fare) => {
        const packageForFare = packagesByFareId[fare.id];
        amendedFareDetails.push({
          ...fare,
          packagePricing: packageForFare.pricing,
          incrementalPricePerTraveler:
            packageForFare.incrementalPricePerTraveler,
          discountInfo: packageForFare.discountInfo,
        });
      });
      tripDetails.fareDetails = amendedFareDetails;
      const tripDetailsById = {
        ...state.tripDetailsById,
        [tripDetails.id]: tripDetails,
      };
      return {
        ...state,
        tripDetailsLoading: false,
        tripDetailsById,
      };
    }
    case actionTypes.SET_PACKAGES_TRIP_DETAILS_CALL_STATE_FAILED:
      return {
        ...state,
        tripDetailsLoading: null,
      };
    case actionTypes.SET_PACKAGES_CHOSEN_OUTGOING_SLICE: {
      const {
        outgoingSliceId,
        outgoingFareRating,
        outgoingFareId,
        outgoingFareSliceId,
        tripId,
        resetReturnIds,
      } = action;
      const selectedTrip = {
        ...state.selectedTrip,
        tripId,
        outgoingSliceId,
        outgoingFareId: outgoingFareId || null,
        outgoingFareRating: outgoingFareRating,
        outgoingFareSliceId,
        // resetting the return ids are sometimes required depending on the current progress of FlightShop workflow
        ...(resetReturnIds && {
          returnSliceId: null,
          returnFareId: null,
          returnFareSliceId: null,
        }),
      };
      return {
        ...state,
        selectedTrip,
      };
    }
    case actionTypes.SET_PACKAGES_CHOSEN_RETURN_SLICE: {
      const {
        returnSliceId,
        returnFareId,
        returnFareSliceId,
        returnFareRating,
        tripId,
      } = action;
      if (state.selectedTrip) {
        const selectedTrip = {
          ...state.selectedTrip,
          tripId,
          returnSliceId,
          returnFareRating,
          returnFareId: returnFareId || null,
          returnFareSliceId,
        };
        return {
          ...state,
          selectedTrip,
        };
      } else {
        return {
          ...state,
        };
      }
    }
    case actionTypes.SET_PACKAGES_FLIGHT_SHOP_PROGRESS: {
      if (action.progress === FlightShopStep.ChooseDeparture) {
        return {
          ...state,
          packagesFlightShopProgress: action.progress,
          selectedTrip: null,
        };
      } else {
        return {
          ...state,
          packagesFlightShopProgress: action.progress,
        };
      }
    }
    case actionTypes.SET_SORT_OPTION: {
      const { sortOption } = action;
      return {
        ...state,
        sortOption,
      };
    }
    case actionTypes.SET_MAX_PRICE_FILTER:
      return { ...state, maxPriceFilter: action.maxPriceFilter };

    case actionTypes.SET_AIRLINE_FILTER:
      return {
        ...state,
        airlineFilter: [...action.airlineFilter],
      };

    case actionTypes.SET_OUTBOUND_DEPARTURE_TIME_RANGE_FILTER:
      return {
        ...state,
        outboundDepartureTimeRange: action.outboundDepartureTimeRange,
      };

    case actionTypes.SET_OUTBOUND_ARRIVAL_TIME_RANGE_FILTER:
      return {
        ...state,
        outboundArrivalTimeRange: action.outboundArrivalTimeRange,
      };

    case actionTypes.SET_RETURN_DEPARTURE_TIME_RANGE_FILTER:
      return {
        ...state,
        returnDepartureTimeRange: action.returnDepartureTimeRange,
      };

    case actionTypes.SET_RETURN_ARRIVAL_TIME_RANGE_FILTER:
      return {
        ...state,
        returnArrivalTimeRange: action.returnArrivalTimeRange,
      };
    case actionTypes.SET_DURATION_FILTER:
      return {
        ...state,
        durationFilter: action.duration,
      };

    case actionTypes.SET_AIRPORT_FILTER:
      return {
        ...state,
        airportFilter: [...action.airportFilter],
      };

    case actionTypes.SET_FLIGHT_NUMBER_FILTER:
      const airlines = action.flightNumberFilter.map(
        (filter) => filter.airlineCode
      );

      // Reset airline filter if chosen flight number is on a different airline.
      if (
        !!airlines.length &&
        !state.airlineFilter.some((airline) => airlines.includes(airline))
      ) {
        return {
          ...state,
          airlineFilter: [],
          flightNumberFilter: [...action.flightNumberFilter],
        };
      }

      return {
        ...state,
        flightNumberFilter: [...action.flightNumberFilter],
      };

    default:
      return { ...state };
  }
};

export * from "./selectors";
