import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { CancellationPoliciesCard } from "./component";
import { IStoreState } from "../../../../reducers/types";

import { getIsTripVoidWindowEligible } from "../../../flight-shop/reducer";
import {
  getHotelShopCancellationSummary,
  getHotelShopChosenProduct,
} from "../../../hotel-shop/reducer";

export const mapStateToProps = (state: IStoreState) => {
  return {
    chosenProduct: getHotelShopChosenProduct(state),
    isVoidWindowEligible: getIsTripVoidWindowEligible(state),
    cancellationSummary: getHotelShopCancellationSummary(state),
  };
};

export const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type CancellationPoliciesCardConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedCancellationPoliciesCard = withRouter(
  connector(CancellationPoliciesCard)
);
