import { Box } from "@material-ui/core";
import clsx from "clsx";
import { Icon, IconName, MobileHotelMapTooltip } from "halifax";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { IIdLodgings, Lodging } from "redmond";
import { v4 as uuidv4 } from "uuid";
import { config } from "../../../../api/config";

import { MobileAvailabilityMapRollingGalleryConnectorProps } from "./container";
import "./styles.scss";
import { PATH_HOTEL_SHOP } from "../../../../utils/paths";
import { transformToStringifiedQuery } from "../../../hotel-shop/utils/queryStringHelpers";
import {
  useExperiments,
  getExperimentVariant,
  CACHE_HOTEL_TOKEN,
  AVAILABLE,
} from "../../../../context/experiments";
import { localCache } from "@capone/common";

export interface IMobileAvailabilityMapRollingGalleryProps
  extends MobileAvailabilityMapRollingGalleryConnectorProps,
    RouteComponentProps {}

export const MobileAvailabilityMapRollingGallery = (
  props: IMobileAvailabilityMapRollingGalleryProps
) => {
  const {
    lodgings,
    lodgingIdInFocus,
    nightCount,
    selectedAccountId,
    isVentureX,
    setLodgingIdInFocus,
    history,
    travelersCount,
    packagesByLodgingId,
    origin,
    packageHotelQueryParams,
    searchedLocation,
  } = props;

  const expState = useExperiments();
  const cacheHotelTokenEnabled =
    getExperimentVariant(expState.experiments, CACHE_HOTEL_TOKEN) === AVAILABLE;

  const lodgingInFocus = React.useMemo(
    () =>
      lodgings?.find(
        (lodging: Lodging) => lodging.lodging.id === lodgingIdInFocus
      ),
    [lodgingIdInFocus, lodgings]
  );

  const renderEachLodging = (lodging?: Lodging) => {
    if (!lodging) return null;

    const openShopPage = () => {
      if (origin && packageHotelQueryParams.fromDate !== null) {
        const params = transformToStringifiedQuery({
          lodgingId: lodging.lodging.id,
          origin: origin?.id?.code?.code,
          destination: (searchedLocation?.id as IIdLodgings).lodgingSelection
            .searchTerm,
          placeId: (searchedLocation?.id as IIdLodgings).lodgingSelection
            .placeId,

          ...packageHotelQueryParams,
          fromDate: packageHotelQueryParams.fromDate,
          shopToken: cacheHotelTokenEnabled
            ? packagesByLodgingId?.[lodging.lodging.id]?.opaqueShopRequest
            : localCache.set(
                uuidv4(),
                packagesByLodgingId?.[lodging.lodging.id]?.opaqueShopRequest ||
                  ""
              ).key,
        });
        history.push(`${PATH_HOTEL_SHOP}${params}`, {
          fromPage: location.pathname,
        });
      }
    };

    return (
      <Box
        className={clsx(
          "mobile-lodging-availability-map-rolling-gallery-wrapper"
        )}
      >
        <span
          className={clsx("mobile-hotel-tooltip-close-button", config.TENANT)}
          onClick={(e) => {
            e.stopPropagation();
            setLodgingIdInFocus(null);
          }}
        >
          <Icon name={IconName.CloseXCircle} />
        </span>
        <MobileHotelMapTooltip
          lodging={lodging}
          onClick={openShopPage}
          nightCount={nightCount || 0}
          rewardsKey={selectedAccountId ?? ""}
          isVentureX={isVentureX}
          roomsCount={1}
          variant="package"
          travelersCount={travelersCount}
          packagePricing={
            packagesByLodgingId?.[lodging.lodging.id]?.packageDetails.pricing
          }
        />
      </Box>
    );
  };

  return renderEachLodging(lodgingInFocus);
};
