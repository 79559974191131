import React from "react";
import { RewardsAccount } from "redmond";

export const TITLE = (
  <>
    <strong>Unlock special rates</strong> when you book your hotel and flight as
    a package.
  </>
);

export const SUBTITLE = (rewardsAccount: RewardsAccount) => {
  const earnSymbol =
    rewardsAccount.rewardsBalance.currency === "Cash" ? "%" : "x";

  const { flightsMultiplier, hotelsMultiplier } = rewardsAccount.earn;
  const { currencyDescription } = rewardsAccount.rewardsBalance;

  const flightEarnString = `${flightsMultiplier}${earnSymbol} ${currencyDescription} on flights`;
  const hotelEarnString = `${hotelsMultiplier}${earnSymbol} ${currencyDescription} on hotels`;

  const funnelEarnText = (() => {
    switch (true) {
      case !!flightsMultiplier && !!hotelsMultiplier:
      default:
        return `${flightEarnString} and ${hotelEarnString}`;
      case !!flightsMultiplier:
        return flightEarnString;
      case !!hotelsMultiplier:
        return hotelEarnString;
    }
  })();

  return `Plus earn ${funnelEarnText} with ${rewardsAccount.productDisplayName}.`;
};

export const INFO_MODAL_CTA_TEXT = "How do packages work?";

export const CONFIRM_TRAVELER_DETAILS =
  "Before you continue, confirm your traveler details are correct";
