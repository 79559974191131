import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { IStoreState } from "../../../../reducers/types";

import { ReviewHotelItinerary } from "./component";

import {
  getHotelShopCancellationSummary,
  getHotelShopChosenProduct,
  getHotelShopChosenRoomInfo,
  getHotelShopDateRange,
  getPackageSelectedLodging,
  getSelectedPackageByLodgingId,
} from "../../../hotel-shop/reducer";

const mapStateToProps = (state: IStoreState) => {
  const hotelDates = getHotelShopDateRange(state);
  return {
    selectedLodging: getPackageSelectedLodging(state),
    checkIn: hotelDates?.from,
    checkOut: hotelDates?.until,
    chosenProduct: getHotelShopChosenProduct(state),
    selectedPackageByLodging: getSelectedPackageByLodgingId(state),
    roomInfo: getHotelShopChosenRoomInfo(state),
    cancellationSummary: getHotelShopCancellationSummary(state),
  };
};

export const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type ReviewHotelItineraryConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedReviewHotelItinerary = withRouter(
  connector(ReviewHotelItinerary)
);
