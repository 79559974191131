import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import { AvailabilityMap } from "./component";
import { IStoreState } from "../../../../reducers/types";
import { actions } from "../../actions";
import {
  // getHotelAvailabilitySearchLocation,
  getHotelAvailabilityLodgingIdInFocus,
  getMapBound as getSearchedMapBound,
  getHotelAvailabilityLodgingIdHovered,
  getViewHotelsNearLocationCategories,
  getViewHotelsNearLocation,
  getMapBound,
  getSearchedNightCount,
  getPackagesByLodgingId,
  getPackageAvailabilitySearchLocation,
  getFilteredHotelAvailabilityLodgings,
  getPackageAvailabilityCentroids,
} from "../../reducer/selectors";

const mapStateToProps = (state: IStoreState) => {
  const visibleLodgings = getFilteredHotelAvailabilityLodgings(state);
  const searchLocation = getPackageAvailabilitySearchLocation(state);
  const lodgingIdInFocus = getHotelAvailabilityLodgingIdInFocus(state);
  const lodgingIdHovered = getHotelAvailabilityLodgingIdHovered(state);
  const searchedMapBound = getSearchedMapBound(state);
  const nightCount = getSearchedNightCount(state);
  const viewHotelsNearLocationCategories =
    getViewHotelsNearLocationCategories(state);
  const viewHotelsNearLocation = getViewHotelsNearLocation(state);
  // const viewHotelsNearAvailabilityProperties =
  //   getViewHotelsNearAvailabilityProperties(state);
  const mapBound = getMapBound(state);
  const packagesByLodgingId = getPackagesByLodgingId(state);
  const searchCentroids = getPackageAvailabilityCentroids(state);
  return {
    lodgings: visibleLodgings,
    searchLocation,
    lodgingIdInFocus,
    lodgingIdHovered,
    searchedMapBound,
    nightCount,
    viewHotelsNearLocationCategories,
    viewHotelsNearLocation,
    // viewHotelsNearAvailabilityProperties,
    mapBound,
    packagesByLodgingId,
    searchCentroids,
  };
};

const mapDispatchToProps = {
  setLodgingIdInFocus: actions.setLodgingIdInFocus,
  setSearchedMapBound: actions.setMapBound,
  setViewHotelsNearLocation: actions.setViewHotelsNearLocation,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type AvailabilityMapConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedAvailabilityMap = connector(withRouter(AvailabilityMap));
