import React from "react";
import { Box } from "@material-ui/core";
import { Icon, IconName } from "halifax";
import { IResult, ITripTerminus, RecentPackageSearch } from "redmond";
import clsx from "clsx";
import {
  OriginAutocomplete,
  DestinationAutocomplete,
} from "../TerminusAutocomplete";
import { ISetOrigin, ISetDestination } from "../../../../actions/actions";
import "./styles.scss";

interface IOriginDestinationSearchProps {
  origin: ITripTerminus | null;
  setOrigin: (origin: ITripTerminus | null) => ISetOrigin;
  destination: IResult | null;
  setDestination: (destination: IResult | null) => ISetDestination;
  hasMissingSearchInfoError?: boolean;
  disabled?: boolean;
  recentSearches?: RecentPackageSearch[];
  onRecentSearchClick?: (search: RecentPackageSearch) => void;
}

export const OriginDestinationSearch = ({
  origin,
  setOrigin,
  destination,
  setDestination,
  hasMissingSearchInfoError,
  disabled,
  recentSearches,
  onRecentSearchClick,
}: IOriginDestinationSearchProps) => (
  <Box
    className={clsx("packages-location-pickers", {
      isErrorState:
        (hasMissingSearchInfoError && !origin) ||
        (hasMissingSearchInfoError && !destination),
    })}
  >
    <OriginAutocomplete
      className="origin-auto-complete b2b"
      customIcon={<Icon name={IconName.B2BMapPin} ariaLabel="" aria-hidden />}
      label="Where from?"
      value={origin}
      setValue={setOrigin}
      disabled={disabled}
      getOptionSelected={(
        option: ITripTerminus | null,
        value: ITripTerminus | null
      ) =>
        !!value &&
        !!option &&
        value.id.code.code === option.id.code.code &&
        value.label === option.label
      }
      hasMissingSearchInfoError={hasMissingSearchInfoError && !origin}
      recentSearches={recentSearches}
      onRecentSearchClick={
        onRecentSearchClick
          ? (recentSearch) =>
              onRecentSearchClick(recentSearch as RecentPackageSearch)
          : undefined
      }
    />

    <Box className="auto-complete-divider" />

    <DestinationAutocomplete
      className="destination-auto-complete b2b"
      customIcon={<Icon name={IconName.B2BMapPin} ariaLabel="" aria-hidden />}
      label="Where to?"
      value={destination}
      setValue={setDestination}
      disabled={disabled}
      getOptionSelected={(option: IResult | null, value: IResult | null) =>
        !!value && !!option && value.label === option.label
      }
      hasMissingSearchInfoError={hasMissingSearchInfoError && !destination}
    />
  </Box>
);
