import dayjs from "dayjs";
import {
  LodgingAvailabilityCheckInTime,
  LodgingAvailabilityCheckInTimeEnum,
} from "redmond";

export const CHECK_IN_INSTRUCTIONS_TITLE =
  "Scheduled check-in and check-out time";

export const formattedCheckIn = (
  date: string,
  time?: LodgingAvailabilityCheckInTime
) => {
  return `<strong>Check-in:</strong> ${dayjs(date).format(
    "dddd, MMMM D, YYYY"
  )}${
    time && time.CheckInTime == LodgingAvailabilityCheckInTimeEnum.Restricted
      ? " at " + dayjs(time.checkInTimeFrom, "HH:mm").format("h:mm A")
      : ""
  }`;
};

export const formattedCheckOut = (date: string, time?: string) =>
  `<strong>Check-out:</strong> ${dayjs(date).format("dddd, MMMM D, YYYY")}${
    time ? " at " + dayjs(time, "HH:mm").format("h:mm A") : ""
  }`;

export const NEED_TO_KNOW_TITLE = "Need to know";
export const NEED_TO_KNOW_DETAILS =
  "All rooms are guaranteed to accommodate 2 guests. Additional guests are at the discretion of the hotel and may be subject to extra fees. At check-in, the hotel may place a temporary hold on your credit card for incidentals.";

export const CHANGE_HOTEL = "Change hotel";
export const CHANGE_ROOM = "Change room";

export const CALIFORNIA_BILL_644_CANCELLATION_TEXT = `This hotel is in California, therefore you are entitled to free cancellation without penalty for 24 hours after booking. If you choose to change or cancel this booking after this 24 hour window, you will not be refunded any of the payment.`;
