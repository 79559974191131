import {
  AirportRegion,
  DestinationEnum,
  ShopFilter,
  IIdLodgings,
  ILocationQueryLabel,
  IResponse,
  IResult,
  ITripTerminus,
  LocationQueryEnum,
  Platform,
  SearchPackagesRequest,
  SearchPackagesRequestEnum,
  SearchPackagesResponse,
  SliceStopCountFilter,
  FareclassOptionFilter,
  PACKAGE_ENTRY,
  ITrackingProperties,
  PackageEntryProperties,
  VIEWED_PACKAGES_LIST,
  PackagesViewedHotelListProperties,
  PackageShopResponseEnum,
} from "redmond";
import { call, put, putResolve, select } from "redux-saga/effects";
import { IStoreState } from "../../../reducers/types";
import { actions as searchActions } from "../../search/actions";
import { actions } from "../actions";
import { fetchPackagesAvailability as fetchAvailability } from "../../../api/v0/availability/fetchPackagesAvailability";
import {
  getDepartureDate,
  getDestination,
  getOrigin,
  getReturnDate,
  getTravelers,
  getStopsOption,
  getFareclassOptionFilter,
} from "../../search/reducer";
import Logger from "../../../utils/logger";
import { isMobile } from "../../../utils/userAgent";
import dayjs from "dayjs";
import {
  getPackageEntryProperties,
  getPackagesAvailabilityNextPageToken,
  getPackagesViewedHotelListProperties,
} from "../reducer";
import { fetchHotelAutocomplete } from "../../../api/v0/search/fetchHotelAutocomplete";
import * as H from "history";
import queryStringParser from "query-string";
import { PATH_HOME } from "../../../utils/paths";
import { ITripTerminusCategory } from "../../search/types";
import { fetchFlightAutocomplete } from "../../../api/v0/search/fetchFlightAutocomplete";
import {
  getSelectedFareClass,
  getSelectedFareClasses,
} from "../../../utils/fareClass";
import { trackEvent } from "../../../api/v0/trackEvent";
import { config } from "../../../api/config";

export function* fetchPackagesAvailabilitySaga(
  action: actions.IFetchPackagesAvailability
) {
  try {
    let requestBody: SearchPackagesRequest;
    const state: IStoreState = yield select();

    const requestData: {
      fromDate: Date | null;
      untilDate: Date | null;
      origin: ITripTerminus | null;
      destination: IResult | null;
      adultsCount: number;
      children: number[];
      infants: {
        age: number;
        inSeat: boolean;
      }[];
      stopsOption: SliceStopCountFilter;
      fareClassOptionFilter: FareclassOptionFilter;
      //   roomsCount: number;
    } = yield call(getAvailabilityRequestParameters, action);
    const {
      fromDate,
      untilDate,
      origin,
      destination,
      adultsCount,
      children,
      infants,
      stopsOption,
      fareClassOptionFilter,
      //   roomsCount,
    } = requestData;

    const selectedFareClasses = getSelectedFareClasses(fareClassOptionFilter);

    switch (action.requestType) {
      case SearchPackagesRequestEnum.InitialRequest: {
        if (JSON.stringify(requestData) === "{}") return;
        requestBody = {
          searchType: SearchPackagesRequestEnum.InitialRequest,
          origin: origin?.id.code as AirportRegion,
          originLabel: origin?.label,
          destination: {
            ...(destination?.id as IIdLodgings).lodgingSelection,
            destinationType: DestinationEnum.Place,
          },
          dates: {
            from: dayjs(fromDate).format("YYYY-MM-DD"),
            until: dayjs(untilDate).format("YYYY-MM-DD"),
          },
          platform: isMobile() ? Platform.Mobile : Platform.Desktop,
          roomCount: 1,
          travelers: {
            adultCount: adultsCount,
            childAges: children?.length ? children : [],
            infantOnLapAges: infants
              .filter((infant) => !infant.inSeat)
              .map((infant) => infant.age),
            infantInSeatAges: infants
              .filter((infant) => infant.inSeat)
              .map((infant) => infant.age),
          },
          searchFilters: {
            flightFilter:
              stopsOption === SliceStopCountFilter.NONE
                ? ShopFilter.NonStop
                : ShopFilter.NoFilter,
            ...(selectedFareClasses.length > 0 && {
              fareClass: selectedFareClasses,
            }),
          },
        };

        break;
      }
      case SearchPackagesRequestEnum.FollowUpRequest: {
        const nextPageToken = getPackagesAvailabilityNextPageToken(state);

        if (!nextPageToken) return;

        requestBody = {
          nextPageToken,
          searchType: SearchPackagesRequestEnum.FollowUpRequest,
        };

        break;
      }
      default:
        return;
    }

    const availabilityResponse: SearchPackagesResponse =
      yield fetchAvailability(requestBody);

    if (availabilityResponse.Response === PackageShopResponseEnum.Success) {
      yield put(
        actions.setPackagesAvailabilityResults({
          payload: availabilityResponse.value,
        })
      );
    } else {
      yield put(
        actions.setPackagesAvailabilityErrors(availabilityResponse.errors)
      );
      yield put(actions.setPackagesAvailabilityCallStateFailed());
    }
    if (action.requestType === SearchPackagesRequestEnum.InitialRequest) {
      const packageEntryTrackingProperties: ITrackingProperties<PackageEntryProperties> =
        yield select(getPackageEntryProperties);

      trackEvent({
        eventName: PACKAGE_ENTRY,
        properties: { ...packageEntryTrackingProperties.properties },
        encryptedProperties: packageEntryTrackingProperties.encryptedProperties,
      });

      const packagesViewedHotelListProperties: ITrackingProperties<PackagesViewedHotelListProperties> =
        yield select(getPackagesViewedHotelListProperties);

      trackEvent({
        eventName: VIEWED_PACKAGES_LIST,
        properties: {
          fareclass: getSelectedFareClass(fareClassOptionFilter),
          ...packageEntryTrackingProperties.properties,
          ...packagesViewedHotelListProperties.properties,
        },
        encryptedProperties: packageEntryTrackingProperties.encryptedProperties,
      });
    }
  } catch (e) {
    yield put(actions.setPackagesAvailabilityCallStateFailed());
    yield put(actions.setPackagesAvailabilityErrors([]));
    Logger.debug(e);
  }
}

export interface IPackagesAvailabilityParsedQuery {
  origin: string;
  destination: string;
  placeId: string;
  fromDate: Date | null;
  untilDate: Date | null;
  adultsCount: number;
  children: number[];
  infants: { age: number; inSeat: boolean }[];
  stopsOption: SliceStopCountFilter;
  fareClassOptionFilter: FareclassOptionFilter;
  // roomsCount?: number;
}

const getExistingStateVariables = (state: IStoreState) => {
  const { adultsCount, children, infants } = getTravelers(state);
  return {
    fromDate: getDepartureDate(state),
    untilDate: getReturnDate(state),
    origin: getOrigin(state),
    destination: getDestination(state),
    placeId: (getDestination(state)?.id as IIdLodgings)?.lodgingSelection
      ?.placeId,
    adultsCount,
    children,
    infants,
    stopsOption: getStopsOption(state),
    fareClassOptionFilter: getFareclassOptionFilter(state),
  };
};

const shouldRedirect = ({ fromDate, untilDate, origin, destination }: any) =>
  !fromDate || !untilDate || !origin || !destination;

const parseQueryString = (
  history: H.History
): IPackagesAvailabilityParsedQuery => {
  const queryString = history?.location?.search || "";

  const {
    origin,
    destination,
    fromDate,
    untilDate,
    adultsCount,
    children,
    infants,
    stopsOption,
    fareClass,
    placeId,
  } = queryStringParser.parse(queryString);

  const childrenArray = Array.isArray(children)
    ? children.map((age) => parseInt(age, 10))
    : children
    ? [parseInt(children, 10)]
    : [];

  const infantsArray = Array.isArray(infants)
    ? infants.map((infant) => JSON.parse(decodeURIComponent(infant)))
    : infants
    ? [JSON.parse(decodeURIComponent(infants))]
    : [];

  return {
    origin: origin as string,
    destination: destination as string,
    fromDate: dayjs(fromDate as string).toDate(),
    untilDate: dayjs(untilDate as string).toDate(),
    adultsCount: parseInt(adultsCount as string),
    children: childrenArray,
    infants: infantsArray as { age: number; inSeat: boolean }[],
    stopsOption: stopsOption as SliceStopCountFilter,
    fareClassOptionFilter: {
      basic: false,
      standard: false,
      premium: false,
      luxury: false,
      enhanced: false,
      ...(fareClass && { [fareClass as string]: true }),
    },
    placeId: placeId as string,
  };
};

function* getAvailabilityRequestParameters(
  fetchPackagesAvailability: actions.IFetchPackagesAvailability
) {
  const state: IStoreState = yield select();
  const history = fetchPackagesAvailability.history;

  let {
    fromDate,
    untilDate,
    origin,
    destination,
    adultsCount,
    children,
    infants,
    stopsOption,
    fareClassOptionFilter,
  } = getExistingStateVariables(state);

  const parsedQueryString = parseQueryString(history);

  let originToSearch: ITripTerminus | null = origin;
  let destinationToSearch: IResult | null = destination;
  if (
    !destination ||
    (destination?.id as IIdLodgings).lodgingSelection.searchTerm !==
      parsedQueryString.destination ||
    !!config.HOTEL_AUTOCOMPLETE_LOCATION_MAPPING[
      decodeURIComponent(parsedQueryString.destination)
    ]
  ) {
    const { correspondingLocation } = yield fetchDestinationLocation(
      parsedQueryString
    );
    destinationToSearch = correspondingLocation;
  }
  if (
    !origin ||
    (origin &&
      parsedQueryString.origin &&
      origin.id.code.code !== parsedQueryString.origin)
  ) {
    const { correspondingOrigin } = yield fetchOriginLocation(
      parsedQueryString
    );
    originToSearch = correspondingOrigin;
  }

  [
    fromDate,
    untilDate,
    origin,
    destination,
    adultsCount,
    children,
    infants,
    stopsOption,
    fareClassOptionFilter,
  ] = [
    parsedQueryString.fromDate,
    parsedQueryString.untilDate,
    originToSearch,
    destinationToSearch,
    parsedQueryString.adultsCount,
    parsedQueryString.children,
    parsedQueryString.infants?.map((infant) => infant),
    parsedQueryString.stopsOption,
    parsedQueryString.fareClassOptionFilter,
  ];

  if (
    shouldRedirect({
      fromDate,
      untilDate,
      origin,
      destination,
    })
  ) {
    fetchPackagesAvailability.history.push(PATH_HOME);
    return {};
  }
  if (
    fetchPackagesAvailability.requestType ===
    SearchPackagesRequestEnum.InitialRequest
  ) {
    yield putResolve(searchActions.setDepartureDate(fromDate));
    yield putResolve(searchActions.setReturnDate(untilDate));
    yield putResolve(searchActions.setDestination(destination));
    yield putResolve(searchActions.setOrigin(origin));
    yield putResolve(
      searchActions.setTravelers({
        adultsCount,
        children,
        infants,
      })
    );
    yield putResolve(searchActions.setStopsOption(stopsOption));
    yield putResolve(
      searchActions.setFareclassOptionFilter(fareClassOptionFilter)
    );
    yield putResolve(searchActions.setStopsOption(stopsOption));

    yield put(actions.setSearchedDates(fromDate!, untilDate!));
    yield put(actions.setSearchedDestinationResult(destination));
    yield put(actions.setSearchedOriginResult(origin));
    yield put(
      actions.setSearchedTravelers({
        adultsCount,
        children,
        infants,
      })
    );
    yield put(actions.setFareclassOptionFilter(fareClassOptionFilter));
    yield put(actions.setStopsOption(stopsOption));
  }
  return {
    fromDate,
    untilDate,
    origin,
    destination,
    adultsCount,
    children,
    infants,
    stopsOption,
    fareClassOptionFilter,
  };
}

export function* fetchDestinationLocation(
  parsedQueryString: IPackagesAvailabilityParsedQuery
) {
  if (!parsedQueryString.destination)
    return { correspondingLocation: undefined };

  const location = decodeURIComponent(parsedQueryString.destination);

  const mappedLocation = config.HOTEL_AUTOCOMPLETE_LOCATION_MAPPING[location];

  const locationToUse = mappedLocation || location;

  const locationRequestBody: ILocationQueryLabel = {
    LocationQuery: LocationQueryEnum.Label,
    l: locationToUse,
  };

  const { categories: locationCategories }: IResponse =
    yield fetchHotelAutocomplete(locationRequestBody);
  const correspondingLocations = locationCategories.flatMap((category) =>
    category.results.find(
      (result) =>
        (result.id as IIdLodgings).lodgingSelection.placeId ===
          parsedQueryString.placeId ||
        result.label.toLowerCase().includes(locationToUse.toLowerCase())
    )
  );
  const allLocations = locationCategories.flatMap(
    (category) => category.results
  );

  return {
    correspondingLocation:
      correspondingLocations.length > 0
        ? correspondingLocations[0]
        : allLocations[0],
  };
}

function* fetchOriginLocation(
  parsedQueryString: IPackagesAvailabilityParsedQuery
) {
  const originRequestBody: ILocationQueryLabel = {
    LocationQuery: LocationQueryEnum.Label,
    l: parsedQueryString.origin,
  };

  const { categories: originCategories }: IResponse =
    yield fetchFlightAutocomplete(originRequestBody);
  const correspondingOrigin = originCategories
    .flatMap((category) => (category as ITripTerminusCategory).results)
    .find(
      (result) => result.id.code.code === parsedQueryString.origin
    ) as ITripTerminus;

  return { correspondingOrigin };
}
