export const FETCH_INITIAL_PACKAGES_AVAILABILITY =
  "packagesAvailability/FETCH_INITIAL_PACKAGES_AVAILABILITY";
export type FETCH_INITIAL_PACKAGES_AVAILABILITY =
  typeof FETCH_INITIAL_PACKAGES_AVAILABILITY;

export const FETCH_MORE_PACKAGES_AVAILABILITY =
  "packagesAvailability/FETCH_MORE_PACKAGES_AVAILABILITY";
export type FETCH_MORE_PACKAGES_AVAILABILITY =
  typeof FETCH_MORE_PACKAGES_AVAILABILITY;

export const STOP_FETCH_MORE_PACKAGES_AVAILABILITY =
  "packagesAvailability/STOP_FETCH_MORE_PACKAGES_AVAILABILITY";
export type STOP_FETCH_MORE_PACKAGES_AVAILABILITY =
  typeof STOP_FETCH_MORE_PACKAGES_AVAILABILITY;

export const SET_PACKAGES_AVAILABILITY_RESULTS =
  "packagesAvailability/SET_PACKAGES_AVAILABILITY_RESULTS";
export type SET_PACKAGES_AVAILABILITY_RESULTS =
  typeof SET_PACKAGES_AVAILABILITY_RESULTS;

export const SET_PACKAGES_AVAILABILITY_ERRORS =
  "packagesAvailability/SET_PACKAGES_AVAILABILITY_ERRORS";
export type SET_PACKAGES_AVAILABILITY_ERRORS =
  typeof SET_PACKAGES_AVAILABILITY_ERRORS;

export const SET_PACKAGES_AVAILABILITY_CALL_STATE_FAILED =
  "packagesAvailability/SET_PACKAGES_AVAILABILITY_CALL_STATE_FAILED";
export type SET_PACKAGES_AVAILABILITY_CALL_STATE_FAILED =
  typeof SET_PACKAGES_AVAILABILITY_CALL_STATE_FAILED;

export const SET_LODGING_ID_IN_FOCUS =
  "packagesAvailability/SET_LODGING_ID_IN_FOCUS";
export type SET_LODGING_ID_IN_FOCUS = typeof SET_LODGING_ID_IN_FOCUS;

export const SET_LODGING_ID_HOVERED =
  "packagesAvailability/SET_LODGING_ID_HOVERED";
export type SET_LODGING_ID_HOVERED = typeof SET_LODGING_ID_HOVERED;

export const SET_AMENITIES_FILTER = "packagesAvailability/SET_AMENITIES_FILTER";
export type SET_AMENITIES_FILTER = typeof SET_AMENITIES_FILTER;

export const SET_STAR_RATINGS_FILTER =
  "packagesAvailability/SET_STAR_RATINGS_FILTER";
export type SET_STAR_RATINGS_FILTER = typeof SET_STAR_RATINGS_FILTER;

export const SET_STAY_TYPE_FILTER = "packagesAvailability/SET_STAY_TYPE_FILTER";
export type SET_STAY_TYPE_FILTER = typeof SET_STAY_TYPE_FILTER;

export const SET_MAX_PRICE_FILTER = "packagesAvailability/SET_MAX_PRICE_FILTER";
export type SET_MAX_PRICE_FILTER = typeof SET_MAX_PRICE_FILTER;

export const SET_HOTEL_NAME_FILTER =
  "packagesAvailability/SET_HOTEL_NAME_FILTER";
export type SET_HOTEL_NAME_FILTER = typeof SET_HOTEL_NAME_FILTER;

export const SET_FREE_CANCEL_FILTER =
  "packagesAvailability/SET_FREE_CANCEL_FILTER";
export type SET_FREE_CANCEL_FILTER = typeof SET_FREE_CANCEL_FILTER;

export const SET_PACKAGES_HOTELS_SORT_OPTION =
  "packagesAvailability/SET_PACKAGES_HOTELS_SORT_OPTION";
export type SET_PACKAGES_HOTELS_SORT_OPTION =
  typeof SET_PACKAGES_HOTELS_SORT_OPTION;

export const SET_MAP_BOUND = "packagesAvailability/SET_MAP_BOUND";
export type SET_MAP_BOUND = typeof SET_MAP_BOUND;

export const SET_SEARCH_LODGING_IDS =
  "packagesAvailability/SET_SEARCH_LODGING_IDS";
export type SET_SEARCH_LODGING_IDS = typeof SET_SEARCH_LODGING_IDS;

export const SET_OPEN_DATES_MODAL = "packagesAvailability/SET_OPEN_DATES_MODAL";
export type SET_OPEN_DATES_MODAL = typeof SET_OPEN_DATES_MODAL;

export const SET_SEARCHED_DATES = "packagesAvailability/SET_SEARCHED_DATES";
export type SET_SEARCHED_DATES = typeof SET_SEARCHED_DATES;

export const SET_SEARCHED_DESTINATION_RESULT =
  "packagesAvailability/SET_SEARCHED_DESTINATION_RESULT";
export type SET_SEARCHED_DESTINATION_RESULT =
  typeof SET_SEARCHED_DESTINATION_RESULT;

export const SET_SEARCHED_ORIGIN_RESULT =
  "packagesAvailability/SET_SEARCHED_ORIGIN_RESULT";
export type SET_SEARCHED_ORIGIN_RESULT = typeof SET_SEARCHED_ORIGIN_RESULT;

export const SET_HAS_VIEWED_UNAVAILABLE_HOTEL =
  "packagesAvailability/SET_HAS_VIEWED_UNAVAILABLE_HOTEL";
export type SET_HAS_VIEWED_UNAVAILABLE_HOTEL =
  typeof SET_HAS_VIEWED_UNAVAILABLE_HOTEL;

export const SET_SELECTED_LODGING_INDEX =
  "packagesAvailability/SET_SELECTED_LODGING_INDEX";
export type SET_SELECTED_LODGING_INDEX = typeof SET_SELECTED_LODGING_INDEX;

export const SET_SEARCHED_TRAVELERS =
  "packagesAvailability/SET_SEARCHED_TRAVELERS ";
export type SET_SEARCHED_TRAVELERS = typeof SET_SEARCHED_TRAVELERS;

export const SET_SEARCHED_ROOMS_COUNT =
  "packagesAvailability/SET_SEARCHED_ROOMS_COUNT";
export type SET_SEARCHED_ROOMS_COUNT = typeof SET_SEARCHED_ROOMS_COUNT;

export const SET_FARECLASS_OPTION_FILTER =
  "packagesAvailability/SET_FARECLASS_OPTION_FILTER";
export type SET_FARECLASS_OPTION_FILTER = typeof SET_FARECLASS_OPTION_FILTER;

export const SET_STOPS_OPTION = "packagesAvailability/SET_STOPS_OPTION";
export type SET_STOPS_OPTION = typeof SET_STOPS_OPTION;

export const SET_IS_HOTEL_FILTER_EXPERIMENT =
  "packagesAvailability/SET_IS_HOTEL_FILTER_EXPERIMENT";
export type SET_IS_HOTEL_FILTER_EXPERIMENT =
  typeof SET_IS_HOTEL_FILTER_EXPERIMENT;

export const SET_HOTEL_AVAIL_ENTRY_POINT =
  "packagesAvailability/SET_HOTEL_AVAIL_ENTRY_POINT";
export type SET_HOTEL_AVAIL_ENTRY_POINT = typeof SET_HOTEL_AVAIL_ENTRY_POINT;

export const FETCH_VIEW_HOTELS_NEAR_LOCATION_CATEGORIES =
  "packagesAvailability/FETCH_VIEW_HOTELS_NEAR_LOCATION_CATEGORIES";
export type FETCH_VIEW_HOTELS_NEAR_LOCATION_CATEGORIES =
  typeof FETCH_VIEW_HOTELS_NEAR_LOCATION_CATEGORIES;

export const SET_VIEW_HOTELS_NEAR_LOCATION_CATEGORIES =
  "packagesAvailability/SET_VIEW_HOTELS_NEAR_LOCATION_CATEGORIES";
export type SET_VIEW_HOTELS_NEAR_LOCATION_CATEGORIES =
  typeof SET_VIEW_HOTELS_NEAR_LOCATION_CATEGORIES;

export const SET_VIEW_HOTELS_NEAR_LOCATION =
  "packagesAvailability/SET_VIEW_HOTELS_NEAR_LOCATION";
export type SET_VIEW_HOTELS_NEAR_LOCATION =
  typeof SET_VIEW_HOTELS_NEAR_LOCATION;

export const SET_POLICY_FILTER = "packagesAvailability/SET_POLICY_FILTER";
export type SET_POLICY_FILTER = typeof SET_POLICY_FILTER;

export const SET_LOYALTY_PROGRAMS_FILTER =
  "packagesAvailability/SET_LOYALTY_PROGRAMS_FILTER";
export type SET_LOYALTY_PROGRAMS_FILTER = typeof SET_LOYALTY_PROGRAMS_FILTER;

export const FETCH_USER_HOTEL_PREFERENCES =
  "packagesAvailability/FETCH_USER_HOTEL_PREFERENCES";
export type FETCH_USER_HOTEL_PREFERENCES = typeof FETCH_USER_HOTEL_PREFERENCES;

export const SET_USER_HOTEL_PREFERENCES =
  "packagesAvailability/SET_USER_HOTEL_PREFERENCES";
export type SET_USER_HOTEL_PREFERENCES = typeof SET_USER_HOTEL_PREFERENCES;

export const SET_USER_HOTEL_PREFERENCES_CALL_STATE_FAILED =
  "packagesAvailability/SET_USER_HOTEL_PREFERENCES_CALL_STATE_FAILED";
export type SET_USER_HOTEL_PREFERENCES_CALL_STATE_FAILED =
  typeof SET_USER_HOTEL_PREFERENCES_CALL_STATE_FAILED;

export const RESET_PACKAGES_AVAILABILITY_CALL_STATE =
  "packagesAvailability/RESET_PACKAGES_AVAILABILITY_CALL_STATE";
export type RESET_PACKAGES_AVAILABILITY_CALL_STATE =
  typeof RESET_PACKAGES_AVAILABILITY_CALL_STATE;

export const LIST_PAYMENT_METHODS = "packagesAvailability/LIST_PAYMENT_METHODS";
export type LIST_PAYMENT_METHODS = typeof LIST_PAYMENT_METHODS;

export const SET_PAYMENT_METHODS = "packagesAvailability/SET_PAYMENT_METHODS";
export type SET_PAYMENT_METHODS = typeof SET_PAYMENT_METHODS;

export const SET_PAYMENT_METHODS_CALL_STATE_FAILED =
  "packagesAvailability/SET_PAYMENT_METHODS_CALL_STATE_FAILED";
export type SET_PAYMENT_METHODS_CALL_STATE_FAILED =
  typeof SET_PAYMENT_METHODS_CALL_STATE_FAILED;

export const SET_PACKAGE_ENTRY_POINT =
  "packagesAvailability/SET_PACKAGE_ENTRY_POINT";
export type SET_PACKAGE_ENTRY_POINT = typeof SET_PACKAGE_ENTRY_POINT;
